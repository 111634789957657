// 
import { observer } from "mobx-react-lite"
import React, { FC, useCallback, useEffect, useReducer, useState } from "react"
import { ImageStyle, Platform, ScrollView, TextStyle, ViewStyle, SafeAreaView, FlatList, Alert, Dimensions, DeviceEventEmitter, TouchableOpacity } from "react-native"
import { api } from "../../../../../services/api"
import { colors, spacing } from "../../../../../theme"
import { View, Text, TextField, Card, Checkbox, DateTimePicker, Picker, SegmentedControl, Assets, Spacings, Colors, BorderRadiuses, Timeline, GridList, GridView, Modal, SortableList, SortableGridList, Image, Button, TabController } from 'react-native-ui-lib';
import DynamicForm, { Col, CustomModal, CustomNumberInput, CustomSelect, CustomTextField, CustomTextFieldWithScanner, DangerButton, Heading, PrimaryButton, Row, SecondaryButton } from "../../../../../mycomponents/DynamicForm"
import DynamicTable from "../../../../../mycomponents/DynamicTable"
import { useNavigate, useParams } from "react-router-dom"
import { useStores } from "../../../../../mycomponents/state"
import { useNavigationState, useNavigation } from "@react-navigation/native"
import LayoutScreen from "../../../../../mycomponents/CRUD/LayoutScreen"
import FormScreen from "../../../../../mycomponents/CRUD/FormScreen"
import { Icon } from "react-native-eva-icons"
import { CustomSelectItemForContacts, TransactionPDFPreview, invoiceItemAdjustment } from "../../../../../models/accountancy/transaction"
import { create } from 'zustand'
import { FlashList } from "@shopify/flash-list";
import { GlobalModalStore } from "../../../../../mycomponents/state/ModalStore"
import { App } from 'antd';
import * as Sharing from 'expo-sharing';
import { GlobalMasterStore } from "../../../../../mycomponents/state/PermissionStore"
import * as Print from 'expo-print';
import Svg, { Circle, Path, Rect } from "react-native-svg"
import moment from "moment"
import CalendarComponent from "../../../../../mycomponents/CalendarComponent/CalendarComponent"

const useFormObject = create((set, get) => ({
    formObject: {},
    setFormObject: (keyValue) => set((state) => ({ formObject: { ...state.formObject, ...keyValue } })),
    setFormObjectArray: (array_key, array_index, field_key, value) => set((state) => {
        if (typeof field_key === 'object') {
            const newFormObject = { ...state.formObject };
            newFormObject[array_key][array_index] = { ...newFormObject[array_key][array_index], ...field_key };
            return { formObject: newFormObject };
        } else {
            const newFormObject = { ...state.formObject };
            // newFormObject[array_key][array_index][field_key] = value;
            const newArray = newFormObject[array_key];
            newArray[array_index][field_key] = value;
            newFormObject[array_key] = newArray;
            return { formObject: newFormObject };
        }
    }),
    getFormObjectArrayValue: (array_key, array_index, field_key) => get().formObject[array_key][array_index][field_key],
    getFormObject: () => get().formObject,
}))

export const ErrorMessage = (props) => (
    <Svg
        xmlns="http://www.w3.org/2000/svg"
        width={202}
        height={135}
        fill="none"
        {...props}
    >
        <Circle cx={100} cy={74} r={61} fill="#F1F2F8" />
        <Path
            fill="#fff"
            d="M53.802 108.728v-48.97l13.591-25.145a2 2 0 0 1 1.76-1.048H129.8c.72 0 1.384.386 1.739 1.012l14.292 25.18v48.971a2 2 0 0 1-2 2h-88.03a2 2 0 0 1-2-2Z"
        />
        <Path
            stroke="#D9DBE8"
            d="M53.802 59.758v48.97a2 2 0 0 0 2 2h88.03a2 2 0 0 0 2-2v-48.97m-92.03 0h25.562a2 2 0 0 1 1.849 1.238l3.17 7.7a5 5 0 0 0 4.624 3.096h21.795c1.937 0 3.7-1.118 4.524-2.87l3.772-8.016a2 2 0 0 1 1.81-1.148h24.924m-92.03 0 13.591-25.145a2 2 0 0 1 1.76-1.048H129.8c.72 0 1.384.386 1.739 1.012l14.292 25.18"
        />
        <Rect
            width={12.743}
            height={4.248}
            x={93.445}
            y={97.277}
            fill="#D9DBE8"
            rx={2.124}
        />
        <Rect
            width={5.663}
            height={7.079}
            x={83.535}
            y={80.287}
            fill="#D9DBE8"
            rx={2.832}
        />
        <Rect
            width={5.663}
            height={7.079}
            x={110.436}
            y={80.287}
            fill="#D9DBE8"
            rx={2.832}
        />
        <Path
            fill="#fff"
            stroke="#D9DBE8"
            d="M109.936 18.698c0 6.505 4.174 12.15 10.409 15.363l-.387 6.192c-.068 1.09 1.231 1.703 2.03.958l5.158-4.814a27.5 27.5 0 0 0 5.235.499c12.291 0 22.446-8.052 22.446-18.198S144.672.5 132.381.5c-12.29 0-22.445 8.052-22.445 18.198Z"
        />
        <Path
            fill="#fff"
            d="M154.327 18.698c0 9.774-9.826 17.698-21.946 17.698-12.12 0-21.945-7.924-21.945-17.698C110.436 8.924 120.261 1 132.381 1c12.12 0 21.946 7.924 21.946 17.698Z"
        />
        <Path
            fill="#fff"
            d="m120.457 40.284.55-8.786a.707.707 0 0 1 .806-.657l7.688 1.098a.708.708 0 0 1 .383 1.219l-8.237 7.688a.708.708 0 0 1-1.19-.562Z"
        />
        <Path
            fill="#D9DBE8"
            d="m129.035 9.36-4.552.317a.5.5 0 0 0-.458.581l2.284 13.74a.5.5 0 0 0 .528.418l1.918-.134a.5.5 0 0 0 .465-.487l.35-13.924a.5.5 0 0 0-.535-.512ZM141.114 10.533l-4.469-.926a.5.5 0 0 0-.598.435l-1.522 13.845a.5.5 0 0 0 .396.545l1.882.39a.5.5 0 0 0 .579-.342l4.108-13.31a.5.5 0 0 0-.376-.637ZM126.645 28.186l-.128-1.827a.5.5 0 0 1 .464-.534l1.827-.128a.5.5 0 0 1 .534.464l.128 1.827a.5.5 0 0 1-.464.534l-1.827.128a.5.5 0 0 1-.534-.464ZM133.715 28.009l.371-1.794a.5.5 0 0 1 .591-.388l1.794.372a.5.5 0 0 1 .388.591l-.372 1.794a.5.5 0 0 1-.591.388l-1.793-.372a.499.499 0 0 1-.388-.591Z"
        />
        <Circle cx={25.485} cy={110.02} r={8.495} fill="#F1F2F8" />
        <Circle
            cx={179.074}
            cy={41.322}
            r={8.495}
            fill="#F1F2F8"
            transform="rotate(132.827 179.074 41.322)"
        />
        <Circle cx={4.248} cy={40.644} r={4.248} fill="#F1F2F8" />
        <Circle cx={12.743} cy={125.594} r={4.248} fill="#F1F2F8" />
        <Circle
            cx={195.892}
            cy={35.675}
            r={4.248}
            fill="#F1F2F8"
            transform="rotate(132.827 195.892 35.675)"
        />
    </Svg>
)

const TableScreen = observer(function TableScreen(props) {
    let navigation = useNavigation();
    let navigate = null;
    const { message, modal } = App.useApp();

    if (Platform.OS === 'web') {
        navigate = useNavigate();
    }

    const navigationFn = (path, params) => {
        if (Platform.OS === 'web') {
            let paramsString = "";
            if (params) {
                paramsString = "?" + Object.keys(params).map(key => key + '=' + params[key]).join('&');
            }
            navigate("/" + path + paramsString);
        } else {
            if (path.includes("report/")) {
                navigation.navigate("report", {
                    ...params,
                    name: path.replace("report/", ""),
                });
            } else {
                navigation.navigate(path, params);
            }
        }
    };

    let queryParams = {};

    if (Platform.OS === 'web') {
        const params = window.location.search.substring(1).split('&')
        params.map((param) => {
            const [key, value] = param.split('=')
            if (key) {
                queryParams[key] = value;
            }
        })
    } else {
        queryParams = props.route.params || {};
    }

    const goBack = () => {
        if (Platform.OS === 'web') {
            window.history.back();
        } else {
            navigation.goBack();
        }
    }

    const [search, setSearch] = useState("");
    const [counter, setCounter] = useState(null);
    const [methods, setMethods] = useState([]);
    const [bankAccounts, setBankAccounts] = useState([]);
    const [cashAccountId, setcashAccountId] = useState('');

    const [selectedMethod, setSelectedMethod] = useState("1");
    const [locations, setLocations] = useState([]);

    const [saving, setSaving] = useState(false);

    const [tables, setTables] = useState([]);
    const [areas, setAreas] = useState([]);
    const [refreshCount, setRefreshCount] = useState(0);
    const debounceRef = React.useRef(null);

    const [response, setResponse] = useState(null);

    const formObject = useFormObject((state) => state.formObject);
    const getFormObject = useFormObject((state) => state.getFormObject);
    const setArrValue = useFormObject((state) => state.setFormObjectArray);
    const setFormObject = useFormObject((state) => state.setFormObject);

    const moduleName = "Invoices";

    const { fields, dataTabs, preferencesFields, ExtraSaveComponent } = api.getForm(moduleName);

    const params = {};

    const setOpenModal = GlobalModalStore((state) => state.setOpenModal);
    const globalData = GlobalMasterStore((state) => state.globalData);
    const setGlobalData = GlobalMasterStore((state) => state.setGlobalData);

    const [takeAwayInvoices, setTakeAwayInvoices] = useState([]);
    const [inKitchenItems, setInKitchenItems] = useState([]);
    const [categories, setCategories] = useState([]);

    const OpenModalCustom = ({ newModule, field_key, array = false, params = null }) => {
        DeviceEventEmitter.addListener("reloadData" + "POS", (event) => {
            if (!event) {
                DeviceEventEmitter.removeAllListeners("reloadData" + "POS");
                if (Platform.OS == 'web') {
                    setOpenModal({ [moduleName]: null })
                } else {
                    //navigation.pop();
                }
                return;
            }

            const newObj = getFormObject();

            if (event?.deleted) {
                DeviceEventEmitter.removeAllListeners("reloadData" + "POS");
                let object = { ...newObj };

                if (event?.id) {
                    if (array) {
                        const oldValues = object[field_key] ? object[field_key] : [];
                        object[field_key] = oldValues.filter((id) => id != event?.id);
                    } else {
                        object[field_key] = null;
                    }
                }

                getAllTables({ ...object, disable_auto_line_item: true });

                if (Platform.OS == 'web') {
                    setOpenModal({
                        [newModule]: null
                    })
                } else {
                    //goBack();
                }
                return;
            }

            let object = { ...newObj };

            if (array) {
                const oldValues = object[field_key] ? object[field_key] : [];
                object[field_key] = [...new Set([...oldValues, event._id])]
            } else {
                object[field_key] = event._id;
            }

            getAllTables({ ...object, disable_auto_line_item: true });

            if (Platform.OS == 'web') {
                setOpenModal({
                    [newModule]: null
                })
            } else {
                //goBack();
            }

            DeviceEventEmitter.removeAllListeners("reloadData" + "POS");
        })

        if (Platform.OS == 'web') {
            let data = {
                [newModule]: {
                    isVisible: true,
                    moduleName: [newModule],
                    viewOnly: false,
                    afterSaveEvent: 'reloadData' + "POS",
                    ignoreQueryParams: true,
                    ...(params ? params : {})
                }
            };

            setOpenModal(data)
        } else {
            navigationFn('New', {
                moduleName: newModule,
                afterSaveEvent: 'reloadData' + "POS",
                ...(params ? params : {})
            })
        }
    }

    const getAllTables = async (values = null) => {
        const response = await api.get("Tables", {
            "page": 1,
            "limit": 1000,
            "sortColumn": "",
            "sortDirection": "",
            "filters": [],
            "tabFilter": {
                'location_id': globalData?.location_id
            },
            "search": "",
            "groupBy": null
        });

        setTables(response?.data.data || []);

        const areas = response?.data.data?.map((item) => {
            return item.area
        });

        const uniqueAreas = [...areas].filter((area) => {
            return area != null;
        }).filter((area, index, self) =>
            index === self.findIndex((t) => (
                t._id === area._id
            ))
        );

        setAreas(uniqueAreas);
    }

    const goToEdit = (id) => {
        if (Platform.OS === 'web') {
            setOpenModal({
                ["Tables"]: {
                    isVisible: true,
                    moduleName: "Tables",
                    id: id,
                    isEdit: true,
                    afterSaveEvent: 'reloadListing',
                }
            })
        } else {
            navigationFn('New', {
                moduleName: "Tables",
                isEdit: true,
                id: id,
                goBackAfterSave: true
            });
        }
    }

    const goToPOS = (table_id, invoice_id) => {
        const obj = {
            // table_id: table_id,
        };

        if (table_id) {
            obj.table_id = table_id;
        }

        if (invoice_id) {
            obj.invoice_id = invoice_id;
        }

        navigationFn('POS', obj);
    }

    const newModule = () => {
        if (Platform.OS === 'web') {
            const query = {}
            const params = window.location.search.substring(1).split('&')
            params.map((param) => {
                const [key, value] = param.split('=')
                if (key) {
                    query[key] = value;
                }
            })

            setOpenModal({
                ["Tables"]: {
                    isVisible: true,
                    moduleName: "Tables",
                    afterSaveEvent: 'reloadListing',
                    disableEdit: props.disableEdit,
                    disableDelete: props.disableDelete,
                    ...(props.default_values ? props.default_values : {})
                }
            })
        } else {
            navigationFn('New', {
                moduleName: "Tables",
                goBackAfterSave: true,
                disableEdit: props.disableEdit,
                disableDelete: props.disableDelete,
                ...(props.default_values ? props.default_values : {})
            })
        }
    }

    const newModuleReservation = () => {
        if (Platform.OS === 'web') {
            const query = {}
            const params = window.location.search.substring(1).split('&')
            params.map((param) => {
                const [key, value] = param.split('=')
                if (key) {
                    query[key] = value;
                }
            })

            setOpenModal({
                ["Reservations"]: {
                    isVisible: true,
                    moduleName: "Reservations",
                    afterSaveEvent: 'reloadListing',
                    disableEdit: props.disableEdit,
                    disableDelete: props.disableDelete,
                    ...(props.default_values ? props.default_values : {})
                }
            })
        } else {
            navigationFn('New', {
                moduleName: "Reservations",
                goBackAfterSave: true,
                disableEdit: props.disableEdit,
                disableDelete: props.disableDelete,
                ...(props.default_values ? props.default_values : {})
            })
        }
    }

    const getTakeAwayInvoices = async () => {
        const response = await api.get("Invoices", {
            "page": 1,
            "limit": 1000,
            "sortColumn": "",
            "sortDirection": "",
            "filters": [],
            "tabFilter": {
                '$and': [
                    {
                        "table_id": {
                            "$exists": false,
                        },
                    },
                    // {
                    //     "balance": 0
                    // },
                    {
                        "status": "Pending"
                    },
                    {
                        'location_id': globalData?.location_id
                    }
                ],
            },
            "search": "",
            "groupBy": null
        });

        setTakeAwayInvoices(response?.data.data || []);
    }

    const getInKitchenInvoices = async () => {
        const response = await api.get("Invoices", {
            "page": 1,
            "limit": 1000,
            "sortColumn": "",
            "sortDirection": "",
            "filters": [],
            "tabFilter": {
                "$and": [
                    // {
                    //     "line_items.kot_status": { "$ne": "Delivered" }
                    // },
                    {
                        "$or": [
                            { "line_items.kot_status": "Pending" },
                            { "line_items.kot_completed_at": { "$gte": moment().subtract(15, 'minutes').toISOString() } }
                        ]
                    },
                    {
                        "status": "Pending"
                    },
                    {
                        'location_id': globalData?.location_id
                    }
                ]
            },
            "search": "",
            "groupBy": null
        });

        const invoices = response?.data.data || [];

        let items = [];

        for (let i = 0; i < invoices.length; i++) {
            const invoice = invoices[i];

            for (let j = 0; j < invoice.line_items.length; j++) {
                const lineItem = invoice.line_items[j];

                if (lineItem.kot_status != "Delivered") {
                    items.push({
                        ...lineItem,
                        invoice: invoice,

                    });
                }
            }
        }

        setInKitchenItems(items);
    }

    const getModulePreferences = async () => {
        const response = await api.getCreateData(moduleName, params);

        const categories = response?.Items?.map((item) => {
            return item.item_category;
        });

        const uniqueCategories = [...categories].filter((category) => {
            return category != null;
        }).filter((category, index, self) =>
            index === self.findIndex((t) => (
                t._id === category._id
            ))
        );

        setCategories(uniqueCategories);

        setResponse(response);
    }

    const getEvents = async ({ start, end }) => {
        const response = await api.customRoute('Tables/action', {
            action: 'getReservations',
            start_date: start,
            end_date: end,
        });

        let events = response || [];

        return events;
    };

    useEffect(() => {
        setMethods(Platform.OS == 'web' ? [
            { _id: "1", name: "Dine In" },
            // { _id: "2", name: "Take Away" },
            { _id: "3", name: "In Kitchen" },
            { _id: "4", name: "Reservations" },
        ] : [
            { _id: "1", name: "Dine In" },
            // { _id: "2", name: "Take Away" },
            { _id: "3", name: "In Kitchen" }
        ]);

        getAllTables();
        getTakeAwayInvoices();
        getInKitchenInvoices();
        getModulePreferences();
    }, [refreshCount]);

    useEffect(() => {
        DeviceEventEmitter.addListener("reloadListing", (event) => {
            console.log("reloadListing");
            setRefreshCount(Math.random());
        })

        DeviceEventEmitter.addListener("goToEdit", (event) => {
            goToEdit(event._id);
        })

        return () => {
            DeviceEventEmitter.removeAllListeners("reloadListing");
            DeviceEventEmitter.removeAllListeners("goToEdit");
        }
    }, [])

    return (
        <View flex>
            <View backgroundColor="#f9f9f9b5"
                style={{
                    padding: Platform.OS == 'web' ? 20 : 6
                }}
            >
                <Row>
                    <Col width={Platform.OS == 'web' ? "100%" : '100%'} >
                        <Card style={{ width: '100%', height: Dimensions.get('window').height - 80 }}>
                            <View row spread>
                                {(response?.OrgPrefs?.enable_restaurant_management) && <View row style={{
                                    flexWrap: 'wrap',
                                }}>
                                    {methods.map((method, index) => {
                                        return <TouchableOpacity key={index.toString()} onPress={() => {
                                            setSelectedMethod(method._id);
                                        }}
                                            style={{
                                                padding: 10,
                                                backgroundColor: selectedMethod == method._id ? 'rgb(77, 129, 241)' : 'white',
                                                borderRadius: 10,
                                                margin: 10,
                                            }}
                                        >
                                            <Text
                                                style={{
                                                    color: selectedMethod == method._id ? 'white' : 'black',
                                                }}
                                            >
                                                {method.name}
                                            </Text>
                                        </TouchableOpacity>
                                    })}
                                </View>}

                                {selectedMethod == "1" && Platform.OS == 'web' && <View row spread centerV>
                                    <SecondaryButton onPress={newModule} style={{ marginLeft: 10, marginRight: 10 }} label={"Add New Table"} />
                                    <PrimaryButton onPress={() => {
                                        goToPOS(null, null);
                                    }} style={{ marginLeft: 10, marginRight: 10 }} >
                                        <View row spread centerV>
                                            <Icon name='plus-outline' width={16} height={16} fill='#fff' />
                                            <View marginL-5>
                                                <Text style={{ color: 'white' }}>Add New Take Away Order</Text>
                                            </View>
                                        </View>
                                    </PrimaryButton>
                                </View>}

                                {(selectedMethod == "2" && Platform.OS == 'web' && takeAwayInvoices.length != 0) && <View row spread centerV>
                                    <PrimaryButton onPress={() => {
                                        goToPOS(null, null);
                                    }} style={{ marginLeft: 10, marginRight: 10 }} >
                                        <View row spread centerV>
                                            <Icon name='plus-outline' width={16} height={16} fill='#fff' />
                                            <View marginL-5>
                                                <Text style={{ color: 'white' }}>Add New Take Away</Text>
                                            </View>
                                        </View>
                                    </PrimaryButton>
                                </View>}

                                {(selectedMethod == "4" && Platform.OS == 'web' && takeAwayInvoices.length != 0) && <View row spread centerV>
                                    <PrimaryButton onPress={newModuleReservation} style={{ marginLeft: 10, marginRight: 10 }} >
                                        <View row spread centerV>
                                            <Icon name='plus-outline' width={16} height={16} fill='#fff' />
                                            <View marginL-5>
                                                <Text style={{ color: 'white' }}>Add New Reservation</Text>
                                            </View>
                                        </View>
                                    </PrimaryButton>
                                </View>}
                            </View>

                            <View style={{
                                height: 1.5,
                                backgroundColor: '#f0f0f0',
                                margin: 10,
                            }}>

                            </View>

                            {(selectedMethod == "1" && Platform.OS != 'web' && response?.OrgPrefs?.enable_restaurant_management) && <View row spread centerV style={{
                                width: '100%',
                            }}>
                                <PrimaryButton onPress={newModule} style={{ marginLeft: 10, marginRight: 10, flex: 1 }} >
                                    <View row spread centerV>
                                        <Icon name='plus-outline' width={16} height={16} fill='#fff' />
                                        <View marginL-5>
                                            <Text style={{ color: 'white' }}>Add New Table</Text>
                                        </View>
                                    </View>
                                </PrimaryButton>
                            </View>}

                            {(selectedMethod == "1" && Platform.OS != 'web' && !response?.OrgPrefs?.enable_restaurant_management) && <View row spread centerV style={{
                                width: '100%',
                            }}>
                                <PrimaryButton onPress={() => {
                                    goToPOS(null, null);
                                }} style={{ marginLeft: 10, marginRight: 10, flex: 1 }} >
                                    <View row spread centerV>
                                        <Icon name='plus-outline' width={16} height={16} fill='#fff' />
                                        <View marginL-5>
                                            <Text style={{ color: 'white' }}>Add New Take Away</Text>
                                        </View>
                                    </View>
                                </PrimaryButton>
                            </View>}

                            {(selectedMethod == "2" && Platform.OS != 'web' && takeAwayInvoices.length != 0) && <View row spread centerV style={{
                                width: '100%'
                            }}>
                                <PrimaryButton onPress={() => {
                                    goToPOS(null, null);
                                }} style={{ marginLeft: 10, marginRight: 10, flex: 1 }} >
                                    <View row spread centerV>
                                        <Icon name='plus-outline' width={16} height={16} fill='#fff' />
                                        <View marginL-5>
                                            <Text style={{ color: 'white' }}>Add New Take Away</Text>
                                        </View>
                                    </View>
                                </PrimaryButton>
                            </View>}

                            {selectedMethod == "1" && <ScrollView style={{
                                height: Dimensions.get('window').height - 180,
                                minHeight: Dimensions.get('window').height - 180,
                            }}><View padding-10 paddingT-0>
                                    <View>

                                        {takeAwayInvoices.length > 0 && <View marginT-10>
                                            <Text h1>On Hold Orders</Text>
                                            <View marginT-10 row style={{
                                                flexWrap: 'wrap',
                                            }}>
                                                {takeAwayInvoices.map((invoice, index) => {
                                                    return <Card key={index.toString()} style={{
                                                        padding: 6,
                                                        backgroundColor: 'white',
                                                        borderRadius: 10,
                                                        margin: 5,
                                                        width: Platform.OS == 'web' ? 220 : '96%',
                                                        // height: 140,
                                                        position: 'relative',
                                                    }}>
                                                        <View style={{
                                                            height: 20,
                                                            backgroundColor: invoice.status == 'Delivered' ? 'rgb(19,145,231)' : 'green',
                                                            borderTopLeftRadius: 10,
                                                            borderTopRightRadius: 10,
                                                            width: '100%',
                                                        }}></View>
                                                        <View flex padding-6 style={{
                                                            backgroundColor: '#f0f0f0cc',
                                                            borderBottomLeftRadius: 10,
                                                            borderBottomRightRadius: 10,
                                                        }}>
                                                            <View row spread>
                                                                <Text
                                                                    style={{
                                                                        fontFamily: 'SourceSansProSemiBold',
                                                                        color: 'rgb(104 104 104)',
                                                                    }}
                                                                >
                                                                    {invoice.contact.name == 'WALK-IN' ? invoice.notes ? invoice.notes : "WALK-IN" : invoice.contact.name}
                                                                </Text>
                                                                <View style={{
                                                                    backgroundColor: '#f6f8f9',
                                                                    padding: 0,
                                                                    paddingLeft: 6,
                                                                    paddingRight: 6,
                                                                    borderRadius: 4,
                                                                    borderWidth: 1.5,
                                                                    borderColor: '#40404045',
                                                                    height: 20,
                                                                }}>
                                                                    <Text
                                                                        style={{
                                                                            color: '#6f7782',
                                                                            fontSize: 12,
                                                                        }}
                                                                    >
                                                                        {invoice.line_items.reduce((acc, item) => {
                                                                            return acc + item.quantity;
                                                                        }, 0) + " Items"}
                                                                    </Text>
                                                                </View>
                                                            </View>

                                                            <View flexG bottom paddingB-8 marginT-10 style={{
                                                                minHeight: 40,
                                                            }}>
                                                                {invoice ? <View row spread>
                                                                    <Text darkCardTitle style={{
                                                                        fontSize: 12,
                                                                        lineHeight: 18,
                                                                    }}>Total - ₹ {invoice.total}</Text>

                                                                    {invoice.balance > 0 && <Text darkCardTitle color="rgb(208,25,25)" style={{
                                                                        fontSize: 12,
                                                                        lineHeight: 18,
                                                                        marginLeft: 10,
                                                                    }}>Unpaid</Text>}
                                                                </View> : null}
                                                                <View>
                                                                    {invoice.status == 'Pending' ? <Text darkCardTitle color="green" style={{
                                                                        fontSize: 12,
                                                                        textTransform: 'uppercase'
                                                                    }}>Ongoing</Text> : invoice.status == 'Delivered' ? <Text darkCardTitle color="rgb(19,145,231)" style={{
                                                                        fontSize: 12,
                                                                        textTransform: 'uppercase'
                                                                    }}>Delivered</Text> : <Text darkCardTitle color="rgb(208,25,25)" style={{
                                                                        fontSize: 12,
                                                                        textTransform: 'uppercase'
                                                                    }}>Pending</Text>}
                                                                </View>
                                                            </View>

                                                            <View row>
                                                                {invoice.status == 'Delivered' && invoice.balance == 0 ? null : <TouchableOpacity
                                                                    style={{
                                                                        backgroundColor: "white",
                                                                        borderWidth: 1,
                                                                        borderRadius: 6,
                                                                        padding: 4,
                                                                        paddingHorizontal: 8,
                                                                        borderColor: '#e4e4e4',
                                                                        marginRight: 2,
                                                                        justifyContent: 'center',
                                                                        alignItems: 'center',
                                                                        flexBasis: invoice.status == 'Delivered' ? '100%' : '59%',
                                                                    }}
                                                                    onPress={() => {
                                                                        goToPOS(null, invoice?._id);
                                                                    }}>
                                                                    <View row center>
                                                                        <Icon
                                                                            name='shopping-cart-outline'
                                                                            width={14}
                                                                            height={14}
                                                                            fill='#2d2d2d'
                                                                        />
                                                                        <Text darkCardTitle color="#2d2d2d" marginL-5 style={{
                                                                            fontSize: 12,
                                                                            letterSpacing: 0.4
                                                                        }}>
                                                                            {"EDIT ORDER"}
                                                                        </Text>
                                                                    </View>
                                                                </TouchableOpacity>}
                                                                {invoice.status != 'Delivered' && <TouchableOpacity
                                                                    style={{
                                                                        backgroundColor: "white",
                                                                        borderWidth: 1,
                                                                        borderRadius: 6,
                                                                        padding: 4,
                                                                        paddingHorizontal: 8,
                                                                        borderColor: '#e4e4e4',
                                                                        marginRight: 2,
                                                                        justifyContent: 'center',
                                                                        alignItems: 'center',
                                                                        flexBasis: '40%',
                                                                    }}
                                                                    onPress={async () => {
                                                                        if (invoice.contact.name == 'WALK-IN') {
                                                                            if (invoice.balance > 0) {
                                                                                if (Platform.OS == 'web') {
                                                                                    message.error("Please collect the payment first.");
                                                                                } else {
                                                                                    Alert.alert("Please collect the payment first.");
                                                                                }

                                                                                return;
                                                                            }
                                                                        }

                                                                        const response = await api.customRoute('Invoices/action', {
                                                                            action: 'markInvoiceAsDelivered',
                                                                            invoice_id: invoice._id,
                                                                        });

                                                                        getTakeAwayInvoices();
                                                                        getInKitchenInvoices();
                                                                    }}>
                                                                    <View row center>
                                                                        <Icon
                                                                            name='shopping-cart-outline'
                                                                            width={14}
                                                                            height={14}
                                                                            fill='#2d2d2d'
                                                                        />
                                                                        <Text darkCardTitle color="#2d2d2d" marginL-5 style={{
                                                                            fontSize: 12,
                                                                            letterSpacing: 0.4
                                                                        }}>
                                                                            {"DELIVER"}
                                                                        </Text>
                                                                    </View>
                                                                </TouchableOpacity>}
                                                            </View>
                                                        </View>
                                                    </Card>
                                                })}
                                            </View>
                                        </View>}

                                        {areas.map((area, index) => {
                                            const filteredTables = tables.filter((table) => {
                                                return table.area && table.area._id == area._id
                                            })

                                            return <View key={index.toString()} marginT-10>
                                                <Text h1>{area.name}</Text>
                                                <View row marginT-10 style={{
                                                    flexWrap: 'wrap',
                                                }}>
                                                    {filteredTables.map((table, index) => {
                                                        return <Card key={index.toString()} style={{
                                                            padding: 6,
                                                            backgroundColor: 'white',
                                                            borderRadius: 10,
                                                            margin: 5,
                                                            width: Platform.OS == 'web' ? 220 : '96%',
                                                            // height: 140,
                                                            position: 'relative',
                                                        }}
                                                        >
                                                            {table.invoice?.require_admin_approval && <View style={{
                                                                position: 'absolute',
                                                                top: 0,
                                                                right: 0,
                                                                backgroundColor: 'red',
                                                                padding: 4,
                                                                paddingHorizontal: 10,
                                                                borderRadius: 10,
                                                                zIndex: 9
                                                            }}>
                                                                <Text style={{
                                                                    color: 'white',
                                                                    fontSize: 10,
                                                                }}>ACTION REQUIRED</Text>
                                                            </View>}

                                                            <View style={{
                                                                height: 20,
                                                                backgroundColor: table.invoice ? 'green' : table.status == 'Reserved' ?
                                                                    'rgb(208,25,25)' : 'rgb(19,145,231)',
                                                                borderTopLeftRadius: 10,
                                                                borderTopRightRadius: 10,
                                                                width: '100%',
                                                            }}></View>
                                                            <View flex padding-6 style={{
                                                                backgroundColor: '#f0f0f0cc',
                                                                borderBottomLeftRadius: 10,
                                                                borderBottomRightRadius: 10,
                                                            }}>
                                                                <View row spread>
                                                                    <Text
                                                                        style={{
                                                                            fontFamily: 'SourceSansProSemiBold',
                                                                            color: 'rgb(104 104 104)',
                                                                        }}
                                                                    >
                                                                        {table.name}
                                                                    </Text>
                                                                    <View style={{
                                                                        backgroundColor: '#f6f8f9',
                                                                        padding: 0,
                                                                        paddingLeft: 6,
                                                                        paddingRight: 6,
                                                                        borderRadius: 4,
                                                                        borderWidth: 1.5,
                                                                        borderColor: '#40404045',
                                                                    }}>
                                                                        <Text
                                                                            style={{
                                                                                color: '#6f7782',
                                                                                fontSize: 12,
                                                                            }}
                                                                        >
                                                                            {table.capacity + " Seater"}
                                                                        </Text>
                                                                    </View>
                                                                </View>

                                                                <View flexG bottom paddingB-8 marginT-10 style={{
                                                                    minHeight: 40,
                                                                }}>
                                                                    {table.invoice ? <View row spread>
                                                                        <Text darkCardTitle style={{
                                                                            fontSize: 12,
                                                                            lineHeight: 18,
                                                                        }}>No - {table.invoice.number}</Text>
                                                                        <Text darkCardTitle style={{
                                                                            fontSize: 12,
                                                                            lineHeight: 18,
                                                                        }}>Total - ₹ {table.invoice.total}</Text>
                                                                    </View> : null}
                                                                    <View>
                                                                        {table.invoice ? <Text darkCardTitle color="green" style={{
                                                                            fontSize: 12,
                                                                            textTransform: 'uppercase'
                                                                        }}>Ongoing</Text> : table.status == 'Reserved' ? <Text darkCardTitle color="rgb(208,25,25)" style={{
                                                                            fontSize: 12,
                                                                            textTransform: 'uppercase'
                                                                        }}>Reserved</Text> : <Text darkCardTitle color="rgb(19,145,231)" style={{
                                                                            fontSize: 12,
                                                                            textTransform: 'uppercase'
                                                                        }}>Available</Text>}
                                                                    </View>
                                                                </View>

                                                                <View row>
                                                                    <TouchableOpacity
                                                                        style={{
                                                                            backgroundColor: "white",
                                                                            borderWidth: 1,
                                                                            borderRadius: 6,
                                                                            padding: 4,
                                                                            paddingHorizontal: 8,
                                                                            borderColor: '#e4e4e4',
                                                                            marginRight: 2,
                                                                            justifyContent: 'center',
                                                                            alignItems: 'center',
                                                                            flexBasis: '69%',
                                                                        }}
                                                                        onPress={() => {
                                                                            goToPOS(table._id, table.invoice?._id);
                                                                        }}>
                                                                        <View row center>
                                                                            <Icon
                                                                                name='shopping-cart-outline'
                                                                                width={14}
                                                                                height={14}
                                                                                fill='#2d2d2d'
                                                                            />
                                                                            <Text darkCardTitle color="#2d2d2d" marginL-5 style={{
                                                                                fontSize: 12,
                                                                                letterSpacing: 0.4
                                                                            }}>
                                                                                {table.invoice ? 'EDIT ORDER' : 'START ORDER'}
                                                                            </Text>
                                                                        </View>
                                                                    </TouchableOpacity>
                                                                    <TouchableOpacity
                                                                        style={{
                                                                            backgroundColor: "white",
                                                                            borderWidth: 1,
                                                                            borderRadius: 6,
                                                                            padding: 4,
                                                                            paddingHorizontal: 8,
                                                                            borderColor: '#e4e4e4',
                                                                            marginLeft: 2,
                                                                            justifyContent: 'center',
                                                                            alignItems: 'center',
                                                                            flexBasis: '30%',
                                                                        }}
                                                                        onPress={() => {
                                                                            goToEdit(table._id);
                                                                        }}>
                                                                        <View row center>
                                                                            <Icon
                                                                                name='edit-outline'
                                                                                width={12}
                                                                                height={12}
                                                                                fill='#2d2d2d'
                                                                            />
                                                                            <Text darkCardTitle color="#2d2d2d" marginL-5 style={{
                                                                                fontSize: 12,
                                                                                letterSpacing: 0.4
                                                                            }}>
                                                                                EDIT
                                                                            </Text>
                                                                        </View>
                                                                    </TouchableOpacity>
                                                                </View>
                                                            </View>
                                                        </Card>
                                                    })}
                                                </View>
                                            </View>
                                        })}
                                    </View>
                                </View>
                            </ScrollView>}

                            {selectedMethod == "2" && <ScrollView style={{
                                height: Dimensions.get('window').height - 180,
                                minHeight: Dimensions.get('window').height - 180,
                            }}>
                                {takeAwayInvoices.length == 0 && <View center>
                                    <ErrorMessage />
                                    <Text marginT-20 center>There is no take away recorded today</Text>
                                    <Text marginT-10 center>Click on the button below to add a new take away</Text>

                                    <View marginT-10>
                                        <PrimaryButton onPress={() => {
                                            goToPOS(null, null);
                                        }} style={{ marginLeft: 10, marginRight: 10 }} >
                                            <View row spread centerV>
                                                <Icon name='plus-outline' width={16} height={16} fill='#fff' />
                                                <View marginL-5>
                                                    <Text style={{ color: 'white' }}>Add New Take Away</Text>
                                                </View>
                                            </View>
                                        </PrimaryButton>
                                    </View>
                                </View>}
                                {/* <Text>{takeAwayInvoices.length}</Text> */}
                                <View marginT-10 row style={{
                                    flexWrap: 'wrap',
                                }}>
                                    {takeAwayInvoices.map((invoice, index) => {
                                        return <Card key={index.toString()} style={{
                                            padding: 6,
                                            backgroundColor: 'white',
                                            borderRadius: 10,
                                            margin: 5,
                                            width: Platform.OS == 'web' ? 220 : '96%',
                                            // height: 140,
                                            position: 'relative',
                                        }}>
                                            <View style={{
                                                height: 20,
                                                backgroundColor: invoice.status == 'Delivered' ? 'rgb(19,145,231)' : 'green',
                                                borderTopLeftRadius: 10,
                                                borderTopRightRadius: 10,
                                                width: '100%',
                                            }}></View>
                                            <View flex padding-6 style={{
                                                backgroundColor: '#f0f0f0cc',
                                                borderBottomLeftRadius: 10,
                                                borderBottomRightRadius: 10,
                                            }}>
                                                <View row spread>
                                                    <Text
                                                        style={{
                                                            fontFamily: 'SourceSansProSemiBold',
                                                            color: 'rgb(104 104 104)',
                                                        }}
                                                    >
                                                        {invoice.contact.name}
                                                    </Text>
                                                    <View style={{
                                                        backgroundColor: '#f6f8f9',
                                                        padding: 0,
                                                        paddingLeft: 6,
                                                        paddingRight: 6,
                                                        borderRadius: 4,
                                                        borderWidth: 1.5,
                                                        borderColor: '#40404045',
                                                        height: 20,
                                                    }}>
                                                        <Text
                                                            style={{
                                                                color: '#6f7782',
                                                                fontSize: 12,
                                                            }}
                                                        >
                                                            {invoice.line_items.reduce((acc, item) => {
                                                                return acc + item.quantity;
                                                            }, 0) + " Items"}
                                                        </Text>
                                                    </View>
                                                </View>

                                                <View flexG bottom paddingB-8 marginT-10 style={{
                                                    minHeight: 40,
                                                }}>
                                                    {invoice ? <View row spread>
                                                        <Text darkCardTitle style={{
                                                            fontSize: 12,
                                                            lineHeight: 18,
                                                        }}>Total - ₹ {invoice.total}</Text>

                                                        {invoice.balance > 0 && <Text darkCardTitle color="rgb(208,25,25)" style={{
                                                            fontSize: 12,
                                                            lineHeight: 18,
                                                            marginLeft: 10,
                                                        }}>Unpaid</Text>}
                                                    </View> : null}
                                                    <View>
                                                        {invoice.status == 'Pending' ? <Text darkCardTitle color="green" style={{
                                                            fontSize: 12,
                                                            textTransform: 'uppercase'
                                                        }}>Ongoing</Text> : invoice.status == 'Delivered' ? <Text darkCardTitle color="rgb(19,145,231)" style={{
                                                            fontSize: 12,
                                                            textTransform: 'uppercase'
                                                        }}>Delivered</Text> : <Text darkCardTitle color="rgb(208,25,25)" style={{
                                                            fontSize: 12,
                                                            textTransform: 'uppercase'
                                                        }}>Pending</Text>}
                                                    </View>
                                                </View>

                                                <View row>
                                                    {invoice.status == 'Delivered' && invoice.balance == 0 ? null : <TouchableOpacity
                                                        style={{
                                                            backgroundColor: "white",
                                                            borderWidth: 1,
                                                            borderRadius: 6,
                                                            padding: 4,
                                                            paddingHorizontal: 8,
                                                            borderColor: '#e4e4e4',
                                                            marginRight: 2,
                                                            justifyContent: 'center',
                                                            alignItems: 'center',
                                                            flexBasis: invoice.status == 'Delivered' ? '100%' : '59%',
                                                        }}
                                                        onPress={() => {
                                                            goToPOS(null, invoice?._id);
                                                        }}>
                                                        <View row center>
                                                            <Icon
                                                                name='shopping-cart-outline'
                                                                width={14}
                                                                height={14}
                                                                fill='#2d2d2d'
                                                            />
                                                            <Text darkCardTitle color="#2d2d2d" marginL-5 style={{
                                                                fontSize: 12,
                                                                letterSpacing: 0.4
                                                            }}>
                                                                {"EDIT ORDER"}
                                                            </Text>
                                                        </View>
                                                    </TouchableOpacity>}
                                                    {invoice.status != 'Delivered' && <TouchableOpacity
                                                        style={{
                                                            backgroundColor: "white",
                                                            borderWidth: 1,
                                                            borderRadius: 6,
                                                            padding: 4,
                                                            paddingHorizontal: 8,
                                                            borderColor: '#e4e4e4',
                                                            marginRight: 2,
                                                            justifyContent: 'center',
                                                            alignItems: 'center',
                                                            flexBasis: '40%',
                                                        }}
                                                        onPress={async () => {
                                                            const response = await api.customRoute('Invoices/action', {
                                                                action: 'markInvoiceAsDelivered',
                                                                invoice_id: invoice._id,
                                                            });

                                                            getTakeAwayInvoices();
                                                            getInKitchenInvoices();
                                                        }}>
                                                        <View row center>
                                                            <Icon
                                                                name='shopping-cart-outline'
                                                                width={14}
                                                                height={14}
                                                                fill='#2d2d2d'
                                                            />
                                                            <Text darkCardTitle color="#2d2d2d" marginL-5 style={{
                                                                fontSize: 12,
                                                                letterSpacing: 0.4
                                                            }}>
                                                                {"DELIVER"}
                                                            </Text>
                                                        </View>
                                                    </TouchableOpacity>}
                                                </View>
                                            </View>
                                        </Card>
                                    })}
                                </View>
                            </ScrollView>}

                            {selectedMethod == "3" && <ScrollView style={{
                                height: Dimensions.get('window').height - 200,
                                minHeight: Dimensions.get('window').height - 200,
                            }}>
                                <InKitchenComponent inKitchenItems={inKitchenItems} getInKitchenInvoices={getInKitchenInvoices} getTakeAwayInvoices={getTakeAwayInvoices} />
                            </ScrollView>}

                            {selectedMethod == "4" && <ScrollView style={{
                                height: Dimensions.get('window').height - 200,
                                minHeight: Dimensions.get('window').height - 200,
                            }}>
                                <View style={{
                                    flex: 1,
                                }}>
                                    <View padding-20 paddingT-10>
                                        <CalendarComponent getEvents={getEvents} />
                                    </View>
                                </View>
                            </ScrollView>}
                        </Card>
                    </Col>
                </Row>
            </View>
        </View >
    )
})

export default TableScreen

export const InKitchenComponent = ({ inKitchenItems, getInKitchenInvoices, getTakeAwayInvoices }) => {
    return <View center style={{
        width: '100%',
    }}>
        <View marginT-10 row style={{
            flexWrap: 'wrap',
            width: '100%',
        }}>
            {inKitchenItems.map((item, index) => {
                return <Card key={index.toString()} style={{
                    padding: 6,
                    backgroundColor: 'white',
                    borderRadius: 10,
                    margin: 5,
                    width: Platform.OS == 'web' ? 220 : '96%',
                    // height: 140,
                    position: 'relative',
                }}>
                    <View center style={{
                        height: 20,
                        backgroundColor: 'green',
                        borderTopLeftRadius: 10,
                        borderTopRightRadius: 10,
                        width: '100%',
                    }}>
                        <Text style={{
                            color: 'white',
                            fontSize: 12,
                        }}>{item.kot_started_at ? 'Started ' + moment(item.kot_started_at).fromNow() : ''}</Text>
                    </View>
                    <View flex padding-6 style={{
                        backgroundColor: '#f0f0f0cc',
                        borderBottomLeftRadius: 10,
                        borderBottomRightRadius: 10,
                    }}>

                        {(item.take_away_quantity > 0 || !item.invoice.table_id) && <View marginB-5 center style={{
                            backgroundColor: 'rgb(58 58 58)',
                            padding: 0,
                            paddingLeft: 6,
                            paddingRight: 6,
                            borderRadius: 4,
                            borderWidth: 1.5,
                            borderColor: '#40404045',
                        }}>
                            <Text
                                style={{
                                    color: 'white',
                                    fontSize: 12,
                                    textAlign: 'center',
                                }}
                            >
                                {"Take Away Qty: " + (item.take_away_quantity || item.quantity)}
                            </Text>
                        </View>}

                        <View row spread>
                            <Text
                                style={{
                                    fontFamily: 'SourceSansProSemiBold',
                                    color: 'rgb(104 104 104)',
                                }}
                            >
                                {item.item?.name}
                            </Text>
                            <View style={{
                                backgroundColor: '#f6f8f9',
                                padding: 0,
                                paddingLeft: 6,
                                paddingRight: 6,
                                borderRadius: 4,
                                borderWidth: 1.5,
                                borderColor: '#40404045',
                            }}>
                                <Text
                                    style={{
                                        color: '#6f7782',
                                        fontSize: 12,
                                    }}
                                >
                                    {"Qty: " + item.quantity}
                                </Text>
                            </View>
                        </View>

                        <View flexG bottom paddingB-8 marginT-10 style={{
                            minHeight: 40,
                        }}>

                            {item.invoice ? <View spread row>
                                <Text darkCardTitle style={{
                                    fontSize: 12,
                                    lineHeight: 18,
                                }}>No - {item.invoice.number}</Text>
                                {item.invoice?.table_id ? <Text darkCardTitle style={{
                                    fontSize: 12,
                                    lineHeight: 18,
                                }}>Table - {item.invoice?.table?.name}</Text> : item.invoice?.kot_token ? <Text darkCardTitle style={{
                                    fontSize: 12,
                                    lineHeight: 18,
                                }}>Token - {item.invoice?.kot_token}</Text> : null}
                            </View> : null}
                            <View>
                                {item.kot_status == 'Pending' ? <Text darkCardTitle color="green" style={{
                                    fontSize: 12,
                                    textTransform: 'uppercase'
                                }}>Order Received</Text> :
                                    item.kot_status == 'Preparing' ? <Text darkCardTitle color="rgb(208,25,25)" style={{
                                        fontSize: 12,
                                        textTransform: 'uppercase'
                                    }}>Preparing</Text> :
                                        item.kot_status == 'Ready' ? <Text darkCardTitle color="rgb(208,25,25)" style={{
                                            fontSize: 12,
                                            textTransform: 'uppercase'
                                        }}>Ready</Text> : <Text darkCardTitle color="rgb(19,145,231)" style={{
                                            fontSize: 12,
                                            textTransform: 'uppercase'
                                        }}>Delivered</Text>}
                            </View>
                        </View>

                        <View row>
                            {(item.kot_status == 'Pending') && <TouchableOpacity
                                style={{
                                    backgroundColor: "white",
                                    borderWidth: 1,
                                    borderRadius: 6,
                                    padding: 4,
                                    paddingHorizontal: 8,
                                    borderColor: '#e4e4e4',
                                    marginRight: 2,
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    flexBasis: '100%',
                                }}
                                onPress={async () => {
                                    const response = await api.customRoute('Invoices/action', {
                                        action: 'startPreparing',
                                        invoice_id: item.invoice._id,
                                        line_item_id: item._id,
                                    })

                                    getInKitchenInvoices()
                                    getTakeAwayInvoices()
                                }}>
                                <View row center>
                                    <Icon
                                        name='shopping-cart-outline'
                                        width={14}
                                        height={14}
                                        fill='#2d2d2d' />
                                    <Text darkCardTitle color="#2d2d2d" marginL-5 style={{
                                        fontSize: 12,
                                        letterSpacing: 0.4
                                    }}>
                                        {"START PREPARING"}
                                    </Text>
                                </View>
                            </TouchableOpacity>}
                            {(item.kot_status == 'Preparing') && <TouchableOpacity
                                style={{
                                    backgroundColor: "white",
                                    borderWidth: 1,
                                    borderRadius: 6,
                                    padding: 4,
                                    paddingHorizontal: 8,
                                    borderColor: '#e4e4e4',
                                    marginRight: 2,
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    flexBasis: '100%',
                                }}
                                onPress={async () => {
                                    const response = await api.customRoute('Invoices/action', {
                                        action: 'markAsReady',
                                        invoice_id: item.invoice._id,
                                        line_item_id: item._id,
                                    })

                                    getInKitchenInvoices()
                                    getTakeAwayInvoices()
                                }}>
                                <View row center>
                                    <Icon
                                        name='shopping-cart-outline'
                                        width={14}
                                        height={14}
                                        fill='#2d2d2d' />
                                    <Text darkCardTitle color="#2d2d2d" marginL-5 style={{
                                        fontSize: 12,
                                        letterSpacing: 0.4
                                    }}>
                                        {"MARK AS READY"}
                                    </Text>
                                </View>
                            </TouchableOpacity>}
                            {(item.kot_status == 'Ready') && <TouchableOpacity
                                style={{
                                    backgroundColor: "white",
                                    borderWidth: 1,
                                    borderRadius: 6,
                                    padding: 4,
                                    paddingHorizontal: 8,
                                    borderColor: '#e4e4e4',
                                    marginRight: 2,
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    flexBasis: '100%',
                                }}
                                onPress={async () => {
                                    const response = await api.customRoute('Invoices/action', {
                                        action: 'markAsDelivered',
                                        invoice_id: item.invoice._id,
                                        line_item_id: item._id,
                                    })

                                    getInKitchenInvoices()
                                    getTakeAwayInvoices()
                                }}>
                                <View row center>
                                    <Icon
                                        name='shopping-cart-outline'
                                        width={14}
                                        height={14}
                                        fill='#2d2d2d' />
                                    <Text darkCardTitle color="#2d2d2d" marginL-5 style={{
                                        fontSize: 12,
                                        letterSpacing: 0.4
                                    }}>
                                        {"MARK AS DELIVERED"}
                                    </Text>
                                </View>
                            </TouchableOpacity>}
                        </View>
                    </View>
                </Card>
            })}
        </View>
    </View>
}
