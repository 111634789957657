import React, { useEffect } from "react";
import { View, Card, Text } from "react-native-ui-lib";
import ListingScreen from "../../mycomponents/CRUD/ListingScreen";

export const spsrWebinarMemberfields = (response, view = false, moduleName = null, OpenModalCustom, value, isEdit, globalData, setGlobalData) => {
    const formFields = [
        {
            label: 'Date',
            key: 'date',
            type: 'date',
            placeholder: 'Date',
            visible: true,
            width: '25%',
            tab: 'General',
        },
        {
            label: 'Membership Expiry Date',
            key: 'membership_expiry_date',
            type: 'date',
            placeholder: 'Membership Expiry Date',
            visible: true,
            width: '25%',
            tab: 'General',
        },
        {
            label: 'Annual Membership Type',
            key: 'annual_membership_type',
            type: 'text',
            placeholder: 'Annual Membership Type',
            visible: true,
            width: '25%',
            tab: 'General',
        },
        {
            type: 'divider',
            heading: 'Personal Information',
            visible: true,
            span: 24,
            tab: 'General'
        },
        {
            label: 'Salutation',
            key: 'salutation',
            type: 'text',
            placeholder: 'Salutation',
            visible: true,
            width: '25%',
            tab: 'General',
        },
        {
            label: 'Name',
            key: 'name',
            type: 'text',
            placeholder: 'Name',
            visible: true,
            width: '25%',
            tab: 'General',
        },
        {
            label: 'Date of Birth',
            key: 'dob',
            type: 'date',
            placeholder: 'Date of Birth',
            visible: true,
            width: '25%',
            tab: 'General',
        },
        {
            label: 'Sex',
            key: 'sex',
            type: 'text',
            placeholder: 'Sex',
            visible: true,
            width: '25%',
            tab: 'General',
        },
        {
            label: 'Email',
            key: 'email',
            type: 'text',
            placeholder: 'Email',
            visible: true,
            width: '25%',
            tab: 'General',
        },
        {
            label: 'Phone',
            key: 'phone',
            type: 'text',
            placeholder: 'Phone',
            visible: true,
            width: '25%',
            tab: 'General',
        },
        {
            label: 'Billing Address',
            key: 'billing_address',
            type: 'text',
            placeholder: 'Billing Address',
            visible: true,
            width: '25%',
            tab: 'General',
        },
        {
            label: 'Billing City',
            key: 'billing_city',
            type: 'text',
            placeholder: 'Billing City',
            visible: true,
            width: '25%',
            tab: 'General',
        },
        {
            label: 'Billing State',
            key: 'billing_state',
            type: 'text',
            placeholder: 'Billing State',
            visible: true,
            width: '25%',
            tab: 'General',
        },
        {
            label: 'Billing Country',
            key: 'billing_country',
            type: 'text',
            placeholder: 'Billing Country',
            visible: true,
            width: '25%',
            tab: 'General',
        },
        {
            label: 'Photo File',
            key: 'photo_file',
            type: 'upload',
            placeholder: 'Photo File',
            visible: true,
            width: '25%',
            tab: 'General',
        },
        {
            type: 'divider',
            heading: 'Academic Information',
            visible: true,
            span: 24,
            tab: 'General'
        },
        {
            label: 'Year of Passing',
            key: 'year_of_passing',
            type: 'date',
            placeholder: 'Year of Passing',
            visible: true,
            width: '25%',
            tab: 'General',
        },
        {
            label: 'Name of Institute',
            key: 'name_of_institute',
            type: 'text',
            placeholder: 'Name of Institute',
            visible: true,
            width: '25%',
            tab: 'General',
        },
        {
            label: 'University',
            key: 'university',
            type: 'text',
            placeholder: 'University',
            visible: true,
            width: '25%',
            tab: 'General',
        },
        {
            label: 'Qualification',
            key: 'qualification',
            type: 'text',
            placeholder: 'Qualification',
            visible: true,
            width: '25%',
            tab: 'General',
        },
        {
            label: 'Institution/Organization',
            key: 'institution_organization',
            type: 'text',
            placeholder: 'Institution/Organization',
            visible: true,
            width: '25%',
            tab: 'General',
        },
        {
            label: 'Position Title',
            key: 'position_title',
            type: 'text',
            placeholder: 'Position Title',
            visible: true,
            width: '25%',
            tab: 'General',
        },
        {
            label: 'Area of Expertise',
            key: 'area_of_expertise',
            type: 'text',
            placeholder: 'Area of Expertise',
            visible: true,
            width: '25%',
            tab: 'General',
        },
        {
            label: 'Years of Experience',
            key: 'years_of_experience',
            type: 'number',
            placeholder: 'Years of Experience',
            visible: true,
            width: '25%',
            tab: 'General',
        }
    ]

    const CustomFields = response.CustomFields;

    if (CustomFields && CustomFields.length > 0) {
        CustomFields.forEach((field) => {
            formFields.push({
                label: field.name,
                key: 'cf.' + field.key,
                type: field.type,
                placeholder: field.placeholder,
                visible: true,
                width: '25%'
            });
        });
    }

    return formFields;
}

export const spsrWebinarMemberMobileCard = (item, index, getFieldValue) => {
    return <Card flex margin-20 padding-15 className="hh" style={{ height: 100, marginVertical: 10, flexDirection: 'row', flexWrap: 'wrap' }}>
        <View>
            <Text>TEST</Text>
            <Text style={{ marginBottom: 5 }}>{getFieldValue(item, 'name') ? "" + getFieldValue(item, 'name') : ""}</Text>
        </View>
    </Card>;
}

export const spsrWebinarMemberMain = {
    fields: spsrWebinarMemberfields,
    mobileCard: null
}