import React, { useEffect, useState } from "react";
import { View, Card, Text, TouchableOpacity, Button, Image } from "react-native-ui-lib";
import DynamicTable from "../../mycomponents/DynamicTable";
import { CustomModal, CustomSelect, CustomSerialNumberTextArea } from "../../mycomponents/DynamicForm";

import { create } from 'zustand'
import { addButton } from "../hrms/shiftModel";
import { CustomSelectItemForItems, RND } from "./transaction";
import { Platform } from "react-native";

const useFormObject = create((set, get) => ({
    formObject: {},
    setFormObject: (keyValue) => set((state) => ({ formObject: { ...state.formObject, ...keyValue } })),
    setFormObjectArray: (array_key, array_index, field_key, value) => set((state) => {
        if (typeof field_key === 'object') {
            const newFormObject = { ...state.formObject };
            newFormObject[array_key][array_index] = { ...newFormObject[array_key][array_index], ...field_key };
            return { formObject: newFormObject };
        } else {
            const newFormObject = { ...state.formObject };
            // newFormObject[array_key][array_index][field_key] = value;
            const newArray = newFormObject[array_key];
            newArray[array_index][field_key] = value;
            newFormObject[array_key] = newArray;
            return { formObject: newFormObject };
        }
    }),
    getFormObjectArrayValue: (array_key, array_index, field_key) => get().formObject[array_key][array_index][field_key],
    getFormObject: () => get().formObject,
}))

export const manufacturefields = (response, view = false, moduleName = null, OpenModalCustom, value, isEdit, globalData, setGlobalData) => {

    const TrackingComponent = ({ field, useForm, tKey, tIndex }) => {
        const track_serial_number = useForm((state) => state.formObject?.[tKey]?.[tIndex]?.['track_serial_number']);
        const serial_numbers = useForm((state) => state.formObject?.[tKey]?.[tIndex]?.['serial_numbers']);
        const old_serial_numbers = useForm((state) => state.formObject?.[tKey]?.[tIndex]?.['old_serial_numbers']);
        const _id = useForm((state) => state.formObject?.['_id']);

        const item_id = useForm((state) => state.formObject?.[tKey]?.[tIndex]?.['item_id']);
        const item = response.Items.find((item) => item._id == item_id);

        let available_serial_numbers = item?.serial_numbers || [];

        if (_id) {
            available_serial_numbers = [...new Set([...(available_serial_numbers || []), ...(old_serial_numbers || [])])];
        }

        const [serialNumberModalVisible, setSerialNumberModalVisible] = useState(false);
        const [tempSerialNumber, setTempSerialNumber] = useState('');

        const track_batch_number = useForm((state) => state.formObject?.[tKey]?.[tIndex]?.['track_batch_number']);
        const batch_numbers = useForm((state) => state.formObject?.[tKey]?.[tIndex]?.['batch_numbers']);
        const [batchNumberModalVisible, setBatchNumberModalVisible] = useState(false);

        const setFormObjectArray = useForm((state) => state.setFormObjectArray);
        const setFormObject = useForm((state) => state.setFormObject);
        const getFormObject = useForm((state) => state.getFormObject);

        const batchNumbersColumns = [
            {
                "field": "batch_number",
                "label": "Batch Number",
                "editable": true,
                "type": "select",
                "visible": true,
                "order": 1,
                "options": [...(response.AvailableBatchNumbers || [{
                    "label": "Batch Number 1",
                    "value": "Batch Number 1",
                    "item_id": "649bb576e552219bd858b4c7"
                }, {
                    "label": "Batch Number 2",
                    "value": "Batch Number 2",
                    "item_id": "649bb576e552219bd858b4c7"
                }, {
                    "label": "Batch Number 3",
                    "value": "Batch Number 3",
                    "item_id": "649be5b1c10754fc93f3d89a"
                }
                ])].filter((item) => item.item_id === item_id)
            }, {
                "field": "quantity",
                "label": "Quantity",
                "editable": true,
                "type": "number",
                "visible": true,
                "order": 3
            },
            {
                label: (props) => null,
                field: 'actions',
            }
        ];

        batchNumbersColumns && batchNumbersColumns.map((column) => {
            column.Header = column.label;
            column.accessor = column.field;
        });

        const batchFormObject = useFormObject((state) => state.formObject);
        const batchSetFormObjectArray = useFormObject((state) => state.setFormObjectArray);
        const batchSetFormObject = useFormObject((state) => state.setFormObject);

        const [newQuantity, setNewQuantity] = useState(1);

        const deleteForm = GlobalFormStore((state) => state.deleteForm);
        const setOpenModal = GlobalModalStore((state) => state.setOpenModal);

        let navigation = useNavigation();

        let navigate = null;

        if (Platform.OS === 'web') {
            navigate = useNavigate();
        }

        const navigationFn = (path, params) => {
            if (Platform.OS === 'web') {
                let paramsString = "";
                if (params) {
                    paramsString = "?" + Object.keys(params).map(key => key + '=' + params[key]).join('&');
                }
                navigate("/" + path + paramsString);
            } else {
                if (path.includes("report/")) {
                    navigation.push("report", {
                        ...params,
                        name: path.replace("report/", ""),
                    });
                } else {
                    navigation.push(path, params);
                }
            }
        };

        return <>
            <View>
                <CustomModal title="Serial Numbers" visible={serialNumberModalVisible} onClose={() => setSerialNumberModalVisible(false)}>
                    {!view && <View>
                        {moduleName == "Invoices" && <CustomSelect
                            value={tempSerialNumber}
                            onChange={(value) => {
                                let arr = [...(serial_numbers || [])];
                                arr.push(value);
                                arr = [...new Set(arr)]
                                setFormObjectArray(tKey, tIndex, 'serial_numbers', arr);
                                setFormObjectArray(tKey, tIndex, 'quantity', arr.length);
                                invoiceItemAdjustment(getFormObject(), setFormObject, setFormObjectArray, response);
                            }}
                            options={available_serial_numbers?.map((el) => {
                                return {
                                    label: el,
                                    value: el
                                }
                            }) || []}
                            placeholder='Select Serial Number'
                        />}

                        {moduleName == 'Bills' && <View row left>
                            <View marginR-10>
                                <CustomNumberInput
                                    min={1}
                                    max={1000}
                                    value={newQuantity}
                                    onChange={(value) => {
                                        setNewQuantity(value);
                                    }}
                                    placeholder='Enter Quantity'
                                />
                            </View>

                            <PrimaryButton label={"Generate" + (newQuantity > 1 ? ` ${newQuantity} Serial Numbers` : ` ${newQuantity} Serial Number`)}
                                onPress={() => {
                                    let arr = [...(serial_numbers || [])];
                                    for (let i = 0; i < newQuantity; i++) {
                                        const newDate = new Date();
                                        // generate 10 digit number based on date
                                        const newSerialNumber = `${item.sku}${newDate.getMonth()}${newDate.getDate()}${newDate.getFullYear() - 2000}${newDate.getHours()}${newDate.getMinutes()}${newDate.getSeconds()}${newDate.getMilliseconds()}${i}`;

                                        arr.push(newSerialNumber);
                                    }
                                    arr = [...new Set(arr)]
                                    setFormObjectArray(tKey, tIndex, 'serial_numbers', arr);
                                    setFormObjectArray(tKey, tIndex, 'quantity', arr.length);
                                    invoiceItemAdjustment(getFormObject(), setFormObject, setFormObjectArray, response);
                                }} />

                        </View>}

                        <View marginT-20>
                            <CustomSerialNumberTextArea
                                value={serial_numbers || []}
                                onChange={(value) => {
                                    let arr = [...(value || [])];
                                    if (moduleName == "Invoices") {
                                        arr = arr.filter((item) => available_serial_numbers.includes(item));
                                    }
                                    arr = [...new Set(arr)]
                                    setFormObjectArray(tKey, tIndex, 'quantity', arr.length);
                                    setFormObjectArray(tKey, tIndex, 'serial_numbers', arr);
                                    invoiceItemAdjustment(getFormObject(), setFormObject, setFormObjectArray, response);
                                }}
                            ></CustomSerialNumberTextArea>
                        </View>
                    </View>}
                    {view && <View>
                        {serial_numbers && serial_numbers.map((serial_number, index) => {
                            return <TouchableOpacity
                                onPress={() => {
                                    setOpenModal({
                                        [moduleName]: null
                                    });

                                    if (Platform.OS === 'web') {
                                        const params = {
                                            quickFilterKey: "serial_number",
                                            quickFilterValue: serial_number,
                                        }
                                        let paramsString = "";
                                        if (params) {
                                            paramsString = "?" + Object.keys(params).map(key => key + '=' + params[key]).join('&');
                                        }
                                        let path = "report/" + "SerialNumberDetail";
                                        navigate("/" + path + paramsString);
                                    } else {
                                        navigationFn('report', {
                                            moduleName: "SerialNumberDetail",
                                            quickFilterKey: "serial_number",
                                            quickFilterValue: serial_number,
                                        });
                                    }
                                }}
                                key={index.toString()}
                            ><View row centerV spread marginB-10>
                                    <View row style={{ width: '70%', flexBasis: '70%' }} centerV spread>
                                        <View>
                                            <Text darkCardTitle>{serial_number}</Text>
                                        </View>
                                    </View>
                                    <View style={{ width: '30%', flexBasis: '30%' }} centerV right>
                                        <Text darkCardTitle tabHeading>{1}</Text>
                                    </View>
                                </View>
                            </TouchableOpacity>
                        })}
                    </View>}
                </CustomModal>

                {track_serial_number ?
                    <TouchableOpacity onPress={() => setSerialNumberModalVisible(true)}>
                        <Text link>{serial_numbers ? serial_numbers.length : 0} Serial Numbers</Text>
                    </TouchableOpacity>
                    : null}

                <CustomModal title="Batch Numbers" visible={batchNumberModalVisible} onClose={() => {
                    if (batchFormObject.batch_numbers.filter((item) => item.quantity == 0) > 0) {
                        alert('Please enter quantity for all batch numbers or delete them');
                        return;
                    }

                    setBatchNumberModalVisible(false)
                    setFormObjectArray(tKey, tIndex, 'batch_numbers', batchFormObject.batch_numbers || [])
                }}>
                    <DynamicTable
                        data={batchFormObject.batch_numbers || []}
                        columns={batchNumbersColumns}
                        editable={true}
                        updateData={(data) => {

                        }}
                        actions={true}
                        actionFunctions={[{
                            label: 'Delete',
                            key: 'delete',
                            fn: ({ data, updateData, row, row_index }) => {
                                let newData = [...data];
                                newData.splice(row_index, 1);
                                batchSetFormObject({
                                    batch_numbers: newData
                                })
                            }
                        }]}
                        useForm={useFormObject}
                        tableKey={'batch_numbers'}
                    />

                    <View marginT-20>
                        <Button
                            label="Add Batch Number"
                            onPress={() => {
                                const arr = [...(batchFormObject.batch_numbers || [])];
                                arr.push({
                                    batch_number: '',
                                    quantity: 0
                                });
                                batchSetFormObject({
                                    batch_numbers: arr
                                })
                            }}
                        />
                    </View>

                </CustomModal>

                {track_batch_number ?
                    <TouchableOpacity onPress={() => setBatchNumberModalVisible(true)}>
                        <Text>From {batch_numbers ? batch_numbers.length : 0} Batches</Text>
                    </TouchableOpacity>
                    : null}
            </View>
        </>
    }

    const adjustedItemColumns = [
        {
            "field": "item_id",
            "label": "Item",
            "editable": true,
            "type": "select",
            "options": response.ChildItems || [],
            onChange: ({ value, tKey, tIndex, getObj, setObj, setArrValue }) => {
                if (Platform.OS == 'web' && (!value?.disable_auto_line_item && !isEdit)) {
                    const line_items = getObj()[tKey];
                    if (line_items[line_items.length - 1].item_id) {
                        line_items.push({});
                        setObj({ [tKey]: line_items });
                    }
                }
            },
            extraRenderForSelectedOption: (props) => {
                const { field, useForm, tKey, tIndex } = props;
                const value = props.value;
                const item = response.Items.find((item) => item._id === value);
                const showTracking = moduleName == 'Invoices' || moduleName == 'Bills';
                const setFormObjectArray = useForm((state) => state.setFormObjectArray);
                const getFormObject = useForm((state) => state.getFormObject);
                const setFormObject = useForm((state) => state.setFormObject);

                return item ? <View>
                    <View marginT-10 style={{ width: '100%' }}>
                        <View marginB-10 row>
                            <Text tabHeading marginR-5>{item.sku}</Text>
                            <Text tabHeading>({item.quantity_in_hand})</Text>
                        </View>
                        {showTracking && <TrackingComponent field={props.field} useForm={props.useForm} tKey={props.tKey} tIndex={props.tIndex} />}
                    </View>
                </View> : <></>;
            },
            extraViewRender: ({
                value,
                tKey,
                tIndex,
                getObj,
                setObj,
                getArrValue,
                setArrValue
            }) => {
                const item = response.Items.find((item) => item._id === value);

                return <View>
                    <View marginT-10 style={{ width: '100%' }}>
                        {item.product_type == "Subscription" && <View marginT-20 row>
                            <View marginR-3>
                                <View marginB-4>
                                    <Text style={{ fontSize: 12, color: '#000' }}>{"Start Date"}</Text>
                                </View>
                                <View>
                                    <Text style={{ fontSize: 12, color: '#000' }}>{moment(getObj()[tKey][tIndex]['start_date']).format('DD/MM/YYYY')}</Text>
                                </View>
                            </View>
                            <View marginL-3>
                                <View marginB-4>
                                    <Text style={{ fontSize: 12, color: '#000' }}>{"End Date"}</Text>
                                </View>
                                <View>
                                    <Text style={{ fontSize: 12, color: '#000' }}>{moment(getObj()[tKey][tIndex]['end_date']).format('DD/MM/YYYY')}</Text>
                                </View>
                            </View>
                        </View>}

                    </View>
                </View>
            },
            "visible": true,
            "order": 3,
            "width": 250,
            "otherSearchKeys": ['sku', 'serial_numbers'],
            customSelectItem: CustomSelectItemForItems,
            creatableAction: (props) => {
                let paramOption = {};

                paramOption = {
                    is_default_values: true,
                    name: props.search,
                }

                const params = {
                    ...(props.id ? { isEdit: true, id: props.id } : {}), ...(props.search ? paramOption : {})
                };
                OpenModalCustom({ newModule: "Items", field_key: 'line_items.item_id', params });
            },
        },
        {
            "field": "quantity",
            "label": "Quantity Used Per Item",
            "editable": true,
            "type": "number",
            "visible": true,
            "width": 100,
            "order": 12,
            "onChange": ({ value, tKey, tIndex, getObj, setObj, setArrValue }) => {
            },
            "debounce": 500
        },
        {
            "field": "adjusted_quantity",
            "label": "Quantity Used (Overall)",
            "editable": false,
            "type": "number",
            "visible": true,
            "width": 100,
            "order": 12,
            "onChange": ({ value, tKey, tIndex, getObj, setObj, setArrValue }) => {

            },
            "debounce": 500
        },
        {
            "field": "action_column",
            "label": "",
            "editable": true,
            "type": "custom",
            "width": 80,
            "component": ({ field, useForm, tKey, tIndex }) => {
                const getObj = useForm((state) => state.getFormObject);
                const setObj = useForm((state) => state.setFormObject);
                const setArrValue = useForm((state) => state.setFormObjectArray);
                const line_items = getObj()[tKey];

                return (Platform.OS == 'web' && (line_items.length - 1) != tIndex) ? <View row centerV style={{
                    height: 40
                }}>
                    <button className="focusable" style={{
                        paddingTop: 5,
                        paddingBottom: 5,
                    }} onClick={(e) => {
                        const obj = getObj();
                        const arr = [...obj[tKey]];
                        arr.splice(tIndex, 1);
                        setObj({
                            [tKey]: arr
                        });
                    }}>
                        <Image source={{ uri: `https://inkapps.pages.dev/icons/delete.png` }}
                            style={{ width: 16, height: 16, marginLeft: 10, marginRight: 8 }} />
                    </button>
                </View> : <></>
            },
            "visible": view ? false : true,
            "order": 22
        }];

    adjustedItemColumns && adjustedItemColumns.map((column) => {
        column.Header = column.label;
        column.accessor = column.field;
    });

    const formFields = [
        {
            label: 'Date',
            key: 'date',
            type: 'date',
            placeholder: 'Enter the date',
            visible: true,
            width: '25%',
            value: new Date()
        },
        {
            label: 'Location',
            key: 'location_id',
            type: 'select',
            placeholder: 'Select the location',
            visible: false,
            width: '25%',
            tab: 'General',
            options: response.Locations || [],
            onChange: ({ value, tKey, tIndex, getObj, setObj, getArrValue, setArrValue }) => {
                invoiceItemAdjustment(getObj(), setObj, setArrValue, response);
                setGlobalData({
                    location_id: value
                })
            },
            value: globalData?.location_id ? response.Locations.find((location) => location._id == globalData.location_id) ? globalData.location_id : response.Locations[0].value : response.Locations[0].value
        },
        // {
        //     label: 'Number',
        //     key: 'number',
        //     type: 'text',
        //     placeholder: 'Enter the number',
        //     visible: true,
        //     width: '25%',
        // },
        {
            label: 'Status',
            key: 'status',
            type: 'select',
            placeholder: 'Select the status',
            visible: false,
            width: '25%',
            options: [
                { label: 'Draft', value: 'Draft' },
                { label: 'Completed', value: 'Completed' },
            ],
            value: 'Completed',
        },
        {
            label: 'Item',
            key: 'item_id',
            type: 'select',
            placeholder: 'Select the item',
            visible: true,
            width: '25%',
            options: response.Items || [],
            //line_items should become whatever there define for that item's mapped_items 
            onChange: ({ value, tKey, tIndex, getObj, setObj, setArrValue }) => {
                const item = response.Items.find((item) => item._id === value);
                const mapped_items = item?.mapped_items || [];
                const line_items = getObj()["line_items"];
                for (let i = 0; i < mapped_items.length; i++) {
                    line_items.push({
                        item_id: mapped_items[i].item_id,
                        quantity: mapped_items[i].quantity,
                        adjusted_quantity: RND(mapped_items[i].quantity),
                    });
                }

                setObj({
                    ["line_items"]: line_items,
                    updateVisiblity: Math.random(),
                });
            },
        },
        {
            label: "Quantity",
            key: "quantity",
            type: "number",
            placeholder: "Enter the quantity",
            visible: true,
            width: "25%",
            value: 1,
            onChange: ({ value, tKey, tIndex, getObj, setObj, setArrValue }) => {
                // increase or decrease the adjusted_quantity of the line items based on the quantity
                const line_items = getObj()["line_items"];

                for (let i = 0; i < line_items.length; i++) {
                    line_items[i].adjusted_quantity = RND(value * line_items[i].quantity);
                }

                setObj({
                    ["line_items"]: line_items,
                    updateVisiblity: Math.random(),
                });
            },
        },
        {
            label: '',
            key: 'line_items',
            type: 'table',
            placeholder: '',
            visible: true,
            width: '100%',
            editable: true,
            columns: adjustedItemColumns,
            value: [],
        },
        // addButton('Add Adjusted Item', 'add_adjusted_item', 'line_items')
    ];

    const CustomFields = response.CustomFields;

    if (CustomFields && CustomFields.length > 0) {
        CustomFields.forEach((field) => {
            formFields.push({
                label: field.name,
                key: 'cf.' + field.key,
                type: field.type,
                placeholder: field.placeholder,
                visible: true,
                width: '25%',
                viewField: true,
            });
        });
    }

    return formFields;
}

export const manufactureMobileCard = (item, index, getFieldValue) => {
    return <Card flex margin-20 padding-15 className="hh" style={{ height: 100, marginVertical: 10, flexDirection: 'row', flexWrap: 'wrap' }}>
        <View>
            <Text>TEST</Text>
            <Text style={{ marginBottom: 5 }}>{getFieldValue(item, 'name') ? "" + getFieldValue(item, 'name') : ""}</Text>
        </View>
    </Card>;
}

export const manufactureMain = {
    fields: manufacturefields,
    mobileCard: null
}
