import React, { useState, useEffect, useRef, useCallback, memo } from "react";
import { ActivityIndicator, Alert, Dimensions, Linking, Platform, SafeAreaView, ScrollView, StyleSheet, TouchableOpacity } from "react-native";
import { Text, TextField as TF, View, DateTimePicker, Picker, Checkbox as NCheckbox, Switch as NSwitch, Modal as NModal, Button, SegmentedControl, TabController, Chip, Spacings, Image, Card, ColorSwatch, Colors, ColorPicker, ActionSheet, Fader } from "react-native-ui-lib";
import { Input, DatePicker, Select, Checkbox, Switch, Drawer, Modal, TimePicker, Dropdown, Tabs, AutoComplete } from 'antd';
import dayjs from 'dayjs';
import DynamicTable from "./DynamicTable";
import { useStores } from "./state";
import { observable, set } from "mobx";
import { observer } from "mobx-react-lite";
import { Icon } from "react-native-eva-icons";
import * as DocumentPicker from 'expo-document-picker';
import * as ImagePicker from 'expo-image-picker';
import { create } from 'zustand'
import { LinearGradient } from 'expo-linear-gradient'
import { MainScannerScreenComponent } from "./CRUD/ListingScreen";
import { FlashList } from "@shopify/flash-list";

import mime, { extension } from 'mime';
import { AutocompleteDropdown, TAutocompleteDropdownItem } from 'react-native-autocomplete-dropdown'
const humanizeDuration = require("humanize-duration");
const { RangePicker } = DatePicker;
import ImageView from "react-native-image-viewing";
import { Image as WebImageView } from 'antd';
import { api } from "../services/api";
import RenderHtml from 'react-native-render-html';
import { GlobalLoaderStore } from "./state/GlobalLoaderStore";
import QuillEditor, { QuillToolbar } from 'react-native-cn-quill';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'

import * as FileSystem from "expo-file-system";
import * as Sharing from "expo-sharing";

function debounce(func, delay) {
  let debounceTimer;
  return function (...args) {
    //
    const context = this;
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(() => func.apply(context, args), delay);
  }
}

const { TextArea } = Input;

const GUTTER_SIZE = Platform.OS === 'web' ? 8 : 0;

const styles = StyleSheet.create({
  row: {
    width: '100%',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginHorizontal: -GUTTER_SIZE,
    alignItems: 'flex-start',
  },
  col: {
    paddingHorizontal: GUTTER_SIZE,
    marginBottom: GUTTER_SIZE * 2,
    flexGrow: 0,
  },
});

export const Row = ({ children, style = {} }) => <View left top style={[styles.row, style]}>{children}</View>;

export const Col = ({ children, width = '100%', visible = true, style = null }) => (
  visible && <View left style={[styles.col, { flexBasis: width, width: width }, { ...(style ? style : {}) }]}>{children}</View>
);

export const CustomNumberInput = (props) => {
  const { value, onChange, onBlur, min, max, center, currency = false, right, onFocus } = props;

  const component = <View row centerV style={{
    width: '100%',
  }}>
    {currency && <View marginR-5 style={{
      backgroundColor: '#ebebeb3d',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      width: 40,
      alignItems: 'center',
      borderRadius: 8,
      borderWidth: 1.5,
      borderColor: 'rgba(225, 227, 231, 0.8)',
    }}><Text>S$</Text></View>}
    <Input
      className="input"
      type="number"
      value={value ? value.toString() : value === 0 ? '0' : null}
      min={min}
      max={max}
      onBlur={() => onBlur && onBlur(value)}
      onFocus={() => onFocus && onFocus(value)}
      onChange={(e) => onChange && onChange(e.target.value ? +e.target.value : null)}
      placeholder={props.placeholder || 'Enter here'}
      disabled={props.disabled || false}
      style={{
        textAlign: center ? 'center' : 'left'
      }}
    />
  </View>

  const mobile_component = <View row centerV left={right ? false : true} right={right} style={[
    { width: '100%' },
    props.independent ? {
      borderWidth: 1,
      borderColor: '#e1e3e7cc',
      borderRadius: 8,
      paddingHorizontal: 12,
      marginBottom: 6,
      marginTop: 6
    } : {}
  ]}>
    {currency && <View marginR-5><Text>$</Text></View>}
    <TF
      containerStyle={{
        width: '100%',
      }}
      placeholder={props.placeholder || 'Enter here'}
      fieldStyle={{ width: '100%', flex: 1, flexGrow: 5, height: 42 }}
      value={value ? value.toString() : value === 0 ? '0' : null}
      onBlur={() => onBlur && onBlur(value)}
      onFocus={() => onFocus && onFocus(value)}
      onChangeText={(e) => onChange && onChange(e)}
      textAlign={props.right ? "right" : props.independent ? "left" : "left"}
      keyboardType="numeric"
    />
  </View>

  return Platform.OS === 'web' ? component : mobile_component;
}

export const CustomDurationInput = (props) => {
  const { value, onChange, onBlur, center, duration_options } = props;
  const [humanizedValue, setHumanizedValue] = useState(value);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    const humanized = humanizeDuration(value, {
      units: ['y', 'mo', 'w', 'd', 'h', 'm'],
      round: true,
      largest: 1,
      conjunction: ' and ',
      unitMeasures: {
        y: 365 * 86400000,
        mo: 30 * 86400000,
        w: 604800000,
        d: 86400000,
        h: 3600000,
        m: 60000,
        s: 1000,
        ms: 1,
      },
    });

    setHumanizedValue(value > 0 ? humanized : '');
  }, [value]);

  const component = <AutoComplete
    value={humanizedValue ? humanizedValue : null}
    options={options}
    onSearch={(value) => {
      const duration = value?.split(/(\d+|\D+)/).filter((e) => e);
      const amount = duration?.length > 0 ? parseInt(duration[0]) : null;
      let unit = duration?.length > 1 ? duration[1] : "";
      unit = unit?.replace(/\s/g, '');

      if (unit) {
        const filtered_options = duration_options?.filter((option) => {
          return option.toLowerCase().startsWith(unit.toLowerCase());
        });

        setOptions(filtered_options ? filtered_options.map((option) => {
          return {
            label: amount + ' ' + option,
            value: amount + ' ' + option,
          }
        }) : []);
      } else {
        if (amount) {
          setOptions(duration_options ? duration_options.map((option) => {
            return {
              label: amount + ' ' + option,
              value: amount + ' ' + option,
            }
          }) : []);
        } else {
          setOptions([]);
        }
      }
    }}
    onBlur={(e) => {
      if (!value || value == 0) {
        setHumanizedValue(null);
      }
    }}
    onChange={(e) => {
      setHumanizedValue(e);
      const duration = e?.split(' ');
      if (duration?.length == 2) {
        const amount = parseInt(duration[0]);
        const unit = duration[1];

        let milliseconds;

        if (unit === 'minutes') {
          milliseconds = amount * 60 * 1000;
        } else if (unit === 'hours') {
          milliseconds = amount * 60 * 60 * 1000;
        } else if (unit === 'days') {
          milliseconds = amount * 24 * 60 * 60 * 1000;
        } else if (unit === 'weeks') {
          milliseconds = amount * 7 * 24 * 60 * 60 * 1000;
        } else if (unit === 'months') {
          milliseconds = (amount * 30 * 24 * 60 * 60 * 1000) + 1;
        } else if (unit === 'years') {
          milliseconds = (amount * 365 * 24 * 60 * 60 * 1000) + 2;
        }

        onChange && onChange(milliseconds);
      }
    }}
    placeholder={props.placeholder || 'Enter here'}
    disabled={props.disabled || false}
    style={{
      textAlign: center ? 'center' : 'left',
      width: '100%',
    }}
    defaultActiveFirstOption={true}
  />

  const mobile_component = <View style={[
    { width: '100%' },
    props.independent ? {
      borderWidth: 1,
      borderColor: '#e1e3e7cc',
      borderRadius: 8,
      paddingHorizontal: 12,
      marginBottom: 6,
      marginTop: 6
    } : {}
  ]}>
    <AutocompleteDropdown
      clearOnFocus={false}
      closeOnBlur={true}
      initialValue={humanizedValue ? humanizedValue : null} // or just '2'
      onSelectItem={e => {
        setHumanizedValue(e);
        const duration = e?.split(' ');
        if (duration?.length == 2) {
          const amount = parseInt(duration[0]);
          const unit = duration[1];

          let milliseconds;

          if (unit === 'minutes') {
            milliseconds = amount * 60 * 1000;
          } else if (unit === 'hours') {
            milliseconds = amount * 60 * 60 * 1000;
          } else if (unit === 'days') {
            milliseconds = amount * 24 * 60 * 60 * 1000;
          } else if (unit === 'weeks') {
            milliseconds = amount * 7 * 24 * 60 * 60 * 1000;
          } else if (unit === 'months') {
            milliseconds = (amount * 30 * 24 * 60 * 60 * 1000) + 1;
          } else if (unit === 'years') {
            milliseconds = (amount * 365 * 24 * 60 * 60 * 1000) + 2;
          }

          onChange && onChange(milliseconds);
        }
      }}
      dataSet={options}
      ItemSeparatorComponent={<View style={{ height: 1, width: '100%', backgroundColor: '#d8e1e6' }} />}
    />
  </View>

  return Platform.OS === 'web' ? component : mobile_component;
}

export const CustomSelectItemForCountryCode = (props) => {
  return <>
    <View row centerV spread>
      <Text>{props.label}</Text>
      <View marginL-5>
        <Text tabHeading>{props.country_name}</Text>
      </View>
    </View>
  </>
}

export const CustomTextField = (props) => {
  const { value = '', onChange, onBlur, placeholder, borderLess = false, validation, center = false, showCountryCode, suffixComponent, type = "text", } = props;

  const [countryCode, setCountryCode] = useState('+91');
  const [countries, setCountries] = useState([{ label: '+91', value: '+91' }]);

  useEffect(() => {
    if (value && showCountryCode) {
      const code = countries.filter((country) => {
        return value.startsWith(country.value);
      }).map((country) => {
        return country.value;
      });

      if (code.length > 0 && code[0] != countryCode) {
        setCountryCode(code[0]);
      }
    }
  }, [value, countryCode]);

  const getCountries = async () => {
    const response = await api.customRoute('Contacts/action', {
      action: 'getCountries',
    });

    setCountries(response.map((country) => {
      return {
        country_code: country.iso2,
        country_name: country.label,
        label: "+" + country.phone_code,
        value: "+" + country.phone_code,
      }
    }));
  }

  useEffect(() => {
    if (showCountryCode) {
      getCountries();
    }
  }, []);

  const web_component = <View row style={{ width: '100%' }}>
    {showCountryCode && <View marginR-5 style={{ width: 70 }}><CustomSelect
      value={countryCode}
      onChange={(e) => {
        const oldNumber = value?.replace(countryCode, '');
        onChange && onChange(e + oldNumber);
        setCountryCode(e);
      }}
      options={countries}
      otherSearchKeys={['country_name']}
      customSelectItem={CustomSelectItemForCountryCode}
    /></View>}
    <Input
      className="input"
      value={showCountryCode ? value?.replace(countryCode, '') : value}
      onBlur={() => { onBlur && onBlur(value) }}
      onChange={(e) => {
        let newValue = showCountryCode ? countryCode + e.target.value : e.target.value;
        if (showCountryCode) {
          newValue = newValue.replace(/[^0-9+]/g, '');
        }
        onChange && onChange(newValue)
      }}
      placeholder={props.placeholder || 'Enter here'}
      disabled={props.disabled || false}
      style={{
        textAlign: center ? 'center' : 'left'
      }}
      type={type}
    />
    {suffixComponent && <View>{suffixComponent}</View>}
  </View>

  const mobile_component = <View row style={{ width: '100%' }}>
    {showCountryCode && <View marginR-5 style={{ width: 70 }}><CustomSelect
      value={countryCode}
      onChange={(e) => {
        const oldNumber = value?.replace(countryCode, '');
        onChange && onChange(e + oldNumber);
        setCountryCode(e);
      }}
      options={countries}
    /></View>}

    <View style={[
      { width: '100%' },
      props.independent ? {
        borderWidth: 1,
        borderColor: '#e1e3e7cc',
        borderRadius: 8,
        paddingHorizontal: 12,
        marginBottom: 6,
        marginTop: 6
      } : {}
    ]}>
      <TF
        containerStyle={{
          width: '100%',
        }}
        placeholder={props.placeholder || 'Enter here'}
        fieldStyle={{ width: '100%', height: 42 }}
        // fieldStyle={{width: '100%', borderWidth: 1, borderColor: '#E0E0E0', borderStyle: 'solid', padding: 10}}
        value={showCountryCode ? value?.replace(countryCode, '') : value}
        onBlur={() => onBlur && onBlur(value)}
        onChangeText={(e) => {
          let newValue = showCountryCode ? countryCode + e : e;
          if (showCountryCode) {
            newValue = newValue.replace(/[^0-9+]/g, '');
          }
          onChange && onChange(newValue);
        }}
        textAlign={props.independent ? "left" : "left"}
        secureTextEntry={type == 'password'}
        inputMode={type == 'number' ? 'numeric' : type}
        keyboardType={type == 'email' ? 'email-address' : type == 'number' ? 'numeric' : type == 'phone' ? 'phone-pad' : 'default'}
      />
    </View>
  </View>

  return Platform.OS === 'web' ? web_component : mobile_component;
}

export const CustomTextFieldWithScanner = (props) => {
  const { value, onChange, onBlur, placeholder, borderLess = false, validation } = props;
  const [showScanner, setShowScanner] = useState(false);

  return <View row centerV flexG>

    <CustomModal title="Scan" visible={showScanner} onClose={() => {
      setShowScanner(false);
    }}>
      <MainScannerScreenComponent onClose={(data) => {
        onChange(data);
        setShowScanner(false);
      }} />
    </CustomModal>

    {Platform.OS != 'web' && <View marginR-10>
      <TouchableOpacity
        style={{
          padding: 10,
          width: 16 + 20,
        }}
        onPress={() => {
          setShowScanner(true);
        }}>
        <Image source={{
          uri: 'https://cdn-icons-png.flaticon.com/128/954/954591.png',
        }} style={{ width: 16, height: 16 }} />
      </TouchableOpacity>
      {/* <Image source={{
        uri: 'https://cdn-icons-png.flaticon.com/128/954/954591.png',
      }} style={{ width: 16, height: 16 }} /> */}
    </View>}

    {Platform.OS != 'web' && <View marginR-0>
      <View style={{ width: 1, height: 16, backgroundColor: '#e0e0e0', marginRight: 8 }} />
    </View>}

    {Platform.OS != 'web' && <View centerV flexG style={{
      position: 'relative',
    }}>
      <TF
        style={{
          height: 42,
          // backgroundColor: 'red'
        }}
        placeholder={placeholder || "Search"} value={value} onChange={(e) => {
          onChange(e.nativeEvent.text);
        }} />

      {value ? <View style={{
        position: 'absolute',
        right: 0,
      }}>
        <TouchableOpacity
          style={{
            padding: 10,
            width: 16 + 20,
            borderRadius: 500,
            height: 16 + 20,
            justifyContent: 'center',
            alignItems: 'center',
          }}
          onPress={() => {
            onChange("");
          }}>
          <Icon name='backspace-outline' width={16} height={16} fill={'#53535f'} />
        </TouchableOpacity>
      </View> : null}

    </View>}
  </View>;
};

export const CustomTextAreaField = (props) => {
  const { value, onChange, onBlur, placeholder, borderLess = false, validation } = props;

  const web_component = <TextArea
    className="input"
    defaultValue={value}
    rows={3}
    onBlur={() => { onBlur && onBlur(value) }}
    onChange={(e) => {
      onChange && onChange(e.target.value)
    }}
    placeholder={props.placeholder || 'Enter here'}
  />

  const mobile_component = <View marginT-10 style={[
    { width: '100%' },
    props.independent ? {
      borderWidth: 1,
      borderColor: '#e1e3e7cc',
      borderRadius: 8,
      paddingHorizontal: 12,
      marginBottom: 6,
      marginTop: 6
    } : {}
  ]}><TF
      containerStyle={{
        width: '100%',
      }}
      placeholder={props.placeholder || 'Enter here'}
      fieldStyle={{ width: '100%', height: 42 }}
      value={value}
      onBlur={() => onBlur && onBlur(value)}
      onChangeText={(e) => onChange && onChange(e)}
      numberOfLines={4}
      multiline={true}
      textAlignVertical="top"
    />
  </View>
  return Platform.OS === 'web' ? web_component : mobile_component;
}

export const CustomEditorField = (props) => {
  const { value, onChange, onBlur, placeholder, borderLess = false, validation } = props;
  const [isFocused, setIsFocused] = useState(false);
  const _editor = useRef(null);

  if (Platform.OS === 'web') {
    const ReactQuill = require('react-quill');

    const web_component = <ReactQuill theme="snow" defaultValue={value} onBlur={() => { onBlur && onBlur(value) }}
      onChange={(e) => {
        console.log("onChange", e);
        onChange && onChange(e)
      }}
    />;

    return web_component;
  } else {
    const mobile_component = <View flex marginT-10 style={[
      { width: '100%' },
      props.independent ? {
        borderWidth: 1,
        borderColor: '#e1e3e7cc',
        borderRadius: 8,
        paddingHorizontal: 12,
        marginBottom: 6,
        marginTop: 6
      } : {}
    ]}>
      <View>
        {isFocused && <TouchableOpacity
          onPress={() => {
            _editor.current.blur();
            setIsFocused(false);
          }}
          style={{
            width: '100%',
            height: 42,
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: '#e1e3e7cc',
            borderRadius: 8,
            marginBottom: 6,
          }}
        >
          <Text>Finish Editing</Text>
        </TouchableOpacity>}

        <QuillToolbar
          editor={_editor}
          options={['bold', 'italic', 'underline', { 'list': 'ordered' }, { 'list': 'bullet' }]}
          theme="light"
          styles={{
            toolbar: {
              provider: (provided) => {
                return ({
                  ...provided,
                  borderTopWidth: 0,
                  borderLeftWidth: 0,
                  borderRightWidth: 0,
                  paddingTop: 0,
                  borderColor: "white",
                  backgroundColor: 'white',
                  borderRadius: 8,
                })
              },
              root: (provided) => {
                return {
                  ...provided,
                  backgroundColor: '#ebedf0',
                  width: '100%',
                  borderRadius: 8,
                  height: 42,
                  top: 0,
                }
              },
              toolset: (provided) => {
                return {
                  ...provided,
                }
              }
            },
          }}
        />
      </View>

      <QuillEditor
        style={{
          flex: 1,
          padding: 0,
          backgroundColor: 'white',
          minHeight: 200,
          maxHeight: 200,
          height: 200,
        }}
        ref={_editor}
        initialHtml={value}
        onHtmlChange={(e) => {
          onChange && onChange(e.html);
        }}
        onBlur={() => {
          console.log("onBlur");
        }}
        onFocus={() => {
          setIsFocused(true);
        }}
      />
    </View>

    return mobile_component;
  }
}

export const CustomSerialNumberTextArea = (props) => {
  const { value, onChange, onBlur } = props;
  const [tempSNvalue, setTempSNvalue] = useState("");

  return <View flex row centerV style={{ borderColor: '#e1e3e7cc', borderWidth: 1, marginBottom: 10, borderRadius: 6, paddingHorizontal: 10, paddingTop: 2, paddingBottom: 2, flexWrap: 'wrap' }}>
    {value && value.map((sn, index) =>
      <Chip
        containerStyle={{ borderColor: '#e6edf8', backgroundColor: '#e6edf8', marginRight: 6, marginBottom: 2, paddingBottom: 4, paddingTop: 2, borderRadius: 4 }}
        labelStyle={{ color: '#2f61a3', fontSize: 14, fontWeight: '500', marginRight: 12, lineHeight: 20 }}
        rightElement={<>
          <View marginR-6 style={{ marginRight: 10 }}><Text style={{ color: 'white' }}>|</Text></View>
          <View marginR-12><Text style={{ color: '#2f61a3' }}>x</Text></View>
        </>}
        key={index.toString()} label={`${sn}`} onPress={() => {
          const new_value = value ? [...value] : [];
          new_value.splice(index, 1);
          onChange && onChange(new_value);
        }} />)}
    <View style={{ marginBottom: 2 }} flex>
      <TF
        containerStyle={{
          width: '100%',
        }}
        placeholder={''}
        style={{ width: '100%', paddingVertical: 8 }}
        value={tempSNvalue}
        onBlur={() => onBlur && onBlur(value)}
        onChangeText={(e) => {
          setTempSNvalue(e);
        }}
        onKeyPress={(e) => {
          var key = e.nativeEvent.key;
          if (key == "," || key == "Enter" || key == "Tab") {
            e.preventDefault();
            var item_serial_numbers = tempSNvalue.trim();

            if (item_serial_numbers.length == 1) {
              if (tempSNvalue.trim() != "") {
                var initital_serial_numbers = value ? [...value] : [];
                initital_serial_numbers.push(tempSNvalue.trim());

                let unique = initital_serial_numbers.filter((value, index, self) => {
                  return self.indexOf(value) === index;
                });

                setTempSNvalue('');
                onChange && onChange(unique);
              }
            } else {
              var initital_serial_numbers = value ? [...value] : [];
              initital_serial_numbers = initital_serial_numbers.concat(item_serial_numbers);

              let unique = initital_serial_numbers.filter((value, index, self) => {
                return self.indexOf(value) === index;
              });

              setTempSNvalue('');
              onChange && onChange(unique);
            }
          }

          if (key == "Backspace") {
            if (tempSNvalue == "") {
              var old_item_serial_numbers = value ? [...value] : [];
              old_item_serial_numbers.pop();
              onChange && onChange(old_item_serial_numbers);
            }
          }
        }}
      />
    </View>
  </View>

}

export const CustomDatePicker = (props) => {
  const { value, onChange, onBlur, dateTime, allowClear = true, range = false, disabled, pickerType = "date", autoFocus = false } = props;

  const rangePresets = [
    { label: 'Today', value: [dayjs(), dayjs()] },
    { label: 'Yesterday', value: [dayjs().add(-1, 'd'), dayjs().add(-1, 'd')] },
    { label: 'Last Week', value: [dayjs().add(-7, 'd'), dayjs()] },
    { label: 'Last Two Weeks', value: [dayjs().add(-14, 'd'), dayjs()] },
    { label: 'Last 30 Days', value: [dayjs().add(-30, 'd'), dayjs()] },
    { label: 'Last 90 Days', value: [dayjs().add(-90, 'd'), dayjs()] },
    { label: 'This Month', value: [dayjs().startOf('month'), dayjs().endOf('month')] },
    { label: 'Last Month', value: [dayjs().add(-1, 'month').startOf('month'), dayjs().add(-1, 'month').endOf('month')] }
  ];

  const [open, setOpen] = useState(false);

  const web_component = !range ? <DatePicker
    style={{ width: '100%' }}
    className="input"
    value={value ? dayjs(value) : null}
    onBlur={() => onBlur && onBlur(value)}
    autoFocus={autoFocus}
    placeholder={props.placeholder || 'Enter here'}
    onChange={(e) => {
      onChange && onChange(e ? e.toISOString() : null)
    }}
    showTime={dateTime}
    // 15 min interval and show only hours and minutes and AM/PM
    minuteStep={1}
    format={pickerType == 'date' ? dateTime ? 'DD-MM-YYYY hh:mm A' : 'DD-MM-YYYY' : pickerType == 'week' ? 'YYYY-WW' : pickerType == 'month' ? 'MM-YYYY' : 'YYYY'}
    allowClear={allowClear}
    disabled={disabled}
    open={open}
    picker={pickerType}
    onFocus={(el) => {
      setOpen(true);
    }}
    onClick={(el) => {
      setOpen(true);
    }}
    onOpenChange={(el) => {
      if (el == false) {
        setOpen(false);
      }
    }}
  /> : <RangePicker
    style={{ width: '100%' }}
    className="input"
    autoFocus={autoFocus}
    value={value ? [dayjs(value[0]), dayjs(value[1])] : null}
    onBlur={() => onBlur && onBlur(value)}
    placeholder={props.placeholder || 'Enter here'}
    onChange={(e) => {
      onChange && onChange(e ? [e[0].toISOString(), e[1].toISOString()] : null)
    }}
    showTime={dateTime}
    // 15 min interval and show only hours and minutes and AM/PM
    minuteStep={1}
    format={dateTime ? 'DD-MM-YYYY hh:mm A' : 'DD-MM-YYYY'}
    allowClear={allowClear}
    presets={rangePresets}
    disabled={disabled}
  />

  let mobile_component = !range ? <View flex>
    <DateTimePicker
      containerStyle={{
        width: '100%',
        height: 42
      }}
      fieldStyle={{ width: '100%' }}
      renderInput={() => (<View style={[
        { width: '100%' },
        props.independent ? {
          borderWidth: 1,
          borderColor: '#e1e3e7cc',
          borderRadius: 8,
          paddingHorizontal: 12,
          marginBottom: 6,
          marginTop: 6
        } : {}
      ]}>
        <TF
          containerStyle={{
            width: '100%',
          }}
          placeholder={props.placeholder || 'Enter here'}
          value={value ? dayjs(value).format('DD MMM YYYY') : null}
          textAlign={props.independent ? "left" : "left"}
          fieldStyle={{ width: '100%', height: 42 }}
        />
      </View>
      )}
      value={value ? new Date(value) : null}
      onBlur={() => onBlur && onBlur(value)}
      onChange={(e) => onChange && onChange(e ? dayjs(e).toISOString() : null)}
      style={{ width: '100%' }}
      disabled={disabled}
    />
  </View> : <View row>
    <DateTimePicker
      renderInput={() => (<View style={[
        { width: '100%', marginRight: 5 },
        props.independent ? {
          borderWidth: 1,
          borderColor: '#e1e3e7cc',
          borderRadius: 8,
          paddingHorizontal: 12,
          marginBottom: 6,
          marginTop: 6
        } : {}
      ]}>
        <TF
          containerStyle={{
            width: '100%',
          }}
          placeholder={props.placeholder || 'Enter here'}
          value={(value ? dayjs(value[0]).format('DD MMM YYYY') : "") + " - "}
          textAlign={props.independent ? "left" : "left"}
          fieldStyle={{ width: '100%', height: 42 }}
        />
      </View>
      )}
      value={value ? new Date(value[0]) : null}
      onBlur={() => onBlur && onBlur(value)}
      onChange={(e) => onChange && onChange(e ? dayjs(e).toISOString() : null)}
      style={{ width: '100%' }}
      disabled={disabled}
    />
    <DateTimePicker
      renderInput={() => (<View style={[
        { width: '100%' },
        props.independent ? {
          borderWidth: 1,
          borderColor: '#e1e3e7cc',
          borderRadius: 8,
          paddingHorizontal: 12,
          marginBottom: 6,
          marginTop: 6
        } : {}
      ]}>
        <TF
          containerStyle={{
            width: '100%',
          }}
          placeholder={props.placeholder || 'Enter here'}
          value={value ? dayjs(value[1]).format('DD MMM YYYY') : null}
          textAlign={props.independent ? "left" : "left"}
          fieldStyle={{ width: '100%', height: 42 }}
        />
      </View>
      )}
      value={value ? new Date(value[1]) : null}
      onBlur={() => onBlur && onBlur(value)}
      onChange={(e) => onChange && onChange(e ? dayjs(e).toISOString() : null)}
      style={{ width: '100%' }}
      disabled={disabled}
    />
  </View>

  if (disabled) {
    mobile_component = <View style={{ width: '100%', height: 42, borderRadius: 8, justifyContent: 'center', alignItems: 'center' }}>
      {!range && <Text>{value ? dayjs(value).format(pickerType == 'date' ? dateTime ? 'DD-MM-YYYY hh:mm A' : 'DD-MM-YYYY' : pickerType == 'week' ? 'YYYY-WW' : pickerType == 'month' ? 'MMM YYYY' : 'YYYY') : ""}</Text>}
      {range && <Text>{value ? dayjs(value[0]).format('DD MMM YYYY') + " - " + dayjs(value[1]).format('DD MMM YYYY') : ""}</Text>}
    </View>
  }

  return Platform.OS === 'web' ? web_component : mobile_component;
}

export const CustomTimePicker = (props) => {
  const { value, onChange, onBlur } = props;
  const web_component = <TimePicker
    placeholder={props.placeholder || 'Enter here'}
    style={{ width: '100%' }}
    className="input"
    changeOnBlur={true}
    use12Hours
    format={'h:mm A'}
    value={value ? dayjs(value) : null}
    onBlur={() => {
      onBlur && onBlur(value)
    }}
    onChange={(e) => {
      onChange && onChange(e ? e.toISOString() : null)
    }}
  />

  const mobile_component = <DateTimePicker
    renderInput={() => (
      <TF
        containerStyle={{
          width: '100%',
          height: 42
        }}
        fieldStyle={{ width: '100%', height: 42 }}
        placeholder={''}
        value={value ? dayjs(value).format('DD MMM YYYY') : null}
      />
    )}
    value={value ? new Date(value) : null}
    onBlur={() => onBlur && onBlur(value)}
    onChange={(e) => onChange && onChange(e ? dayjs(e).toISOString() : null)}
  />

  return Platform.OS === 'web' ? web_component : mobile_component;
}

export const CustomDateTimePicker = (props) => {
  const { value, onChange, onBlur } = props;
  const web_component = <DatePicker
    style={{ width: '100%' }}
    className="input"
    value={value ? dayjs(value) : null}
    onBlur={() => onBlur && onBlur(value)}
    placeholder={props.placeholder || 'Enter here'}
    onChange={(e) => {
      onChange && onChange(e ? e.toISOString() : null)
    }}
    showTime
    // 15 min interval and show only hours and minutes and AM/PM
    minuteStep={15}
    format={'DD MMM YYYY hh:mm A'}
  />

  const mobile_component = <View>
    {Platform.OS == 'android' && <><DateTimePicker
      renderInput={() => (
        <TF
          containerStyle={{
            width: '100%',
            height: 42
          }}
          placeholder={''}
          value={value ? dayjs(value).format('DD MMM YYYY') : null}
        />
      )}
      value={value ? new Date(value) : null}
      onBlur={() => onBlur && onBlur(value)}
      mode={'date'}
      onChange={(e) => onChange && onChange(e ? dayjs(e).toISOString() : null)}
    />
      <DateTimePicker
        renderInput={() => (
          <TF
            containerStyle={{
              width: '100%',
              height: 42
            }}
            fieldStyle={{ width: '100%', height: 42 }}
            placeholder={''}
            value={value ? dayjs(value).format('hh:mm A') : null}
          />
        )}
        value={value ? new Date(value) : null}
        onBlur={() => onBlur && onBlur(value)}
        mode={'time'}
        onChange={(e) => onChange && onChange(e ? dayjs(e).toISOString() : null)}
      /></>}
    {Platform.OS == 'ios' && <DateTimePicker
      renderInput={() => (
        <TF
          containerStyle={{
            width: '100%',
            height: 42
          }}
          fieldStyle={{ width: '100%', height: 42 }}
          placeholder={''}
          value={value ? dayjs(value).format('DD MMM YYYY hh:mm A') : null}
        />
      )}
      value={value ? new Date(value) : null}
      onBlur={() => onBlur && onBlur(value)}
      mode={'datetime'}
      onChange={(e) => onChange && onChange(e ? dayjs(e).toISOString() : null)}
    />}
  </View>

  return Platform.OS === 'web' ? web_component : mobile_component;
}

export const CustomSelect = (props) => {
  const { value, onChange, onBlur, options, multiple = false, creatable, creatableAction, otherSearchKeys, customRenderForSelectedOption, extraRenderForSelectedOption, borderLess, searchRoute, searchRouteParams, serialNumberFound, left = false,
    independent = false, autoFocus = false } = props;

  const [open, setOpen] = useState(false);
  const [filteredOptions, setFilteredOptions] = useState(options);
  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    if (options) {
      setFilteredOptions(options);
    }
  }, [options]);

  const getOptions = async (e) => {
    const { data } = await api.customRoute(searchRoute, { search: e });
    setFilteredOptions(data);
  }

  useEffect(() => {
    if (searchRoute) {
      getOptions(searchValue);
    }
  }, [options, value, searchRoute]);

  const web_component = (customRenderForSelectedOption && value) ? <props.customRenderForSelectedOption value={value} /> : <Select
    // ref={selectRef}
    style={{ width: '100%' }}
    open={open}
    placeholder={props.placeholder || 'Enter here'}
    onDropdownVisibleChange={(visible) => setOpen(visible)}
    className="input"
    value={value ? value : multiple ? [] : null}
    onBlur={() => onBlur && onBlur(value)}
    onChange={(e) => {
      onChange && onChange(e)
      if (searchRoute) {
        getOptions(searchValue);
      }
      setFilteredOptions(options);
    }}
    onFocus={() => {
      setOpen(true);
    }}
    defaultActiveFirstOption={false}
    mode={multiple ? 'multiple' : undefined}
    maxTagCount={'responsive'}
    showSearch
    onSearch={(e) => {
      if (searchRoute) {
        getOptions(searchValue);
      }

      if (e) {
        setSearchValue(e);

        const NewOptions = options.filter((option) => {
          if (otherSearchKeys) {
            return option.label?.toLowerCase().includes(e.toLowerCase()) || otherSearchKeys.some((key) => {
              if (key != 'serial_numbers') {
                return option[key]?.toLowerCase().includes(e?.toLowerCase());
              } else {
                const someSerialNumbers = option[key]?.filter((sn) => {
                  return sn?.toLowerCase().includes(e?.toLowerCase());
                });

                if (someSerialNumbers.length == 1 && someSerialNumbers[0].toLowerCase() == e?.toLowerCase()) {
                  onChange && onChange({
                    value: option.value,
                    serial_number: someSerialNumbers[0],
                  })
                }

                return someSerialNumbers.length > 0;
              }
            });
          } else {
            return option.label.toLowerCase().includes(e.toLowerCase());
          }
        });

        setFilteredOptions(NewOptions);
      } else {
        setSearchValue('');
        setFilteredOptions(options);
      }
    }}
    filterOption={false}
    optionLabelProp="label"
    dropdownRender={creatableAction ? (menu) => (
      <>
        {menu}
        <Button style={{
          backgroundColor: 'white',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
          paddingLeft: 8,
          borderRadius: 4,
          height: 38,
          cursor: 'pointer',
          width: 285,
        }} onPress={() => {
          setOpen(false);
          creatableAction({ search: searchValue });
        }}>
          <Text style={{
            color: 'rgb(12, 139, 179)',
            textTransform: 'uppercase',
            fontWeight: '700',
            letterSpacing: 1,
            fontSize: 12,
          }}>
            Add New
          </Text>
        </Button>
      </>
    ) : undefined}
    popupMatchSelectWidth={props.customSelectItem ? false : true}
    bordered={!borderLess}
    autoFocus={autoFocus}
    suffixIcon={<View style={{
      position: 'relative',
      top: -5
    }}><Icon name='chevron-down-outline' width={20} height={20} fill={'#53535f'} /></View>}
  >
    {filteredOptions.map((option, i) => {
      return <Select.Option key={i.toString()} value={option.value} label={option.label}>
        <div style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
        }}>
          {props.customSelectItem ? props.customSelectItem(option) : option.label}
          {creatableAction ? <div style={{ cursor: 'pointer' }} onClick={(e) => {
            e.stopPropagation();
            setOpen(false);
            creatableAction({
              ...option,
              id: option.value,
            });
          }}>
            <Text style={{
              color: 'rgb(12, 139, 179)',
              textTransform: 'uppercase',
              fontWeight: '700',
              letterSpacing: 1,
              fontSize: 12,
            }}>
              EDIT
            </Text>
          </div> : null}
        </div>
      </Select.Option>
    })}
  </Select>


  const mobile_component = <View style={{ width: '100%' }}>
    <CustomModal threeForth title={null} visible={open} onClose={() => setOpen(false)}>
      <View style={{ width: '100%' }}>
        <View row centerV paddingH-10>
          <View flexG>
            <CustomTextFieldWithScanner value={searchValue} onChange={(e) => {
              setSearchValue(e);
              if (searchRoute) {
                getOptions(e);
              }

              if (e) {
                const NewOptions = options.filter((option) => {
                  if (otherSearchKeys) {
                    return option.label?.toLowerCase().includes(e.toLowerCase()) || otherSearchKeys.some((key) => {
                      if (key != 'serial_numbers') {
                        return option[key]?.toLowerCase().includes(e?.toLowerCase());
                      } else {
                        const someSerialNumbers = option[key]?.filter((sn) => {
                          return sn?.toLowerCase().includes(e?.toLowerCase());
                        });

                        if (someSerialNumbers.length == 1 && someSerialNumbers[0].toLowerCase() == e?.toLowerCase()) {
                          onChange && onChange({
                            value: option.value,
                            serial_number: someSerialNumbers[0],
                          })
                        }

                        return someSerialNumbers.length > 0;
                      }
                    });
                  } else {
                    return option.label.toLowerCase().includes(e.toLowerCase());
                  }
                });

                setFilteredOptions(NewOptions);
              } else {
                setFilteredOptions(options);
              }
            }} />
          </View>

          <View>
            <TouchableOpacity
              style={{
                padding: 10,
                width: 16 + 20,
              }}
              onPress={() => {
                setOpen(false);
                setSearchValue('');
              }}
            >
              <Icon name='close-outline' width={20} height={20} fill={'#53535f'} />
            </TouchableOpacity>
          </View>
        </View>
      </View>

      <View
        style={{
          width: '100%',
          height: Dimensions.get('window').height * 0.75 - 150,
        }}
      >
        <FlashList
          data={filteredOptions}
          renderItem={({ item, index }) => {
            return <View
              style={{
                borderBottomWidth: 1,
                borderColor: Colors.$backgroundNeutral,
                paddingHorizontal: 10,
              }}
              row
              centerV
              spread
            >
              <View flex>
                <TouchableOpacity
                  style={{
                    width: '100%',
                    height: 42,
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                  onPress={() => {
                    if (!multiple) {
                      onChange && onChange(item.value);
                    } else {
                      const newValue = value ? [...value] : [];
                      const foundIndex = newValue.findIndex((option) => option == item.value);
                      if (foundIndex > -1) {
                        newValue.splice(foundIndex, 1);
                      } else {
                        newValue.push(item.value);
                      }
                      onChange && onChange(newValue);
                    }
                    setOpen(false);
                  }}>
                  <Text marginL-10 text70 $textDefault style={{
                    fontSize: 13
                  }}>
                    {item.label}
                  </Text>
                  {multiple ? <View marginL-10 style={{
                    width: 16,
                    height: 16,
                    borderRadius: 8,
                    borderWidth: 1,
                    borderColor: Colors.$backgroundNeutral,
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}>
                    {value && value.includes(item.value) ? <Icon name='checkmark-outline' width={12} height={12} fill={Colors.$primaryBlue} /> : null}
                  </View> : null}
                </TouchableOpacity>
              </View>

              {creatableAction ? <TouchableOpacity onPress={() => {
                setOpen(false);
                creatableAction({
                  id: item.value,
                });
              }}>
                <Text style={{
                  color: 'rgb(12, 139, 179)',
                  textTransform: 'uppercase',
                  fontWeight: '700',
                  letterSpacing: 1,
                  fontSize: 12,
                  fontFamily: 'SourceSansProSemiBold'
                }}>
                  EDIT
                </Text>
              </TouchableOpacity> : null}
            </View>
          }}
          keyExtractor={(item, index) => index.toString()}
          estimatedItemSize={50}
        />
      </View>

      {creatableAction ? <Button style={{
        backgroundColor: 'white',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        paddingLeft: 20,
        borderRadius: 4,
        height: 38,
        cursor: 'pointer',
        width: 285,
      }} onPress={() => {
        setOpen(false);
        creatableAction({ search: searchValue });
      }}>
        <Text style={{
          color: 'rgb(12, 139, 179)',
          textTransform: 'uppercase',
          fontFamily: 'SourceSansProSemiBold',
          letterSpacing: 1,
          fontSize: 12,
        }}>
          Add New
        </Text>
      </Button> : null}
    </CustomModal>

    <TouchableOpacity style={[
      { width: '100%' },
      independent ? {
        borderWidth: 1,
        borderColor: '#e1e3e7cc',
        borderRadius: 8,
        paddingHorizontal: 12,
        marginBottom: 6,
        marginTop: 6
      } : {}
    ]} onPress={() => setOpen(true)}>
      <View
        row
        style={{ width: '100%' }}
        right={!left}
        centerV
      >
        {(value && !multiple) && <View flexG>
          <Text style={{
            lineHeight: 42,
          }}>
            {filteredOptions.find((option) => option.value == value)?.label || value}
          </Text>
        </View>}

        {(value && multiple) && <View flexG>
          <Text style={{
            lineHeight: 42,
          }}>
            {value.length} Selected
          </Text>
        </View>}

        {!value && <View flexG>
          <Text style={{
            lineHeight: 42,
            color: '#bfbfbf',
          }}>
            {props.placeholder || 'Select'}
          </Text>
        </View>}

        <View marginL-10>
          <Icon name="arrow-ios-downward-outline" height={16} width={16} fill={"#1168bf"} />
        </View>
      </View>
    </TouchableOpacity>
  </View>;

  return Platform.OS === 'web' ? web_component : mobile_component;
}

export const CustomAutoComplete = (props) => {
  const { value, onChange, onBlur, options: allOptions, borderLess, searchRoute, searchRouteParams, autoFocus = false, suffixOptions = null, auto_fill_key } = props;

  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState(auto_fill_key ? [] : allOptions);
  const [filteredOptions, setFilteredOptions] = useState(options);
  const [searchValue, setSearchValue] = useState('');

  const getAutoFillOptions = async (searchValue) => {
    const response = await api.customRoute('Contacts/action', {
      action: 'getAutoFillData',
      auto_fill_key: auto_fill_key,
      search_key: searchValue
    });

    setOptions(response);
  }

  const updateFilteredOptions = () => {
    const e = searchValue;
    if (e) {
      const NewOptions = options.filter((option) => {
        return option.label?.toLowerCase().includes(e.toLowerCase());
      });
      const searchWords = e.split(" ");

      if (suffixOptions) {
        const searchTerm = searchWords[0];

        const suffixFilteredOptions = suffixOptions.map(suffix => {
          return {
            label: `${searchTerm} ${suffix}`,
            value: `${e} ${suffix}`.toLowerCase()
          };
        });

        const combined = [...NewOptions, ...suffixFilteredOptions]

        const uniqueSuffixFilteredOptions = combined.filter((item, index) => {
          return combined.findIndex(obj => obj.value?.toLowerCase() === item.value?.toLowerCase()) === index;
        });

        setFilteredOptions(uniqueSuffixFilteredOptions);
      } else {
        const combined = [...NewOptions, {
          label: e,
          value: e.toLowerCase(),
        }]
        const uniqueSuffixFilteredOptions = combined.filter((item, index) => {
          return combined.findIndex(obj => obj.value?.toLowerCase() === item.value?.toLowerCase()) === index;
        });
        setFilteredOptions(uniqueSuffixFilteredOptions);
      }
    } else {
      setSearchValue('');
      setFilteredOptions(options);
    }
  }

  const addAutoFillData = async (value) => {
    if (!value) return;

    await api.customRoute('Contacts/action', {
      action: 'addAutoFillData',
      auto_fill_key: auto_fill_key,
      value: value
    });

    getAutoFillOptions();
  }

  const deleteAutoFillData = async (value) => {
    await api.customRoute('Contacts/action', {
      action: 'deleteAutoFillData',
      auto_fill_key: auto_fill_key,
      value: value
    });

    getAutoFillOptions();
  }

  useEffect(() => {
    if (options) {
      setFilteredOptions([...options]);
    }
  }, [options]);

  useEffect(() => {
    if (auto_fill_key) {
      getAutoFillOptions(searchValue);
    } else {
      updateFilteredOptions();
    }
  }, [auto_fill_key, searchValue]);

  const web_component = <AutoComplete
    style={{ width: '100%' }}
    open={open}
    placeholder={props.placeholder || 'Enter here'}
    onDropdownVisibleChange={(visible) => setOpen(visible)}
    className="input"
    value={value ? value : null}
    onBlur={() => onBlur && onBlur(value)}
    onChange={(e) => {
      onChange && onChange(e)
      setFilteredOptions(options);
    }}
    showSearch
    onSearch={(e) => {
      if (e) {
        setSearchValue(e);
      } else {
        setSearchValue('');
      }
    }}
    onSelect={(e) => {
      console.log("SELECTED", e);
      props.onSelect && props.onSelect(e);
      if (auto_fill_key) {
        addAutoFillData(e);
      }
    }}
    filterOption={false}
    bordered={!borderLess}
    autoFocus={autoFocus}
    options={filteredOptions.map((option) => {
      return {
        value: option.value,
        label: (<View row spread>
          <View>
            <Text>{option.label}</Text>
          </View>
          {auto_fill_key && !option.new && <TouchableOpacity onPress={() => {
            deleteAutoFillData(option.value);
          }}>
            <Text style={{
              color: 'rgb(12, 139, 179)',
              textTransform: 'uppercase',
              fontWeight: '700',
              letterSpacing: 1,
              fontSize: 12,
            }}>
              DELETE
            </Text>
          </TouchableOpacity>}
        </View>)
      }
    })}
  >
    {/* {filteredOptions.map((option, i) => {
      return <AutoComplete.Option key={i.toString()} value={option.value} label={(<View row spread>
        {console.log("HIIII", option.label)}
        <View>
          {option.label}
        </View>
        <TouchableOpacity onPress={() => {
        }}>
          <Text style={{
            color: 'rgb(12, 139, 179)',
            textTransform: 'uppercase',
            fontWeight: '700',
            letterSpacing: 1,
            fontSize: 12,
          }}>
            DELETE
          </Text>
        </TouchableOpacity>
      </View>)}></AutoComplete.Option>
    })} */}
  </AutoComplete>

  const mobile_component = <View style={{ width: '100%' }}>
    <AutocompleteDropdown
      clearOnFocus={false}
      closeOnBlur={true}
      initialValue={{ id: '2' }} // or just '2'
      onSelectItem={item => {

      }}
      dataSet={[
        { id: '1', title: 'Alpha' },
        { id: '2', title: 'Beta' },
        { id: '3', title: 'Gamma' }
      ]}
      ItemSeparatorComponent={<View style={{ height: 1, width: '100%', backgroundColor: '#d8e1e6' }} />}
    />
  </View>

  return Platform.OS === 'web' ? web_component : mobile_component;
}

export const CustomCheckbox = (props) => {
  const { value, onChange, onBlur, label, indeterminate } = props;

  const web_component = <Checkbox
    className="input"
    checked={value}
    indeterminate={value ? false : indeterminate}
    onChange={(e) => onChange && onChange(e.target.checked)}
  >
  </Checkbox>

  const mobile_component = <NCheckbox
    value={value}
    onValueChange={(e) => onChange && onChange(e)}
    label={""}
  />

  return Platform.OS === 'web' ? web_component : mobile_component;
}

export const CustomSwitch = (props) => {
  const { value, onChange, onBlur, label } = props;


  const web_component = <div><Switch
    className="input"
    checked={value}
    onChange={(e) => onChange && onChange(e)}
    size="small"
  >
  </Switch>
    {/* <Text text70 style={{ marginLeft: 8, flex: 1, flexWrap: 'wrap', textTransform: "uppercase", fontSize: 10, letterSpacing: 0.6, whiteSpace: "nowrap" }}>
      {label}
    </Text> */}
  </div>

  const mobile_component = <NSwitch
    value={value}
    onValueChange={(e) => onChange && onChange(e)}
    label={""}
  />

  return Platform.OS === 'web' ? web_component : mobile_component;
}

export const CustomModal = ({ title = '', visible, onClose, children, drawer = null, unmount = false, width, threeForth = false, padding = null }) => {

  const web_component = drawer ? <Drawer
    title={title}
    placement="right"
    closable={false}
    onClose={onClose}
    open={visible}
    width={width ? width : "20%"}
    bodyStyle={{ padding: 0 }}
  >
    {children}
  </Drawer> : <Modal
    style={{ top: 20 }}
    title={title}
    open={visible}
    onOk={onClose}
    onCancel={onClose}
    closeIcon={false}
    footer={null}
    width={width ? width : "80%"}
    forceRender={unmount}
  >
    {children}
  </Modal>

  const mobile_component = <NModal
    visible={visible}
    onBackgroundPress={onClose}
    overlayBackgroundColor={"rgba(0,0,0,0.5)"}
    transparent={true}
    onRequestClose={onClose}
  >
    <SafeAreaView>
      <TouchableOpacity onPress={onClose} style={threeForth ? {
        height: Dimensions.get('window').height * 0.25,
        width: Dimensions.get('window').width,
      } : {}}>
      </TouchableOpacity>
      <View style={threeForth ? {
        height: Dimensions.get('window').height * 0.75,
        backgroundColor: 'white',
        borderTopLeftRadius: 20,
        borderTopRightRadius: 20,
        paddingTop: 10,
      } : {
        backgroundColor: 'white',
        padding: padding >= 0 ? padding : 10,
        paddingTop: 10,
        height: Dimensions.get('window').height * 1,
      }}>
        {title ? <View paddingH-10>
          <View centerV row>
            <View flexG>
              <Text h1 style={{ textTransform: "uppercase", fontSize: 16, letterSpacing: 0.6 }}>
                {title}
              </Text>
            </View>
            <View style={{
              marginRight: -10,
            }}>
              <TouchableOpacity onPress={onClose} style={{
                paddingHorizontal: 20,
                paddingVertical: 5,
              }}>
                <Icon name='close-outline' width={20} height={20} fill={'#53535f'} />
              </TouchableOpacity>
            </View>
          </View>
        </View> : null}
        {children}
      </View>
    </SafeAreaView>
  </NModal>

  return Platform.OS === 'web' ? web_component : mobile_component;
}

export const CustomTable = (props) => {
  return <DynamicTable {...props} />
}

export const CustomUpload = (props) => {
  const { value, onChange, onBlur, label, multiple = false, mimeType, moduleName, readOnly, showNaming = false } = props;
  // const [uploading, setUploading] = useState(false);
  console.log('CustomUpload value', value);
  const setLoading = GlobalLoaderStore((state) => state.setLoading);

  const [visible, setIsVisible] = useState(false);
  const [initialIndex, setInitialIndex] = useState(0);
  const [selectPickerVisible, setSelectPickerVisible] = useState(false);

  const UploadText = <View center row style={{
    borderStyle: 'dashed',
    borderWidth: 2,
    borderColor: '#e0e8f2',
    backgroundColor: '#fafafa',
    borderRadius: 5,
    paddingLeft: 10,
    paddingRight: 10,
    marginTop: 6,
    paddingVertical: 3,
  }}>
    <Text style={{
    }}>
      Upload
    </Text>
  </View>;

  const uploadFn = async (source = null) => {
    if (source == "camera") {
      //get required permissions
      const { status, canAskAgain } = await ImagePicker.requestCameraPermissionsAsync();
      if (status !== 'granted') {
        Alert.alert(
          "Permission Required",
          "Please enable camera permissions from settings",
          [
            {
              text: "Cancel",
              onPress: () => console.log("Cancel Pressed"),
              style: "cancel"
            },
            { text: "OK", onPress: () => Linking.openSettings() }
          ]
        );
      }

      await ImagePicker.launchCameraAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.All,
        //allowsEditing: true,
        quality: 0.4,
        base64: true,
      }).then(async (result) => {
        if (!multiple) {
          const assets = result.assets;
          if (!assets || !assets.length) return;

          let uri = assets[0].uri;
          if (!uri) return;
          let extension = null;
          if (assets && assets.length > 0) {
            uri = assets[0].uri;
            if (assets[0].name) {
              extension = assets[0].name.split('.').pop();
            }
          }

          let base64File = uri;

          if (Platform.OS !== 'web') {
            base64File = await FileSystem.readAsStringAsync(uri, { encoding: 'base64' });
            base64File = `data:${mime.getType(uri)};base64,${base64File}`;
            extension = assets[0].uri.split('.').pop();
          }

          const data = { file: base64File, extension: extension };

          setLoading(true);

          try {
            const res = await api.uploadFile(moduleName, data);
            onChange(res.url);
          } catch (e) {
          } finally {
            setLoading(false);
          }
        } else {
          const assets = result.assets;
          if (!assets || !assets.length) return;

          let extension = null;

          let uri = assets[0].uri;

          if (assets && assets.length > 0) {
            uri = assets[0].uri;
            if (assets[0].name) {
              extension = assets[0].name.split('.').pop();
            }
          }

          if (!uri) return;

          let base64File = uri;
          if (Platform.OS !== 'web') {
            base64File = await FileSystem.readAsStringAsync(uri, { encoding: 'base64' });
            base64File = `data:${mime.getType(uri)};base64,${base64File}`;
            extension = assets[0].uri.split('.').pop();
          }

          const data = { file: base64File, extension: extension };

          setLoading(true);

          try {
            const res = await api.uploadFile(moduleName, data);
            const newArr = value ? [...value] : [];
            newArr.push({ url: res.url });
            onChange(newArr);
          } catch (e) {
          } finally {
            setLoading(false);
          }
        }
      });
    } else if (source == "gallery") {
      //get required permissions
      const { status } = await ImagePicker.requestMediaLibraryPermissionsAsync();
      if (status !== 'granted') {
        alert('Sorry, we need camera roll permissions to make this work!');
        return;
      }

      await ImagePicker.launchImageLibraryAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.All,
        //allowsEditing: true,
        quality: 0.4,
        base64: true,
      }).then(async (result) => {
        if (!multiple) {
          const assets = result.assets;
          if (!assets || !assets.length) return;

          let uri = assets[0].uri;
          if (!uri) return;
          let extension = null;
          if (assets && assets.length > 0) {
            uri = assets[0].uri;
            if (assets[0].name) {
              extension = assets[0].name.split('.').pop();
            }
          }

          let base64File = uri;

          if (Platform.OS !== 'web') {
            base64File = await FileSystem.readAsStringAsync(uri, { encoding: 'base64' });
            base64File = `data:${mime.getType(uri)};base64,${base64File}`;
            extension = assets[0].uri.split('.').pop();
          }

          const data = { file: base64File, extension: extension };

          setLoading(true);

          try {
            const res = await api.uploadFile(moduleName, data);
            onChange(res.url);
          } catch (e) {
          } finally {
            setLoading(false);
          }
        } else {
          const assets = result.assets;
          if (!assets || !assets.length) return;

          let extension = null;

          let uri = assets[0].uri;

          if (assets && assets.length > 0) {
            uri = assets[0].uri;
            if (assets[0].name) {
              extension = assets[0].name.split('.').pop();
            }
          }

          if (!uri) return;

          let base64File = uri;
          if (Platform.OS !== 'web') {
            base64File = await FileSystem.readAsStringAsync(uri, { encoding: 'base64' });
            base64File = `data:${mime.getType(uri)};base64,${base64File}`;
            extension = assets[0].uri.split('.').pop();
          }

          const data = { file: base64File, extension: extension };

          setLoading(true);

          try {
            const res = await api.uploadFile(moduleName, data);
            const newArr = value ? [...value] : [];
            newArr.push({ url: res.url });
            onChange(newArr);
          }
          catch (e) {
          }
          finally {
            setLoading(false);
          }
        }
      });
    } else {
      await DocumentPicker.getDocumentAsync({ type: mimeType ? mimeType : '*/*' }).then(async (result) => {
        if (!multiple) {
          let uri = null;
          console.log("DocumentPicker.getDocumentAsync result", result);

          const assets = result.assets;

          if (assets && assets.length == 0) {
            return;
          }

          let extension = null;
          let selectedMimeType = null;

          if (assets && assets.length > 0) {
            uri = assets[0].uri;
            if (assets[0].name) {
              extension = assets[0].name.split('.').pop();
            }
            if (assets[0].mimeType || assets[0].type) {
              selectedMimeType = assets[0].mimeType || assets[0].type;
            }
          }

          if (Platform.OS == 'web') {
            const allowedMimeTypes = mimeType.split(',');

            let shouldStop = false;

            for (const allowedMimeType of allowedMimeTypes) {
              if (allowedMimeType == "image/*") {
                if (selectedMimeType && selectedMimeType.startsWith('image')) {
                  shouldStop = false;
                  break;
                } else {
                  shouldStop = true;
                }
              } else {
                if (selectedMimeType && selectedMimeType == allowedMimeType) {
                  shouldStop = false;
                  break;
                } else {
                  shouldStop = true;
                }
              }
            }

            if (shouldStop) {
              return;
            }
          }

          if (uri) {
            let base64File = uri;
            const mimeType = mime.getType(uri);

            console.log("DocumentPicker.getDocumentAsync mimeType", mimeType);

            if (Platform.OS != 'web') {
              base64File = await FileSystem.readAsStringAsync(uri, { encoding: 'base64' });
              base64File = 'data:' + mimeType + ';base64,' + base64File;
              extension = assets[0].uri.split('.').pop();
            }

            const data = {
              file: base64File,
              extension: extension ? extension : mimeType
            };
            setLoading(true);
            api.uploadFile(moduleName, data).then((res) => {
              onChange(res.url);
              setLoading(false);
            }).catch((e) => {
              setLoading(false);
            });
          }
        } else {
          const assets = result.assets;
          if (!assets || !assets.length) return;

          let extension = null;

          let uri = assets[0].uri;

          if (assets && assets.length > 0) {
            uri = assets[0].uri;
            if (assets[0].name) {
              extension = assets[0].name.split('.').pop();
            }
          }

          if (!uri) return;

          let base64File = uri;
          if (Platform.OS !== 'web') {
            base64File = await FileSystem.readAsStringAsync(uri, { encoding: 'base64' });
            base64File = `data:${mime.getType(uri)};base64,${base64File}`;
            extension = assets[0].uri.split('.').pop();
          }

          const data = { file: base64File, extension: extension };

          setLoading(true);

          try {
            const res = await api.uploadFile(moduleName, data);
            const newArr = value ? [...value] : [];
            newArr.push({ url: res.url });
            console.log(newArr);
            onChange(newArr);
          }
          catch (e) {
          }
          finally {
            setLoading(false);
          }
        }
      });
    }
  };

  const common_image_extension = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp', 'svg'];

  const component = <><View style={{
    position: 'relative',
  }}>

    {(multiple) && <View style={{ display: 'flex', flexDirection: showNaming ? 'column' : 'row', flexWrap: 'wrap' }}>
      {(value && value.length > 0) ? value.map((obj, i) => {
        return <View key={obj.url} style={{
          position: 'relative',
          marginRight: showNaming ? 0 : 8,
          marginTop: showNaming ? 8 : 0,
        }}>

          {showNaming && <View marginB-10><CustomTextField
            value={obj.name}
            onChange={(e) => {
              const newArr = [...value];
              newArr[i].name = e;
              onChange(newArr);
            }}
            placeholder="Enter name"
            disabled={readOnly}
          /></View>}

          {Platform.OS != 'web' && <TouchableOpacity
            onPress={() => {
              if (common_image_extension.includes(obj.url.split('.').pop())) {
                setInitialIndex(i);
                setIsVisible(true);
              } else {
                Linking.openURL(obj.url);

                // save the file to the device first and then open it using expo sharing
                // const uri = obj.url;
                // const fileExtension = uri.split('.').pop();

                // const fileUri = FileSystem.cacheDirectory + `file.${fileExtension}`;
                // FileSystem.downloadAsync(uri, fileUri)
                //   .then(({ uri }) => {
                //     console.log('Finished downloading to ', uri);
                //     Sharing.shareAsync(uri);
                //   })
                //   .catch(error => {
                //     console.error(error);
                //   });
              }
            }}
            marginR-5 marginB-5>
            {common_image_extension.includes(obj.url.split('.').pop()) && <Image source={{ uri: obj.url }} style={{
              borderRadius: 4,
              width: 90,
              height: 85,
            }} />}
            {!common_image_extension.includes(obj.url.split('.').pop()) && <View center style={{
              borderRadius: 4,
              width: 90,
              height: 85,
              backgroundColor: '#f0f0f0',
            }}>
              <Icon name='file-text-outline' width={40} height={40} fill={'#53535f'} />
            </View>}
          </TouchableOpacity>}

          {Platform.OS == 'web' && common_image_extension.includes(obj.url?.split('.').pop()) && <WebImageView width={90} src={obj.url} style={{
            width: 90,
            height: 85,
            resizeMode: 'cover',
          }} />}

          {Platform.OS == 'web' && !common_image_extension.includes(obj.url?.split('.').pop()) && <TouchableOpacity
            onPress={() => {
              Linking.openURL(obj.url);
            }}
          >
            <View center style={{
              borderRadius: 4,
              width: 90,
              height: 85,
              backgroundColor: '#f0f0f0',
            }}>
              <Icon name='file-text-outline' width={40} height={40} fill={'#53535f'} />
            </View>
          </TouchableOpacity>}

          <ImageView
            images={[[... (value || []).map((item) => { return { uri: item.url } })][initialIndex]]}
            imageIndex={0}
            visible={visible}
            onRequestClose={() => setIsVisible(false)}
          />
          {!readOnly && <TouchableOpacity
            onPress={() => {
              const newArr = [...(value || [])];
              newArr.splice(i, 1);
              onChange(newArr);
            }}
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
              width: 90,
              background: "#ffdede",
              borderRadius: 5,
              paddingLeft: 10,
              paddingRight: 10,
              marginTop: 6,
              paddingVertical: 3,
            }}>
            <View>
              <Icon name="trash-outline" height={12} width={12} fill={"rgb(226, 21, 21)"} />
            </View>
          </TouchableOpacity>}
        </View>
      }) : null}
      {!readOnly && <TouchableOpacity
        style={{
          marginTop: showNaming ? 8 : 0,
        }}
        onPress={() => {
          if (Platform.OS == 'web') {
            uploadFn('document');
          } else {
            setSelectPickerVisible(true);
          }
        }}>
        <View center style={{
          borderRadius: 4,
          width: 110,
          height: 110,
          borderStyle: 'dashed',
          borderWidth: 2,
          borderColor: '#e0e8f2',
          backgroundColor: '#fafafa',
        }}>
          <Icon name="cloud-upload-outline" height={16} width={16} fill={"#545454"} />
        </View>
        {/* <View>
          {UploadText}
        </View> */}
      </TouchableOpacity>}
    </View>}

    {!multiple && <View style={{
      display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', margin: 4, borderRadius: 4,
      marginLeft: Platform.OS == 'web' ? 0 : 20,
    }}>
      {value ? <View centerV>
        {Platform.OS != 'web' && <TouchableOpacity
          onPress={() => {
            if (common_image_extension.includes(value.split('.').pop())) {
              setInitialIndex(0);
              setIsVisible(true);
            } else {
              Linking.openURL(value);
            }
          }}
          marginR-5 marginB-5>

          {common_image_extension.includes(value.split('.').pop()) && <Image source={{ uri: value }} style={{
            borderRadius: 4,
            width: 90,
            height: 85,
          }} />}

          {!common_image_extension.includes(value.split('.').pop()) && <View center style={{
            borderRadius: 4,
            width: 90,
            height: 85,
            backgroundColor: '#f0f0f0',
          }}>
            <Icon name='file-text-outline' width={40} height={40} fill={'#53535f'} />
          </View>}

        </TouchableOpacity>}

        {Platform.OS == 'web' && common_image_extension.includes(value.split('.').pop()) && <WebImageView width={90} src={value} style={{
          width: 90,
          height: 85,
          resizeMode: 'cover',
        }} />}

        {Platform.OS == 'web' && !common_image_extension.includes(value.split('.').pop()) && <TouchableOpacity
          onPress={() => {
            Linking.openURL(value);
          }}
        >
          <View center style={{
            borderRadius: 4,
            width: 90,
            height: 85,
            backgroundColor: '#f0f0f0',
          }}>
            <Icon name='file-text-outline' width={40} height={40} fill={'#53535f'} />
          </View>
        </TouchableOpacity>}

        <ImageView
          images={[{ uri: value }]}
          imageIndex={0}
          visible={visible}
          onRequestClose={() => setIsVisible(false)}
        />

        {!readOnly && <TouchableOpacity
          onPress={() => {
            onChange(null);
          }}
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            width: 90,
            background: "#ffdede",
            borderRadius: 5,
            paddingLeft: 10,
            paddingRight: 10,
            marginTop: 6,
            paddingVertical: 3,
          }}>
          <View>
            <Icon name="trash-outline" height={12} width={12} fill={"rgb(226, 21, 21)"} />
          </View>
        </TouchableOpacity>}
      </View>
        :
        <View row centerV>
          <TouchableOpacity
            onPress={() => {
              if (Platform.OS == 'web') {
                uploadFn('document');
              } else {
                setSelectPickerVisible(true);
              }
            }}>
            <View center style={{
              borderRadius: 4,
              width: 110,
              height: 110,
              borderStyle: 'dashed',
              borderWidth: 2,
              borderColor: '#e0e8f2',
              backgroundColor: '#fafafa',
            }}>
              <Icon name="cloud-upload-outline" height={16} width={16} fill={"#545454"} />
            </View>
          </TouchableOpacity>
        </View>}
    </View>}

    <ActionSheet
      visible={selectPickerVisible}
      title={'Upload'}
      message={'Select the source of the file'}
      useNativeIOS={true}
      options={[
        {
          label: 'Camera', onPress: () => {
            uploadFn('camera');
          }
        },
        {
          label: 'Gallery', onPress: () => {
            uploadFn('gallery');
          }
        },
        {
          label: 'Document', onPress: () => {
            uploadFn('document');
          }
        },
        {
          label: 'Cancel', onPress: () => {
            setSelectPickerVisible(false);
          }
        },
      ]}
      cancelButtonIndex={3}
      onDismiss={() => setSelectPickerVisible(false)}
    />

  </View>
  </>

  return component;
}

export const CustomButton = (props) => {

  if (Platform.OS == 'web') {
    // if (props?.buttonProps) {
    //   return <Button
    //     onPress={props.onClick}
    //     {...props.buttonProps}
    //   >
    //     {props?.buttonProps?.iconSource ? <img src={props?.buttonProps?.iconSource?.uri} style={props?.buttonProps?.iconStyle} /> : <></>}
    //     <Text style={{ ...props?.buttonProps?.labelStyle }}>
    //       {props.text}
    //     </Text>
    //   </Button>;
    // } else {
    //   return <Button
    //     onPress={props.onClick}
    //     label={props.text}
    //   />
    // }
  }

  return <View flex style={{
    width: '100%',
  }}>
    <SecondaryButton
      onPress={props.onClick}
      label={props.text}
    />
  </View>
}

export const CustomDropDown = ({ value, onChange, options, children = null, webStyle = {} }) => {
  const [open, setOpen] = useState(false);
  const [items, setItems] = useState([]);

  useEffect(() => {
    let newItems = [];
    for (const option of options) {
      newItems.push({
        key: option.value,
        label: (<TouchableOpacity
          onPress={() => {
            if (option.onSelect)
              option.onSelect && option.onSelect(option);
            else
              onChange && onChange(option);
          }}>
          <Text style={{ color: 'black' }}>{option.label}</Text>
        </TouchableOpacity>),
      });
    }

    setItems(newItems);
  }, [options]);

  const web_component = <Dropdown menu={{ items }} trigger={['click']}>
    {children ? children : <View flex>
      <div className="pointer"><View style={{
        paddingVertical: value == 'menu-icon' ? 10 : 11,
        ...webStyle
      }}>
        <Text style={{ fontFamily: 'SourceSansProSemiBold' }}>
          {value == 'menu-icon' && <Icon name="more-vertical-outline" height={16} />}
          {value == 'list-icon' && <Icon name="list-outline" height={16} />}
          {!(["menu-icon", "list-icon"].includes(value)) && value}
        </Text>
      </View></div>
    </View>}
  </Dropdown>;

  const mobile_component = <View>
    <TouchableOpacity onPress={() => {
      setOpen(true);
    }}>
      {children ? children : <View>
        <SecondaryButton style={{
          paddingVertical: value == 'menu-icon' ? 10 : 11,
          borderWidth: 0,
        }} onPress={() => {
          setOpen(true);
        }}>
          <Text style={{ fontFamily: 'SourceSansProSemiBold' }}>
            {value == 'menu-icon' ? <Icon name="more-vertical-outline" height={16} /> : value}
          </Text>
        </SecondaryButton>
      </View>}
    </TouchableOpacity>

    <ActionSheet
      useNativeIOS
      showCancelButton
      visible={open}
      onDismiss={() => setOpen(false)}
      options={
        [
          ...((options || []).map((option) => {
            return {
              label: option.label,
              onPress: () => {
                if (option.onSelect)
                  option.onSelect && option.onSelect(option);
                else
                  onChange && onChange(option);
              }
            }
          })),
        ]
      }
    />
  </View>;

  return Platform.OS === 'web' ? web_component : mobile_component;
}

export const CustomTabsForModule = ({ tabs = [], onChange, activeKey,
  moduleName,
  filters,
  group_by,
  columns,
  sortColumn,
  sortDirection,
  pageSize
}) => {
  const [viewName, setViewName] = useState(activeKey);
  const [id, setId] = useState(null);
  const [openSaveViewModal, setOpenSaveViewModal] = useState(false);

  const saveTab = async () => {
    if (!viewName) {
      return;
    }

    if (viewName == 'All') {
      return;
    }

    await api.saveView(moduleName, {
      _id: id,
      name: viewName,
      filters,
      group_by,
      columns,
      sortColumn,
      sortDirection,
      pageSize,
    });

    setOpenSaveViewModal(false);
    onChange && onChange(viewName);
  }

  useEffect(() => {
    if (activeKey != 'All') {
      setViewName(activeKey);
      const tab = tabs.find((tab) => tab.name == activeKey);
      if (tab) {
        setId(tab._id);
      } else {
        setId(null);
      }
    } else {
      setId(null);
    }
  }, [activeKey]);

  const web_component = <Tabs
    activeKey={tabs.find((tab) => tab.name == activeKey)?.name || 'All'} onChange={(e) => {
      if (e == 'All') {
        onChange && onChange("All")
      } else {
        const tab = tabs.find((tab) => tab.name == e);
        onChange && onChange(tab.name)
      }
    }} items={[{
      key: 'All',
      label: 'All',
      value: {},
    }, ...tabs.map((tab) => {
      return {
        ...tab,
        key: tab.name,
        value: tab.name,
        label: tab.name,
      }
    })]}
    tabBarExtraContent={<View row centerV>
      <CustomDropDown value={'list-icon'} options={[
        { label: 'List', value: 'List' },
        // { label: 'Cards', value: 'Cards' },
        // { label: 'Calendar', value: 'Calendar' },
        // { label: 'Grant', value: 'Grant' },
      ]}
        webStyle={{
          paddingHorizontal: 10,
        }}
        onChange={() => {

        }}
      />
      <TouchableOpacity style={{
        marginRight: 10, paddingHorizontal: 5,
        position: 'relative',
        top: -2,
      }} onPress={() => {
        setOpenSaveViewModal(true);
      }}>
        <Text style={{
          color: '#29459c',
          textTransform: 'uppercase',
          fontWeight: '700',
          letterSpacing: 1,
          fontSize: 12,
        }}>
          {tabs.find((tab) => tab.name == viewName) ? 'Edit ' : 'Save '}
          View
        </Text>
      </TouchableOpacity>
    </View>}
  />

  const mobile_component = <View>
    {/* {tabs && tabs.length >= 0 && (<>
      <ScrollView horizontal showsHorizontalScrollIndicator={false}>
        <View style={
          { flexDirection: 'row', alignItems: 'center', flex: 1 }
        }>
          {[{
            key: 'All',
            label: 'All',
            value: {},
          }, ...tabs.map((tab) => {
            return {
              ...tab,
              key: tab.name,
              value: tab.name,
              label: tab.name,
            }
          })].map((tab, index) => (
            <TouchableOpacity
              key={index.toString()}
              style={[
                (tabs.find((tab) => tab.name == activeKey)?.name || 'All') == tab.value ?
                  { padding: 10, borderBottomColor: 'rgb(17, 104, 191)', borderBottomWidth: 3 } :
                  { padding: 10 }
              ]}
              onPress={() => {
                onChange && onChange(tab.value);
              }}
            >
              <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                <View style={{ marginLeft: 5 }}>
                  <Text style={{ color: (tabs.find((tab) => tab.name == activeKey)?.name || 'All') == tab.value ? 'rgb(44, 126, 208)' : 'black' }}>
                    {tab.label}</Text>
                </View>
              </View>
            </TouchableOpacity>
          ))}
        </View>
      </ScrollView>

      <View style={{
        borderBottomColor: '#e0e3f2bf',
        borderBottomWidth: 1,
        marginBottom: 0,
        marginTop: 0,
        borderStyle: 'solid',
        marginHorizontal: 10
      }} />
    </>)} */}
  </View>

  return <View>
    <CustomModal width={400} title={''} drawer visible={openSaveViewModal} onClose={() => setOpenSaveViewModal(false)}>
      <View padding-20>
        {Label({ label: "View Name" })}
        <View marginT-10>
          <CustomTextField value={viewName} onChange={(e) => {
            setViewName(e);
          }} />
        </View>

        <View marginT-20>
          <PrimaryButton label={
            tabs.find((tab) => tab.name == viewName) ? 'Update' : 'Save'
          } onPress={() => {
            saveTab();
            onChange && onChange(viewName);
          }} />
        </View>

        {tabs.find((tab) => tab.name == viewName) && <View marginT-20>
          <DangerButton label={'Delete'} onPress={() => {
            api.deleteView(moduleName, {
              _id: id,
            }).then(() => {
              setOpenSaveViewModal(false);
              onChange && onChange('All');
            })
          }} />
        </View>}
      </View>
    </CustomModal>
    {Platform.OS === 'web' ? web_component : mobile_component}
  </View>
}

export const DangerButton = ({ onPress, label = null, children = null, style = {} }) => {
  return <TouchableOpacity
    onPress={() => {
      onPress && onPress();
    }}
    style={[Platform.OS == 'web' ? {
      padding: 8,
      paddingHorizontal: 20,
      borderWidth: 1,
      borderColor: '#dcdcdc75',
      backgroundColor: 'white',
      borderRadius: 8,
      alignItems: "center",
      justifyContent: "center",
    } : {
      padding: 5,
      paddingHorizontal: 30,
      borderWidth: 1,
      borderColor: '#dcdcdc75',
      backgroundColor: 'white',
      borderRadius: 8,
      alignItems: "center",
      justifyContent: "center",
      height: 42,
    }, style
    ]}
  >
    {label ? <Text style={{ color: '#b60505', fontFamily: 'SourceSansProSemiBold' }}>
      {label}
    </Text> : null}

    {children}
  </TouchableOpacity>
}

export const DeleteButton = ({ onPress, label = null, children = null, style = {} }) => {
  return <TouchableOpacity onPress={() => {
    onPress && onPress();
  }}>
    <Image source={{ uri: `https://inkapps.pages.dev/icons/delete.png` }}
      style={{ width: 16, height: 16, marginLeft: 10, marginRight: 8 }} />
  </TouchableOpacity>
}

export const SecondaryButton = ({ onPress = null, label = null, children = null, style = {}, disabled = false }) => {
  return <TouchableOpacity
    onPress={() => {
      if (!disabled)
        onPress && onPress();
    }}
    style={[Platform.OS == 'web' ? {
      padding: 8,
      paddingHorizontal: 20,
      borderWidth: 1,
      borderColor: '#cfdeff',
      backgroundColor: '#ecf2ff6b',
      borderRadius: 8,
      alignItems: "center",
      justifyContent: "center",
      opacity: disabled ? 0.5 : 1,
    } : {
      padding: 5,
      paddingHorizontal: 30,
      borderWidth: 1,
      borderColor: '#cfdeff',
      backgroundColor: '#ecf2ff6b',
      borderRadius: 8,
      alignItems: "center",
      justifyContent: "center",
      height: 42,
      opacity: disabled ? 0.5 : 1,
    }, style
    ]}
  >
    {label ? <Text style={{ color: '#29459c', fontFamily: 'SourceSansProSemiBold' }}>
      {label}
    </Text> : null}

    {children}
  </TouchableOpacity>
}

export const PrimaryButton = ({ onPress, label = null, children = null, style = {}, disabled = false }) => {
  return <TouchableOpacity
    onPress={() => {
      if (!disabled)
        onPress && onPress();
    }}
    style={[Platform.OS == 'web' ? {
      padding: 7,
      paddingHorizontal: 20,
      borderWidth: 1,
      // backgroundColor: '#4d81f1',
      backgroundColor: disabled ? '#dcdcdc75' : '#2062cf',
      // borderColor: '#2062cf',
      borderColor: disabled ? '#dcdcdc75' : '#2062cf',
      borderRadius: 8,
      alignItems: "center",
      justifyContent: "center",
    } : {
      padding: 5,
      paddingHorizontal: 30,
      borderWidth: 1,
      backgroundColor: '#29459C',
      borderColor: '#29459C',
      borderRadius: 8,
      alignItems: "center",
      justifyContent: "center",
      height: 42,
    }, { ...(style || {}) }
    ]}
    aria-label="Primary Button"
  >
    {label ? <Text style={{
      color: disabled ? '#ababab' : 'white',
      fontFamily: 'SourceSansProSemiBold'
    }}>
      {label}
    </Text> : null}

    {children}
  </TouchableOpacity>
}

export const FiltersComponent = ({ filters, selectedFilters = [], updateSelectedFilters, createData = {}, visible, autoApply = false }) => {
  const [tempSelectedFilters, setTempSelectedFilters] = useState((selectedFilters || []));

  useEffect(() => {
    if (!autoApply) {
      setTempSelectedFilters(selectedFilters);
    }
  }, [selectedFilters, visible]);

  useEffect(() => {
    if (autoApply) {
      updateSelectedFilters(tempSelectedFilters);
    }
  }, [tempSelectedFilters]);

  return <View>
    {tempSelectedFilters.map((filter, index) => <View row marginT-20 centerV key={index.toString()}>
      <View marginR-10>
        <Text>
          Where
        </Text>
      </View>
      <View marginR-10 flex>
        <CustomSelect
          label="Field"
          options={filters.map(filter => ({ label: filter.label, value: filter.field }))}
          value={filter.field}
          onChange={(value) => {
            const selectedFilter = filters.find(filter => filter.field === value);
            const filterNew = { ...filter };

            filterNew.field = selectedFilter?.field;
            filterNew.operators = selectedFilter?.operators;
            filterNew.type = selectedFilter?.type;
            filterNew.operator = selectedFilter?.operators[0].value;
            filterNew.route = selectedFilter?.route;
            filterNew.routeParams = selectedFilter?.routeParams;
            filterNew.options = selectedFilter?.options;
            filterNew.createDataKey = selectedFilter?.createDataKey;

            setTempSelectedFilters(tempSelectedFilters.map((fil, i) => {
              if (i == index) {
                return filterNew;
              } else {
                return fil;
              }
            }));
          }}
        />
      </View>
      <View marginR-10 flex>
        <CustomSelect
          label="Operator"
          options={filter.operators || []}
          value={filter.operator}
          onChange={(value) => {
            const filterNew = { ...filter };
            filterNew.operator = value;
            delete filterNew.value;

            setTempSelectedFilters(tempSelectedFilters.map((fil, i) => {
              if (i == index) {
                return filterNew;
              } else {
                return fil;
              }
            }));
          }}
        />
      </View>
      <View flex centerV>
        {filter.type == 'select' && <CustomSelect
          label="Value"
          options={(filter.options || createData[filter.createDataKey] || [])}
          value={filter.value}
          onChange={(value) => {
            const filterNew = { ...filter };
            filterNew.value = value;

            setTempSelectedFilters(tempSelectedFilters.map((fil, i) => {
              if (i == index) {
                return filterNew;
              } else {
                return fil;
              }
            }));
          }}
          multiple
        />}

        {filter.type == 'text' && ([
          "eq", "ne", "startsWith", "endsWith", "contains"
        ].includes(filter.operator)) && <CustomTextField
            label="Value"
            value={filter.value}
            onChange={(value) => {
              const filterNew = { ...filter };
              filterNew.value = value;

              setTempSelectedFilters(tempSelectedFilters.map((fil, i) => {
                if (i == index) {
                  return filterNew;
                } else {
                  return fil;
                }
              }));
            }}
          />}

        {filter.type == 'number' && ([
          "eq", "ne", "gt", "lt", "gte", "lte"
        ].includes(filter.operator)) && <CustomNumberInput
            label="Value"
            value={filter.value}
            onChange={(value) => {
              const filterNew = { ...filter };
              filterNew.value = value;

              setTempSelectedFilters(tempSelectedFilters.map((fil, i) => {
                if (i == index) {
                  return filterNew;
                } else {
                  return fil;
                }
              }));
            }}
          />}

        {filter.type == 'date' && ([
          "eq", "ne", "gt", "lt", "gte", "lte", "dateRange"
        ].includes(filter.operator)) && <CustomDatePicker
            label="Value"
            value={filter.value}
            onChange={(value) => {
              const filterNew = { ...filter };
              filterNew.value = value;

              setTempSelectedFilters(tempSelectedFilters.map((fil, i) => {
                if (i == index) {
                  return filterNew;
                } else {
                  return fil;
                }
              }));
            }}
          />}

        {filter.type == 'datetime' && ([
          "eq", "ne", "gt", "lt", "gte", "lte", "dateRange"
        ].includes(filter.operator)) && <CustomDateTimePicker
            label="Value"
            value={filter.value}
            onChange={(value) => {
              const filterNew = { ...filter };
              filterNew.value = value;

              setTempSelectedFilters(tempSelectedFilters.map((fil, i) => {
                if (i == index) {
                  return filterNew;
                } else {
                  return fil;
                }
              }));
            }}
          />}

        {filter.type == 'time' && ([
          "eq", "ne", "gt", "lt", "gte", "lte", "dateRange"
        ].includes(filter.operator)) && <CustomTimePicker
            label="Value"
            value={filter.value}
            onChange={(value) => {
              const filterNew = { ...filter };
              filterNew.value = value;

              setTempSelectedFilters(tempSelectedFilters.map((fil, i) => {
                if (i == index) {
                  return filterNew;
                } else {
                  return fil;
                }
              }));
            }}
          />}

        {filter.type == 'boolean' && ([
          "eq"
        ].includes(filter.operator)) && <View row centerV>
            <CustomCheckbox
              value={filter.value}
              onChange={(value) => {
                const filterNew = { ...filter };
                filterNew.value = value;

                setTempSelectedFilters(tempSelectedFilters.map((fil, i) => {
                  if (i == index) {
                    return filterNew;
                  } else {
                    return fil;
                  }
                }));
              }}
            />
            <View marginL-5>
              <Text>
                {filter.value ? 'True' : 'False'}
              </Text>
            </View>
          </View>}

      </View>

      <View centerV>
        <DeleteButton onPress={() => {
          setTempSelectedFilters(tempSelectedFilters.filter((fil, i) => i != index));
        }} />
      </View>
    </View>)}

    <View marginT-10 left>
      <TouchableOpacity onPress={() => {
        setTempSelectedFilters([...tempSelectedFilters, {}])
      }}>
        <View margin-5 centerV row>
          <View marginR-5>
            <Icon name="plus-outline" height={12} width={12} fill={'rgb(32, 48, 60)'} />
          </View>
          <Text>
            Add Filter
          </Text>
        </View>
      </TouchableOpacity>
    </View>

    {!autoApply && <View marginT-15 row>
      <PrimaryButton
        label="Apply"
        onPress={() => {
          updateSelectedFilters(tempSelectedFilters);
        }}
      />
      <SecondaryButton
        style={{
          marginLeft: 10
        }}
        label="Clear All"
        onPress={() => {
          updateSelectedFilters([]);
        }}
      />
    </View>}
  </View>
}






export const TableField = ({ field, useForm, viewOnly = false }) => {
  let columns = field.columns ? [...field.columns] : [];

  columns = columns.filter(column => column.visible !== false);

  if (field.actions) {
    columns.push({
      Header: (props) => null,
      accessor: 'actions',
    })
  }

  const timer = useRef(null);

  const value = useForm((state) => state.formObject[field.key]);
  const setFormObject = useForm((state) => state.setFormObject);

  return <CustomTable
    {...field}
    data={value || []}
    updateData={(data) => {
      if (field.forceUpdate) {
        setFormObject({
          [field.key]: data,
          updateVisiblity: Math.random()
        });
      } else {
        setFormObject({
          [field.key]: data,
        });
      }
    }}
    columns={columns}
    resizable={false}
    actions={field.actions}
    actionFunctions={field.actionFunctions}
    editable={field.editable}
    viewOnly={viewOnly}
    useForm={useForm}
    tableKey={field.key}
    showCheckbox={field.showCheckbox}
    onCheckboxChange={field.onCheckboxChange}
  />
}

export const CheckboxField = React.memo(({ field, useForm, viewOnly = false, tKey = null, tIndex = null }) => {
  const timer = useRef(null);

  const value = useForm((state) => state.formObject?.[tKey]?.[tIndex]?.[field.key] ?? state.formObject?.[field.key]);;
  const setFormObject = useForm((state) => state.setFormObject);
  const setFormObjectArray = useForm((state) => state.setFormObjectArray);

  const getFormObjectArrayValue = useForm((state) => state.getFormObjectArrayValue);
  const getFormObject = useForm((state) => state.getFormObject);

  return !viewOnly ? <CustomCheckbox
    value={value}
    onBlur={field.onBlur}
    label={field.label}
    onChange={(e) => {
      if (tKey) {
        setFormObjectArray(tKey, tIndex, field.key, e);
      } else {
        setFormObject({ [field.key]: e })
      }

      if (field.debounce) {
        clearTimeout(timer.current);
        timer.current = setTimeout(() => {
          field.onChange && field.onChange({
            value: e,
            tKey,
            tIndex,
            getObj: getFormObject,
            setObj: setFormObject,
            getArrValue: getFormObjectArrayValue,
            setArrValue: setFormObjectArray,
          });
        }, 500);
      } else {
        field.onChange && field.onChange({
          value: e,
          tKey,
          tIndex,
          getObj: getFormObject,
          setObj: setFormObject,
          getArrValue: getFormObjectArrayValue,
          setArrValue: setFormObjectArray,
        });
      }
    }}
  /> : <Text>{field.value ? 'Yes' : 'No'}</Text>
})

export const SwitchField = React.memo(({ field, useForm, viewOnly = false, tKey = null, tIndex = null }) => {
  const timer = useRef(null);

  const value = useForm((state) => state.formObject?.[tKey]?.[tIndex]?.[field.key] ?? state.formObject?.[field.key]);;
  const setFormObject = useForm((state) => state.setFormObject);
  const setFormObjectArray = useForm((state) => state.setFormObjectArray);

  const getFormObjectArrayValue = useForm((state) => state.getFormObjectArrayValue);
  const getFormObject = useForm((state) => state.getFormObject);

  return !viewOnly ? <CustomSwitch
    value={value}
    onBlur={field.onBlur}
    label={field.label}
    onChange={(e) => {
      if (tKey) {
        setFormObjectArray(tKey, tIndex, field.key, e);
      } else {
        setFormObject({ [field.key]: e })
      }

      if (field.debounce) {
        clearTimeout(timer.current);
        timer.current = setTimeout(() => {
          field.onChange && field.onChange({
            value: e,
            tKey,
            tIndex,
            getObj: getFormObject,
            setObj: setFormObject,
            getArrValue: getFormObjectArrayValue,
            setArrValue: setFormObjectArray,
          });
        }, 500);
      } else {
        field.onChange && field.onChange({
          value: e,
          tKey,
          tIndex,
          getObj: getFormObject,
          setObj: setFormObject,
          getArrValue: getFormObjectArrayValue,
          setArrValue: setFormObjectArray,
        });
      }
    }}
  />
    : <Text>{field.value ? 'Yes' : 'No'}</Text>
})

export const SelectField = React.memo(({ field, useForm, viewOnly = false, tKey = null, tIndex = null }) => {
  // const [options, setOptions] = useState(field.options || []);

  const timer = useRef(null);
  const value = useForm((state) => tKey ? state.formObject?.[tKey]?.[tIndex]?.[field.key] : state.formObject?.[field.key]);

  const setFormObject = useForm((state) => state.setFormObject);
  const setFormObjectArray = useForm((state) => state.setFormObjectArray);

  const getFormObjectArrayValue = useForm((state) => state.getFormObjectArrayValue);
  const getFormObject = useForm((state) => state.getFormObject);

  if (field.customRenderForSelectedOption && value) {
    return <field.customRenderForSelectedOption
      value={value}
      field={field}
      useForm={useForm}
      tKey={tKey}
      tIndex={tIndex}
    />
  }

  return !viewOnly ? <>
    <CustomSelect
      {...field}
      value={value}
      onBlur={field.onBlur}
      onChange={(e) => {
        const value = e?.value || e;
        if (tKey) {
          setFormObjectArray(tKey, tIndex, field.key, value);
        } else {
          setFormObject({ [field.key]: value })
        }

        const changeFn = field.onChange && field.onChange({
          value: value,
          tKey,
          tIndex,
          getObj: getFormObject,
          setObj: setFormObject,
          getArrValue: getFormObjectArrayValue,
          setArrValue: setFormObjectArray,
          serial_number: e?.serial_number,
        });

        if (field.debounce) {
          clearTimeout(timer.current);
          timer.current = setTimeout(() => {
            changeFn && changeFn();
          }, 500);
        } else {
          changeFn && changeFn();
        }
      }}
      options={field.options || []}
      autoFocus={field.autoFocus}
    />
    {field.extraRenderForSelectedOption && value && <View>
      <field.extraRenderForSelectedOption
        value={value}
        field={field}
        useForm={useForm}
        tKey={tKey}
        tIndex={tIndex}
      />
    </View>}
  </>
    : <Text>
      {field.extraViewRender && field.extraViewRender({
        value: value,
        tKey,
        tIndex,
        getObj: getFormObject,
        setObj: setFormObject,
        getArrValue: getFormObjectArrayValue,
        setArrValue: setFormObjectArray
      })}
      {!field.multiple && [...(field.options || [])].find((o) => o.value === value)?.label || ''}
      {field.multiple && [...(field.options || [])].filter((o) => value.includes(o.value)).map((o) => o.label).join(', ')}
    </Text>
})

export const AutoCompleteField = React.memo(({ field, useForm, viewOnly = false, tKey = null, tIndex = null }) => {
  const [options, setOptions] = useState(field.options || []);

  const timer = useRef(null);

  const value = useForm((state) => tKey ? state.formObject?.[tKey]?.[tIndex]?.[field.key] : state.formObject?.[field.key]);

  const setFormObject = useForm((state) => state.setFormObject);
  const setFormObjectArray = useForm((state) => state.setFormObjectArray);

  const getFormObjectArrayValue = useForm((state) => state.getFormObjectArrayValue);
  const getFormObject = useForm((state) => state.getFormObject);

  return !viewOnly ? <CustomAutoComplete
    {...field}
    value={value}
    onBlur={field.onBlur}
    onChange={(e) => {
      const value = e?.value || e;
      if (tKey) {
        setFormObjectArray(tKey, tIndex, field.key, value);
      } else {
        setFormObject({ [field.key]: value })
      }

      const changeFn = field.onChange && field.onChange({
        value: value,
        tKey,
        tIndex,
        getObj: getFormObject,
        setObj: setFormObject,
        getArrValue: getFormObjectArrayValue,
        setArrValue: setFormObjectArray,
        serial_number: e?.serial_number,
      });

      if (field.debounce) {
        clearTimeout(timer.current);
        timer.current = setTimeout(() => {
          changeFn && changeFn();
        }, 500);
      } else {
        changeFn && changeFn();
      }
    }}
    options={options}
    autoFocus={field.autoFocus}
    onSearch={(search) => {
      if (field.onSearch) {
        field.onSearch(search).then((res) => {
          setOptions(res);
        });
      }
    }}
    onSelect={(e) => {
      if (field.onSelect) {
        field.onSelect({
          value: e,
          tKey,
          tIndex,
          getObj: getFormObject,
          setObj: setFormObject,
          getArrValue: getFormObjectArrayValue,
          setArrValue: setFormObjectArray,
        });
      }
    }}
  /> : <Text>
    {([...(field.options || [])].find((o) => o.value === value)?.label) || value || ''}
  </Text>
})

export const NumberField = React.memo(({ field, useForm, viewOnly = false, tKey = null, tIndex = null }) => {
  const timer = useRef(null);

  const value = useForm((state) => tKey ? state.formObject?.[tKey]?.[tIndex]?.[field.key] : state.formObject?.[field.key]);

  const setFormObject = useForm((state) => state.setFormObject);
  const setFormObjectArray = useForm((state) => state.setFormObjectArray);

  const getFormObjectArrayValue = useForm((state) => state.getFormObjectArrayValue);
  const getFormObject = useForm((state) => state.getFormObject);

  return !viewOnly ? <CustomNumberInput
    {...field}
    value={value}
    onBlur={() => field.onBlur && field.blur(field.value)}
    onChange={(e) => {
      if (tKey) {
        setFormObjectArray(tKey, tIndex, field.key, e);
      } else {
        setFormObject({ [field.key]: e })
      }

      if (field.debounce) {
        clearTimeout(timer.current);
        timer.current = setTimeout(() => {
          field.onChange && field.onChange({
            value: e,
            tKey,
            tIndex,
            getObj: getFormObject,
            setObj: setFormObject,
            getArrValue: getFormObjectArrayValue,
            setArrValue: setFormObjectArray,
          });
        }, 500);
      } else {
        field.onChange && field.onChange({
          value: e,
          tKey,
          tIndex,
          getObj: getFormObject,
          setObj: setFormObject,
          getArrValue: getFormObjectArrayValue,
          setArrValue: setFormObjectArray,
        });
      }
    }}
  /> :
    <View row centerV>
      {field.currency && <Text value style={{
        lineHeight: 39
      }}>
        S$
      </Text>}
      <Text value style={{
        lineHeight: 39
      }}>
        {(value >= 0 || value < 0) ? "" + value : ''}
      </Text>
    </View>
})

export const DurationField = React.memo(({ field, useForm, viewOnly = false, tKey = null, tIndex = null }) => {
  const timer = useRef(null);

  const value = useForm((state) => tKey ? state.formObject?.[tKey]?.[tIndex]?.[field.key] : state.formObject?.[field.key]);

  const setFormObject = useForm((state) => state.setFormObject);
  const setFormObjectArray = useForm((state) => state.setFormObjectArray);

  const getFormObjectArrayValue = useForm((state) => state.getFormObjectArrayValue);
  const getFormObject = useForm((state) => state.getFormObject);

  return !viewOnly ? <CustomDurationInput
    {...field}
    value={value}
    onBlur={() => field.onBlur && field.blur(field.value)}
    onChange={(e) => {
      if (tKey) {
        setFormObjectArray(tKey, tIndex, field.key, e);
      } else {
        setFormObject({ [field.key]: e })
      }

      if (field.debounce) {
        clearTimeout(timer.current);
        timer.current = setTimeout(() => {
          field.onChange && field.onChange({
            value: e,
            tKey,
            tIndex,
            getObj: getFormObject,
            setObj: setFormObject,
            getArrValue: getFormObjectArrayValue,
            setArrValue: setFormObjectArray,
          });
        }, 500);
      } else {
        field.onChange && field.onChange({
          value: e,
          tKey,
          tIndex,
          getObj: getFormObject,
          setObj: setFormObject,
          getArrValue: getFormObjectArrayValue,
          setArrValue: setFormObjectArray,
        });
      }
    }}
  /> : <Text value style={{ lineHeight: 39 }}>
    {field.value > 0 ? humanizeDuration(field.value, { units: ['y', 'mo', 'd', 'h', 'm'], round: true, largest: 2 }) : ''}
  </Text>
})

export const TextField = React.memo(({ field, useForm, viewOnly = false, tKey = null, tIndex = null }) => {
  const timer = useRef(null);
  const value = useForm((state) => tKey ? state.formObject?.[tKey]?.[tIndex]?.[field.key] : state.formObject?.[field.key]);

  const setFormObject = useForm((state) => state.setFormObject);
  const setFormObjectArray = useForm((state) => state.setFormObjectArray);
  const getFormObject = useForm((state) => state.getFormObject);
  const getFormObjectArrayValue = useForm((state) => state.getFormObjectArrayValue);

  return !viewOnly ? <CustomTextField
    {...field}
    validation={field.validation}
    value={value}
    onBlur={(e) => {
      field.onBlur && field.onBlur({
        value: e,
        tKey,
        tIndex,
        getObj: getFormObject,
        setObj: setFormObject,
        getArrValue: getFormObjectArrayValue,
        setArrValue: setFormObjectArray,
      })
    }}
    onChange={(e) => {
      if (tKey) {
        setFormObjectArray(tKey, tIndex, field.key, e);
      } else {
        setFormObject({ [field.key]: e })
      }

      if (field.debounce) {
        clearTimeout(timer.current);
        timer.current = setTimeout(() => {
          field.onChange && field.onChange({
            value: e,
            tKey,
            tIndex,
            getObj: getFormObject,
            setObj: setFormObject,
            getArrValue: getFormObjectArrayValue,
            setArrValue: setFormObjectArray,
          })
        }, 500);
      } else {
        field.onChange && field.onChange({
          value: e,
          tKey,
          tIndex,
          getObj: getFormObject,
          setObj: setFormObject,
          getArrValue: getFormObjectArrayValue,
          setArrValue: setFormObjectArray,
        })
      }
    }}
  /> : <Text value>{tKey ? value : field.value}</Text>
})

export const TextAreaField = React.memo(({ field, useForm, viewOnly = false, tKey = null, tIndex = null }) => {
  const timer = useRef(null);

  const value = useForm((state) => tKey ? state.formObject?.[tKey]?.[tIndex]?.[field.key] : state.formObject?.[field.key]);;
  const setFormObject = useForm((state) => state.setFormObject);
  const setFormObjectArray = useForm((state) => state.setFormObjectArray);

  const getFormObjectArrayValue = useForm((state) => state.getFormObjectArrayValue);
  const getFormObject = useForm((state) => state.getFormObject);

  return !viewOnly ? <CustomTextAreaField
    {...field}
    validation={field.validation}
    value={value}
    onBlur={field.onBlur}
    onChange={(e) => {
      if (tKey) {
        setFormObjectArray(tKey, tIndex, field.key, e);
      } else {
        setFormObject({ [field.key]: e })
      }

      if (field.debounce) {
        clearTimeout(timer.current);
        timer.current = setTimeout(() => {
          field.onChange && field.onChange({
            value: e,
            tKey,
            tIndex,
            getObj: getFormObject,
            setObj: setFormObject,
            getArrValue: getFormObjectArrayValue,
            setArrValue: setFormObjectArray,
          });
        }, 500);
      } else {
        field.onChange && field.onChange({
          value: e,
          tKey,
          tIndex,
          getObj: getFormObject,
          setObj: setFormObject,
          getArrValue: getFormObjectArrayValue,
          setArrValue: setFormObjectArray,
        });
      }
    }}
  /> : <Text value>{field.value}</Text>
})

export const EditorField = React.memo(({ field, useForm, viewOnly = false, tKey = null, tIndex = null }) => {
  const value = useForm((state) => state.formObject[field.key]);
  const setFormObject = useForm((state) => state.setFormObject);
  const setFormObjectArray = useForm((state) => state.setFormObjectArray);

  const getFormObjectArrayValue = useForm((state) => state.getFormObjectArrayValue);
  const getFormObject = useForm((state) => state.getFormObject);

  return !viewOnly ? <CustomEditorField
    {...field}
    validation={field.validation}
    value={value}
    onBlur={field.onBlur}
    onChange={(e) => {
      if (tKey) {
        setFormObjectArray(tKey, tIndex, field.key, e);
      } else {
        setFormObject({ [field.key]: e })
      }

      field.onChange && field.onChange({
        value: e,
        tKey,
        tIndex,
        getObj: getFormObject,
        setObj: setFormObject,
        getArrValue: getFormObjectArrayValue,
        setArrValue: setFormObjectArray,
      });
    }}
  /> : Platform.OS === 'web' ? <div dangerouslySetInnerHTML={{ __html: field.value }} /> : <RenderHtml contentWidth={Dimensions.get('window').width - 40} source={{ html: field.value }} />
});

export const SerialNumberTextArea = React.memo(({ field, useForm, viewOnly = false, tKey = null, tIndex = null }) => {
  const timer = useRef(null);

  const value = useForm((state) => tKey ? state.formObject?.[tKey]?.[tIndex]?.[field.key] : state.formObject?.[field.key]);;
  const setFormObject = useForm((state) => state.setFormObject);
  const setFormObjectArray = useForm((state) => state.setFormObjectArray);

  const getFormObjectArrayValue = useForm((state) => state.getFormObjectArrayValue);
  const getFormObject = useForm((state) => state.getFormObject);

  return !viewOnly ? <CustomSerialNumberTextArea
    value={value}
    onBlur={field.onBlur}
    onChange={(e) => {
      if (tKey) {
        setFormObjectArray(tKey, tIndex, field.key, e);
      } else {
        setFormObject({ [field.key]: e })
      }

      if (field.debounce) {
        clearTimeout(timer.current);
        timer.current = setTimeout(() => {
          field.onChange && field.onChange({
            value: e,
            tKey,
            tIndex,
            getObj: getFormObject,
            setObj: setFormObject,
            getArrValue: getFormObjectArrayValue,
            setArrValue: setFormObjectArray,
          });
        }, 500);
      } else {
        field.onChange && field.onChange({
          value: e,
          tKey,
          tIndex,
          getObj: getFormObject,
          setObj: setFormObject,
          getArrValue: getFormObjectArrayValue,
          setArrValue: setFormObjectArray,
        });
      }
    }}
  />
    : field.value && field.value.map((item, index) => <Text key={index.toString()} value>{item}</Text>)
})

export const DateField = React.memo(({ field, useForm, viewOnly = false, tKey = null, tIndex = null }) => {
  const timer = useRef(null);

  const value = useForm((state) => state.formObject?.[tKey]?.[tIndex]?.[field.key] ?? state.formObject?.[field.key]);;
  const setFormObject = useForm((state) => state.setFormObject);
  const setFormObjectArray = useForm((state) => state.setFormObjectArray);

  const getFormObjectArrayValue = useForm((state) => state.getFormObjectArrayValue);
  const getFormObject = useForm((state) => state.getFormObject);

  return !viewOnly ? <CustomDatePicker
    {...field}
    value={value}
    onBlur={field.onBlur}
    onChange={(e) => {
      if (tKey) {
        setFormObjectArray(tKey, tIndex, field.key, e);
      } else {
        setFormObject({ [field.key]: e })
      }

      if (field.debounce) {
        clearTimeout(timer.current);
        timer.current = setTimeout(() => {
          field.onChange && field.onChange({
            value: e,
            tKey,
            tIndex,
            getObj: getFormObject,
            setObj: setFormObject,
            getArrValue: getFormObjectArrayValue,
            setArrValue: setFormObjectArray,
          });
        }, 500);
      } else {
        field.onChange && field.onChange({
          value: e,
          tKey,
          tIndex,
          getObj: getFormObject,
          setObj: setFormObject,
          getArrValue: getFormObjectArrayValue,
          setArrValue: setFormObjectArray,
        });
      }
    }}
  /> : <Text value>
    {value ? dayjs(value).format('DD MMM YYYY') : ''}
  </Text>
})

export const TimeField = React.memo(({ field, useForm, viewOnly = false, tKey = null, tIndex = null }) => {
  const timer = useRef(null);

  const value = useForm((state) => state.formObject?.[tKey]?.[tIndex]?.[field.key] ?? state.formObject?.[field.key]);;
  const setFormObject = useForm((state) => state.setFormObject);
  const setFormObjectArray = useForm((state) => state.setFormObjectArray);

  const getFormObjectArrayValue = useForm((state) => state.getFormObjectArrayValue);
  const getFormObject = useForm((state) => state.getFormObject);

  return !viewOnly ? <CustomTimePicker
    value={value}
    onBlur={field.onBlur}
    onChange={(e) => {
      if (tKey) {
        setFormObjectArray(tKey, tIndex, field.key, e);
      } else {
        setFormObject({ [field.key]: e })
      }

      if (field.debounce) {
        clearTimeout(timer.current);
        timer.current = setTimeout(() => {
          field.onChange && field.onChange({
            value: e,
            tKey,
            tIndex,
            getObj: getFormObject,
            setObj: setFormObject,
            getArrValue: getFormObjectArrayValue,
            setArrValue: setFormObjectArray,
          });
        }, 500);
      } else {
        field.onChange && field.onChange({
          value: e,
          tKey,
          tIndex,
          getObj: getFormObject,
          setObj: setFormObject,
          getArrValue: getFormObjectArrayValue,
          setArrValue: setFormObjectArray,
        });
      }
    }}
  /> : <Text value>
    {value ? dayjs(value).format('hh:mm A') : ''}
  </Text>
})

export const DateTimeField = React.memo(({ field, useForm, viewOnly = false, tKey = null, tIndex = null }) => {
  const timer = useRef(null);
  const value = useForm((state) => state.formObject?.[tKey]?.[tIndex]?.[field.key] ?? state.formObject?.[field.key]);

  const setFormObject = useForm((state) => state.setFormObject);
  const setFormObjectArray = useForm((state) => state.setFormObjectArray);
  const getFormObjectArrayValue = useForm((state) => state.getFormObjectArrayValue);
  const getFormObject = useForm((state) => state.getFormObject);

  return !viewOnly ? <CustomDateTimePicker
    {...field}
    value={value}
    onBlur={field.onBlur}
    onChange={(e) => {
      const value = e?.value || e;
      if (tKey) {
        setFormObjectArray(tKey, tIndex, field.key, value);
      } else {
        setFormObject({ [field.key]: value })
      }

      const changeFn = field.onChange && field.onChange({
        value: value,
        tKey,
        tIndex,
        getObj: getFormObject,
        setObj: setFormObject,
        getArrValue: getFormObjectArrayValue,
        setArrValue: setFormObjectArray,
        serial_number: e?.serial_number,
      });

      if (field.debounce) {
        clearTimeout(timer.current);
        timer.current = setTimeout(() => {
          changeFn && changeFn();
        }, 500);
      } else {
        changeFn && changeFn();
      }
    }}
    options={field.options || []}
    autoFocus={field.autoFocus}
  /> : <Text>
    {value ? dayjs(value).format('DD MMM YYYY hh:mm A') : ''}
  </Text>
})

export const UploadField = React.memo(({ field, useForm, viewOnly = false, tKey = null, tIndex = null }) => {
  const timer = useRef(null);

  const value = useForm((state) => state.formObject?.[tKey]?.[tIndex]?.[field.key] ?? state.formObject?.[field.key]);;
  const setFormObject = useForm((state) => state.setFormObject);
  const setFormObjectArray = useForm((state) => state.setFormObjectArray);

  const getFormObjectArrayValue = useForm((state) => state.getFormObjectArrayValue);
  const getFormObject = useForm((state) => state.getFormObject);

  const [visible, setIsVisible] = React.useState(false);
  const common_image_extension = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp', 'svg'];

  return !viewOnly ? <CustomUpload
    {...field}
    value={value}
    onBlur={field.onBlur}
    onChange={(e) => {
      if (tKey) {
        setFormObjectArray(tKey, tIndex, field.key, e);
      } else {
        setFormObject({ [field.key]: e })
      }

      if (field.debounce) {
        clearTimeout(timer.current);
        timer.current = setTimeout(() => {
          field.onChange && field.onChange({
            value: e,
            tKey,
            tIndex,
            getObj: getFormObject,
            setObj: setFormObject,
            getArrValue: getFormObjectArrayValue,
            setArrValue: setFormObjectArray,
          });
        }, 500);
      } else {
        field.onChange && field.onChange({
          value: e,
          tKey,
          tIndex,
          getObj: getFormObject,
          setObj: setFormObject,
          getArrValue: getFormObjectArrayValue,
          setArrValue: setFormObjectArray,
        });
      }
    }}
  /> : field.customView ? <field.customView value={value} /> : field.multiple ?
    <View row spread style={{
      flexWrap: 'wrap',
    }}>
      {Platform.OS != 'web' && value && value.map((image, index) => <TouchableOpacity
        onPress={() => setIsVisible(true)}
        marginR-5 marginB-5 key={index}>
        <Image source={{ uri: image.url }} style={{
          width: 100,
          height: 100,
          resizeMode: 'cover',
        }} />
      </TouchableOpacity>)}
      {Platform.OS == 'web' && value && value.map((image, index) => <View marginR-8>
        <WebImageView width={100} src={image.url} style={{
          width: 100,
          height: 100,
          resizeMode: 'cover',
        }} />
      </View>)}
      <ImageView
        images={[...(value || []).map((image) => {
          return {
            uri: image.url
          }
        }
        )]}
        imageIndex={0}
        visible={visible}
        onRequestClose={() => setIsVisible(false)}
      />
    </View>
    : <View row spread style={{
      flexWrap: 'wrap',
    }}>
      {field.multiple ?

        <>{Platform.OS != 'web' && value && [value].map((image, index) => <TouchableOpacity
          onPress={() => setIsVisible(true)}
          marginR-5 marginB-5 key={index}>
          <Image source={{ uri: image.url }} style={{
            width: 100,
            height: 100,
            resizeMode: 'cover',
          }} />
        </TouchableOpacity>)}
          {Platform.OS == 'web' && value && [value].map((image, index) => <WebImageView width={100} src={image.url} style={{
            width: 100,
            height: 100,
            resizeMode: 'cover',
          }} />)}
          <ImageView
            images={[...(value || []).map((image) => {
              return {
                uri: image.url
              }
            })]}
            imageIndex={0}
            visible={visible}
            onRequestClose={() => setIsVisible(false)}
          /></>
        :

        <>
        
        <View centerV>
          {Platform.OS != 'web' && <TouchableOpacity
            onPress={() => {
              if (common_image_extension.includes(value.split('.').pop())) {
                setInitialIndex(0);
                setIsVisible(true);
              } else {
                Linking.openURL(value);
              }
            }}
            marginR-5 marginB-5>

            {common_image_extension.includes(value.split('.').pop()) && <Image source={{ uri: value }} style={{
              borderRadius: 4,
              width: 90,
              height: 85,
            }} />}

            {!common_image_extension.includes(value.split('.').pop()) && <View center style={{
              borderRadius: 4,
              width: 90,
              height: 85,
              backgroundColor: '#f0f0f0',
            }}>
              <Icon name='file-text-outline' width={40} height={40} fill={'#53535f'} />
            </View>}

          </TouchableOpacity>}

          {Platform.OS == 'web' && common_image_extension.includes(value.split('.').pop()) && <WebImageView width={90} src={value} style={{
            width: 90,
            height: 85,
            resizeMode: 'cover',
          }} />}

          {Platform.OS == 'web' && !common_image_extension.includes(value.split('.').pop()) && <TouchableOpacity
            onPress={() => {
              Linking.openURL(value);
            }}
          >
            <View center style={{
              borderRadius: 4,
              width: 90,
              height: 85,
              backgroundColor: '#f0f0f0',
            }}>
              <Icon name='file-text-outline' width={40} height={40} fill={'#53535f'} />
            </View>
          </TouchableOpacity>}

          <ImageView
            images={[{ uri: value }]}
            imageIndex={0}
            visible={visible}
            onRequestClose={() => setIsVisible(false)}
          />
        </View>
        </>}
    </View>
})

export const ButtonField = React.memo(({ field, useForm, viewOnly = false, tKey = null, tIndex = null }) => {
  const setFormObject = useForm((state) => state.setFormObject);
  const getFormObject = useForm((state) => state.getFormObject);

  const customUpdateField = (key, value, newForm = null, keyValueObject = null) => {
    if (keyValueObject) {
      setFormObject(keyValueObject);
    } else {
      setFormObject({ [key]: value });
    }
  }

  return <View left={Platform.OS == 'web'} style={Platform.OS != 'web' ? {
    width: '100%',
  } : {}}>
    <CustomButton
      onClick={() => {
        field.onClick && field.onClick({ form: getFormObject(), connectedObject: getFormObject(), updateField: customUpdateField });
      }}
      text={field.text}
      buttonProps={field.buttonProps}
    />
  </View>
})

export const checkVisibility = (field, connectedObject, updateField) => {
  if (typeof field.visible == 'function') {
    const visible = field.visible(connectedObject);
    return visible;
  } else {
    return field.visible;
  }
}

export const Heading = (field, connectedObject, updateField) => {

  return <View style={{ marginTop: 0, width: '100%' }}>
    <View spread row>
      <Text text70 style={{ fontWeight: 'bold' }}>{field.heading}</Text>
      {field.component && <field.component connectedObject={connectedObject} updateField={updateField} />}
    </View>

    {Platform.OS == 'ios' ? <View style={{ overflow: 'hidden' }}>
      <View
        style={{
          borderStyle: 'dashed',
          borderWidth: 1,
          borderColor: '#d9d9d9',
          margin: -2,
          marginTop: 10,
        }}>
        <View style={{ height: 20, width: 200 }} />
      </View>
    </View> : <View style={{
      borderBottomColor: '#d9d9d9',
      borderBottomWidth: 1,
      width: '100%',
      marginBottom: 0,
      marginTop: 10,
      borderStyle: 'dashed',
    }} />}
  </View>
}

export const Label = (field, viewOnly = false) => {
  const absStyle = { zIndex: 2, paddingHorizontal: 0, flexGrow: 1, flex: 1, marginBottom: 2 };

  return <>{field.label && (<>
    {Platform.OS == 'web' && <View style={{ height: 2 }}></View>}

    <View style={{
      zIndex: 2,
      marginBottom: Platform.OS == 'web' ? 8 : 0,
    }} centerV={Platform.OS != 'web'} >
      <View centerV style={Platform.OS == 'web' ? absStyle : {
        // height: '100%',
      }}>
        {Platform.OS == 'web' && <Text style={{
          flex: 1,
          flexWrap: 'wrap',
          fontSize: 13,
          letterSpacing: 0.4,
          fontWeight: "500",
          color: viewOnly ? "#72849A" : '#01050F',
          fontFamily: 'SourceSansProSemiBold'
        }}>
          {field.label}</Text>}
        {Platform.OS != 'web' && <Text style={{
          flexWrap: 'wrap',
          fontSize: 14,
          lineHeight: 20,
          letterSpacing: 0.4,
          fontWeight: "500",
          color: viewOnly ? "#72849A" : '#01050F',
          fontFamily: 'SourceSansProSemiBold',
        }}>
          {field.label}</Text>}
      </View>
    </View>
  </>
  )}</>;
}

const DynamicForm = observer((props) => {
  const { formUID, connectedObject, moduleName, setConnectedObject, viewOnly, getOpenForms, saveFn, cancelFn, ExtraSaveComponent, deleteFn, dataTabs, preferences = false, show_save_and_new } = props;

  const [currentTab, setCurrentTab] = useState("");
  const [tabItems, setTabItems] = useState([]);
  const [loading, setLoading] = useState(false);

  let moduleObjRef = props.connectedObjectRef || useRef({});

  const useFormObject = create((set, get) => ({
    formObject: { ...connectedObject, ...moduleObjRef.current },
    setFormObject: (keyValue) => {
      set((state) => {
        const newVal = { ...state.formObject, ...keyValue };
        moduleObjRef.current = newVal;
        return { formObject: newVal }
      })
    },
    setFormObjectArray: (array_key, array_index, field_key, value) => set((state) => {
      if (typeof field_key === 'object') {
        const newFormObject = { ...state.formObject };
        newFormObject[array_key][array_index] = { ...newFormObject[array_key][array_index], ...field_key };
        moduleObjRef.current = newFormObject;
        return { formObject: newFormObject };
      } else {
        const newFormObject = { ...state.formObject };
        const newArray = [...newFormObject[array_key]];
        newArray[array_index][field_key] = value
        newFormObject[array_key] = newArray;
        moduleObjRef.current = newFormObject;
        return { formObject: newFormObject };
      }
    }),
    getFormObjectArrayValue: (array_key, array_index, field_key) => get().formObject[array_key][array_index][field_key],
    getFormObject: () => get().formObject,
  }))

  const getFormObject = useFormObject((state) => state.getFormObject);
  const setFormObject = useFormObject((state) => state.setFormObject);
  const value = useFormObject((state) => state.formObject['updateVisiblity']);

  const updateField = useCallback((keyValueObject) => {
    if (keyValueObject) {
      setFormObject(keyValueObject);
    }
  }, []);

  const saveFnInternal = async (stay_on_page = false, ignore_message = false, save_and_new=false) => {
    const objectMain = getFormObject();
    setLoading(true);
    const response = await saveFn(objectMain, stay_on_page, ignore_message, save_and_new)
    setLoading(false);

    return response;
  }

  const deleteFnInternal = async () => {
    const objectMain = getFormObject();
    setLoading(true);
    const response = await deleteFn(objectMain)
    setLoading(false);
  }

  useEffect(() => {
    setFormObject({ ...connectedObject, ...moduleObjRef.current });

    if (!getOpenForms[formUID + "Form"]) {
      return;
    }

    const tabs = getOpenForms[formUID + "Form"].reduce((acc, field) => {
      if (field.tab && checkVisibility(field, connectedObject, updateField)) {
        acc[field.tab] = acc[field.tab] || [];
        acc[field.tab].push(field);
      }
      return acc;
    }, {});

    let newTabItems = Object.keys(tabs).map((tabName, index) => ({
      label: tabName,
      key: "" + index,
      value: "" + index,
    }));

    if (newTabItems.length === 0) {
      newTabItems.push({ label: null, key: null, value: null });
      setCurrentTab(null);
    }

    if (viewOnly) {
      newTabItems = [...newTabItems, ...(dataTabs || [])];
    } else {
      newTabItems = [...newTabItems];
    }

    setTabItems(newTabItems);

    if (currentTab === "" && newTabItems.length >= 1) {
      setCurrentTab(newTabItems[0].label);
    }

    const oldTab = currentTab || newTabItems[0].label;
    setCurrentTab(null);
    setTimeout(() => {
      setCurrentTab(oldTab);
    }, 0);

    let currentTabFound = false;

    for (const newTabItem of newTabItems) {
      if (newTabItem.label == currentTab) {
        currentTabFound = true;
        break;
      }
    }

    if (!currentTabFound) {
      setCurrentTab(null);
      setTimeout(() => {
        setCurrentTab(newTabItems[0].label);
      }, 0);
    }

    setFormObject({
      updateVisiblity: Math.random()
    })

  }, [formUID, connectedObject]);

  const normalHeight = Dimensions.get('window').height - (Platform.OS == 'web' ? (viewOnly ? 50 : (175)) : Platform.OS == 'android' ? 260 : 320);
  const prefHeight = Dimensions.get('window').height - (Platform.OS == 'web' ? 110 : Platform.OS == 'android' ? 130 : 210);

  return (<>
    {getOpenForms[formUID + "Form"] ?
      <>
        <View style={{
          position: 'relative',
        }}>
          {loading && <View style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(255,255,255,0.5)',
            zIndex: 2,
            justifyContent: 'center',
            alignItems: 'center',
          }}>
            <ActivityIndicator size="large" color="#0000ff" />
          </View>}

          {tabItems?.length >= 1 ? <View marginV-0>
            <View style={props.hideHeader ? {
              borderBottomColor: '#535a69',
              borderBottomWidth: 1,
              marginBottom: 0,
              marginTop: 10,
              // marginHorizontal: 10,
              borderStyle: 'none'
            } : {
              borderBottomColor: '#535a69',
              borderBottomWidth: 1,
              marginBottom: 0,
              marginTop: 0,
              borderStyle: Platform.OS == 'web' ? 'solid' : 'solid',
              // marginHorizontal: 10
            }} />
            <View>
              <View row marginB-0 marginT-0 backgroundColor="#0a152fd6" style={{
                position: 'relative',
                // borderTopRightRadius: 5,
                // borderTopLeftRadius: 5,
              }}>
                <ScrollView
                  horizontal
                  showsVerticalScrollIndicator={false}
                  showsHorizontalScrollIndicator={false}
                  scrollEventThrottle={16}>
                  {tabItems.map((tab, tab_index) => (
                    <TouchableOpacity
                      key={tab_index.toString()}
                      style={[
                        currentTab == tab?.label ?
                          { padding: 10, borderBottomColor: 'rgb(17, 104, 191)', borderBottomWidth: 3 } :
                          { padding: 10 }
                      ]}
                      onPress={() => {
                        const obj = getFormObject();
                        setCurrentTab(tab.label);
                      }}
                    >
                      <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                        <View style={{ marginLeft: 5 }}>
                          <Text style={
                            [currentTab == tab?.label ?
                              { color: '#58b5ff', fontFamily: 'SourceSansProSemiBold', letterSpacing: 0.4 } :
                              { color: '#ffffffab', fontFamily: 'SourceSansProSemiBold', letterSpacing: 0.4 }
                            ]}>
                            {tab.label || (preferences ? "Preferences" : "General")}
                          </Text>
                        </View>
                      </View>
                    </TouchableOpacity>
                  ))}
                </ScrollView>
              </View>
            </View>

            <View style={{
              borderBottomColor: '#e0e3f2bf',
              borderBottomWidth: 1,
              marginBottom: 0,
              marginTop: 0,
              borderStyle: 'solid',
              marginHorizontal: 10
            }} />
          </View> : null}

          {[...tabItems.map((el) => el.label)].includes(currentTab) && <KeyboardAwareScrollView
            extraScrollHeight={100} enableOnAndroid={true} keyboardShouldPersistTaps='handled'
            extraHeight={100}
            style={{
              height: preferences ? prefHeight : (normalHeight + (viewOnly ? 60 : 25)),
              padding: 20,
              paddingBottom: 100,
              backgroundColor: 'rgb(255, 255, 255)'
            }}>
            {[...(tabItems || [])].map((tab, index) => (<View key={index.toString()}>
              {currentTab === tab.label && (![...(dataTabs || []).map((el) => el.label)].includes(currentTab)) && <View>
                <Row style={{
                  marginBottom: 50,
                  width: preferences ? '100%' : Platform.OS == 'web' ? Math.round((Dimensions.get('window').width - 30) / 4) * 4 : '100%',
                }}>
                  {getOpenForms[formUID + "Form"].map((field, index2) => {
                    if (currentTab != "") {
                      if (field.tab != currentTab) {
                        return null;
                      }
                    }

                    const fullWidthComponents = ["button", "divider", "custom", "table", "upload", "tags_text_area", "textarea", "editor"]
                    // const isRow = Platform.OS != 'web' && ((fullWidthComponents.indexOf(field.type) == -1) || (field.type == "select" && field.multiple));
                    const isRow = false;

                    return (
                      <Col key={index2.toString()} width={Platform.OS != 'web' ? '100%' : field.width || '100%'} visible={checkVisibility(field, getFormObject(), value)}>


                        <View centerV row={isRow} style={{ width: '100%' }}>
                          <View centerV style={isRow ? field.type == "checkbox" ? { flexBasis: '90%' } : { flexBasis: '40%' } : {}}>
                            {isRow && <View centerV >
                              {Label(field, viewOnly)}
                            </View>}
                            {!isRow && <View centerV marginT-10>
                              {Label(field, viewOnly)}
                            </View>}
                          </View>

                          {/* {(Platform.OS != 'web' && ["button", "divider", "custom"].indexOf(field.type) == -1) && <View style={{ width: '100%', height: 1, backgroundColor: '#f0f0f0', marginVertical: 0 }}></View>} */}

                          <View row style={[
                            isRow ? field.type == "checkbox" ? { flexBasis: '10%' } : { flexBasis: '60%' } : {},
                            (Platform.OS != 'web' && ["button", "divider", "custom", "checkbox", "switch", "upload"].indexOf(field.type) == -1) ? {
                              borderWidth: viewOnly ? 0 : 1,
                              borderStyle: 'solid',
                              borderColor: '#f0f0f0',
                              paddingHorizontal: viewOnly ? 0 : 10,
                              borderRadius: 8,
                              marginTop: 10,
                              width: '100%',
                              flexBasis: '100%',
                            } : {}
                          ]}>
                            {field.type == "text" && <TextField field={field} useForm={useFormObject} viewOnly={viewOnly} />}

                            {field.type === "textarea" && <TextAreaField field={field} useForm={useFormObject} viewOnly={viewOnly} />}

                            {field.type === "editor" && <EditorField field={field} useForm={useFormObject} viewOnly={viewOnly} />}

                            {field.type === "number" && <NumberField field={field} useForm={useFormObject} viewOnly={viewOnly} />}

                            {field.type === "duration" && <DurationField field={field} useForm={useFormObject} viewOnly={viewOnly} />}

                            {field.type === "date" && <DateField field={field} useForm={useFormObject} viewOnly={viewOnly} />}

                            {field.type === "time" && <TimeField field={field} useForm={useFormObject} viewOnly={viewOnly} />}

                            {field.type === "datetime" && <DateTimeField field={field} useForm={useFormObject} viewOnly={viewOnly} />}

                            {field.type === "select" && <SelectField field={field} useForm={useFormObject} viewOnly={viewOnly} />}

                            {field.type === "autocomplete" && <AutoCompleteField field={field} useForm={useFormObject} viewOnly={viewOnly} />}

                            {field.type === "checkbox" && <CheckboxField field={field} useForm={useFormObject} viewOnly={viewOnly} />}

                            {field.type === "switch" && <SwitchField field={field} useForm={useFormObject} viewOnly={viewOnly} />}

                            {field.type === "table" && <TableField field={field} useForm={useFormObject} viewOnly={viewOnly} />}

                            {field.type === "button" && <ButtonField field={field} useForm={useFormObject} viewOnly={viewOnly} />}

                            {field.type === "upload" && <UploadField field={field} useForm={useFormObject} viewOnly={viewOnly} />}

                            {field.type === "tags_text_area" && <SerialNumberTextArea field={field} useForm={useFormObject} viewOnly={viewOnly} />}

                            {field.type === "divider" && Heading(field, getFormObject(), updateField)}

                            {field.type === "custom" && <field.component field={field} useForm={useFormObject} setCurrentTab={setCurrentTab} />}

                            {field.suffix ? <>
                              <Button center avoidMinWidth style={{
                                backgroundColor: 'whitesmoke',
                                marginLeft: 5,
                                borderWidth: 1,
                                borderColor: '#e2e2e2',
                                height: 38,
                                borderRadius: 4,
                                paddingHorizontal: 8,
                              }} onPress={() => {
                                if (field?.suffix?.onPress) {
                                  field?.suffix?.onPress({ field, form: getOpenForms[formUID + "Form"], updateField });
                                }
                              }}>
                                <Text>{field.suffix.icon}</Text>
                              </Button>
                            </> : null}

                          </View>

                          {field.error &&
                            <>
                              <Icon name="alert-triangle-outline" height={15} width={15} color={'red'} />
                              <Text style={{ color: 'red' }}>
                                {field.error}</Text>
                            </>}
                        </View>

                        {/* {(Platform.OS != 'web' && ["button", "divider", "custom"].indexOf(field.type) == -1) && <View style={{ width: '100%', height: 1, backgroundColor: '#f0f0f0', marginVertical: 0 }}></View>} */}
                      </Col>
                    )
                  })}
                </Row>
              </View>}
              {currentTab === tab.label && ([...(dataTabs || []).map((el) => el.label)].includes(currentTab)) && <tab.component moduleName={moduleName} obj={connectedObject} />}
            </View>))}
          </KeyboardAwareScrollView>}

          {/* {dataTabs && [...dataTabs.map((el) => el.label)].includes(currentTab) && dataTabs.map((tab, index) => (<View key={index.toString()}>
            {currentTab === tab.label && <View flex padding-20>
              
            </View>}
          </View>))} */}

          {!viewOnly ? <View padding-10 row backgroundColor="white" style={[
            {
              // elevation: 2,
              // shadowColor: '#000',
              // shadowOffset: { width: 0, height: -1 },
              // shadowOpacity: 0.1,
              // shadowRadius: 5,
              width: '100%',
              borderTopColor: 'rgb(207, 222, 255)',
              borderTopWidth: 1.5,
              borderStyle: 'solid',
            }
          ]}>
            <PrimaryButton onPress={saveFnInternal} label="Save" disabled={loading} />
            {show_save_and_new && <View marginL-10>
              <SecondaryButton onPress={() => {saveFnInternal(false, false, true)}} label="Save and New" disabled={loading} />
            </View>}

            {ExtraSaveComponent ? <ExtraSaveComponent saveFnInternal={saveFnInternal} getFormObject={getFormObject} setFormObject={setFormObject} moduleName={moduleName} /> : null}

            {cancelFn ? <SecondaryButton style={{ marginLeft: 10 }} label="Cancel" onPress={cancelFn}></SecondaryButton> : null}

            <View style={{
              marginLeft: Platform.OS == 'web' ? 'auto' : 10,
            }}>
              {deleteFn ? <DangerButton label="Delete" onPress={deleteFnInternal} ></DangerButton> : null}
            </View>
          </View> : null}
        </View>
      </>
      : <></>}
    {/* {<Button onPress={() => {
        closeAllForms();
      }} title="Clear All Forms" />} */}

    {/* {<Text>{renderCount.current}</Text>} */}
  </>);
});

export default DynamicForm;
