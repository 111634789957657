import React, { useEffect } from "react";
import { View, Card, Text } from "react-native-ui-lib";
import ListingScreen from "../../mycomponents/CRUD/ListingScreen";


export const awardRegistrationfields = (response, view = false, moduleName = null, OpenModalCustom, value, isEdit, globalData, setGlobalData) => {
    const formFields = [
        {
            label: 'Date',
            key: 'date',
            type: 'date',
            placeholder: 'Date',
            visible: true,
            width: '25%',
            tab: 'General',
        },
        {
            label: 'Award Type',
            key: 'award_type',
            type: 'text',
            placeholder: 'Award Type',
            visible: true,
            width: '25%',
            tab: 'General',
        },
        {
            label: 'Award Category',
            key: 'award_category',
            type: 'text',
            placeholder: 'Award Category',
            visible: true,
            width: '25%',
            tab: 'General',
        },
        {
            type: 'divider',
            heading: 'Personal Information',
            visible: true,
            span: 24,
            tab: 'General'
        },
        {
            label: 'Full Name',
            key: 'full_name',
            type: 'text',
            placeholder: 'Full Name',
            visible: true,
            width: '25%',
            tab: 'General',
        },
        {
            label: 'Date of Birth',
            key: 'dob',
            type: 'date',
            placeholder: 'Date of Birth',
            visible: true,
            width: '25%',
            tab: 'General',
        },
        {
            label: 'Email',
            key: 'email',
            type: 'text',
            placeholder: 'Email',
            visible: true,
            width: '25%',
            tab: 'General',
        },
        {
            label: 'Phone',
            key: 'phone',
            type: 'text',
            placeholder: 'Phone',
            visible: true,
            width: '25%',
            tab: 'General',
        },
        {
            label: 'Address',
            key: 'address',
            type: 'text',
            placeholder: 'Address',
            visible: true,
            width: '25%',
            tab: 'General',
        },
        {
            type: 'divider',
            heading: 'Professional Information',
            visible: true,
            span: 24,
            tab: 'General'
        },
        {
            label: 'Institution',
            key: 'institution',
            type: 'text',
            placeholder: 'Institution',
            visible: true,
            width: '25%',
            tab: 'General',
        },
        {
            label: 'Position',
            key: 'position',
            type: 'text',
            placeholder: 'Position',
            visible: true,
            width: '25%',
            tab: 'General',
        },
        {
            label: 'Qualification',
            key: 'qualification',
            type: 'text',
            placeholder: 'Qualification',
            visible: true,
            width: '25%',
            tab: 'General',
        },
        {
            label: 'Experience',
            key: 'experience',
            type: 'text',
            placeholder: 'Experience',
            visible: true,
            width: '25%',
            tab: 'General',
        },
        {
            label: 'Achievements',
            key: 'achievements',
            type: 'text',
            placeholder: 'Achievements',
            visible: true,
            width: '25%',
            tab: 'General',
        },
        {
            type: 'divider',
            heading: 'Nominator Information',
            visible: true,
            span: 24,
            tab: 'General'
        },
        {
            label: 'Purpose',
            key: 'purpose',
            type: 'text',
            placeholder: 'Purpose',
            visible: true,
            width: '25%',
            tab: 'General',
        },
        {
            label: 'Document Urls',
            key: 'document_urls',
            type: 'text',
            placeholder: 'Document Urls',
            visible: true,
            width: '25%',
            tab: 'General',
        },
        {
            label: 'Membership No',
            key: 'membership_no',
            type: 'text',
            placeholder: 'Membership No',
            visible: true,
            width: '25%',
            tab: 'General',
        },
        {
            label: 'Nominator Name',
            key: 'nominator_name',
            type: 'text',
            placeholder: 'Nominator Name',
            visible: true,
            width: '25%',
            tab: 'General',
        },
        {
            label: 'Relationship',
            key: 'relationship',
            type: 'text',
            placeholder: 'Relationship',
            visible: true,
            width: '25%',
            tab: 'General',
        },
        {
            label: 'Nominator Contact',
            key: 'nominator_contact',
            type: 'text',
            placeholder: 'Nominator Contact',
            visible: true,
            width: '25%',
            tab: 'General',
        },

    ]

    const CustomFields = response.CustomFields;

    if (CustomFields && CustomFields.length > 0) {
        CustomFields.forEach((field) => {
            formFields.push({
                label: field.name,
                key: 'cf.' + field.key,
                type: field.type,
                placeholder: field.placeholder,
                visible: true,
                width: '25%'
            });
        });
    }

    return formFields;
}

export const awardRegistrationMobileCard = (item, index, getFieldValue) => {
    return <Card flex margin-20 padding-15 className="hh" style={{ height: 100, marginVertical: 10, flexDirection: 'row', flexWrap: 'wrap' }}>
        <View>
            <Text>TEST</Text>
            <Text style={{ marginBottom: 5 }}>{getFieldValue(item, 'name') ? "" + getFieldValue(item, 'name') : ""}</Text>
        </View>
    </Card>;
}

export const awardRegistrationMain = {
    fields: awardRegistrationfields,
    mobileCard: null
}