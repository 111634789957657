import React, { useCallback, useEffect, useState } from "react";
import { View, Card, Text, Button, Icon } from "react-native-ui-lib";
import { Col, CustomModal, CustomNumberInput, CustomSelect, CustomTextField, DangerButton, Label, PrimaryButton, Row, SelectField } from "../../mycomponents/DynamicForm";
import { Platform } from "react-native";
import { addButton } from "../hrms/shiftModel";
import { api } from "../../services/api";
import dayjs from 'dayjs';
import ReportScreenSingle from "../../mycomponents/CRUD/ReportScreenSingle";
import { App } from 'antd';
import moment from "moment";
import Svg, { Path } from "react-native-svg";

export const contactfields = (response, view = false, moduleName = null, OpenModalCustom, value, isEdit, globalData, setGlobalData) => {

    const AddressesComponent = ({ field, useForm }) => {
        const addresses = useForm((state) => state.formObject["addresses"]) || [];

        const setFormObject = useForm((state) => state.setFormObject);
        const setFormObjectArray = useForm((state) => state.setFormObjectArray);
        const getFormObject = useForm((state) => state.getFormObject);

        const [states, setStates] = useState({});

        useEffect(() => {
            for (let i = 0; i < addresses.length; i++) {
                const address = addresses[i];

                if (address.country && !states[i]) {
                    (async () => {
                        try {
                            const response = await api.customRoute('Contacts/action', {
                                action: 'getStates',
                                country_id: address.country
                            });

                            const newStates = response;
                            setStates({ ...states, [i]: newStates });
                        } catch (e) {

                        }
                    })();
                }
            }
        }, [addresses]);

        return <>
            <View flex={Platform.OS == 'web'}>
                {addresses && addresses.map((obj, index) => {
                    return <Row key={index} style={{ marginTop: 10, alignItems: 'center' }} >
                        <Col width="100%">
                            <View style={{
                                width: '100%',
                                marginBottom: Platform.OS == 'web' ? 0 : 10,
                            }}>
                                <Text tabHeading>Address {index + 1}</Text>
                            </View>
                        </Col>

                        <Col width={Platform.OS == 'web' ? '25%' : '100%'}>
                            <View style={{ width: '100%' }} row={Platform.OS != 'web'}>
                                {Label({ label: 'Country' })}
                                <CustomSelect independent value={obj.country} options={response.Countries || []} onChange={async (value) => {
                                    setFormObjectArray('addresses', index, 'country', value);

                                    try {
                                        const response = await api.customRoute('Contacts/action', {
                                            action: 'getStates',
                                            country_id: value
                                        });

                                        const newStates = response;
                                        setStates({ ...states, [index]: newStates });
                                    } catch (e) {

                                    }
                                }} />
                            </View>
                        </Col>

                        <Col width={Platform.OS == 'web' ? '25%' : '100%'}>
                            <View style={{ width: '100%' }}>
                                {Label({ label: 'State' })}
                                <CustomSelect independent value={obj.state} onChange={(value) => {
                                    setFormObjectArray('addresses', index, 'state', value);
                                }} options={states[index] || []} />
                            </View>
                        </Col>

                        <Col width={Platform.OS == 'web' ? '25%' : '100%'}>
                            <View style={{ width: '100%' }}>
                                {Label({ label: 'Pincode' })}
                                <CustomNumberInput independent value={addresses[index].pincode} onChange={(value) => {
                                    setFormObjectArray('addresses', index, 'pincode', value);
                                }} />
                            </View>
                        </Col>

                        <Col width={Platform.OS == 'web' ? '25%' : '100%'}>
                            <View style={{ width: '100%' }}>
                                {Label({ label: 'City' })}
                                <CustomTextField independent value={obj.city} onChange={(value) => {
                                    setFormObjectArray('addresses', index, 'city', value);
                                }} />
                            </View>
                        </Col>

                        <Col width={Platform.OS == 'web' ? '25%' : '100%'}>
                            <View style={{ width: '100%' }}>
                                {Label({ label: 'Address Line 1' })}
                                <CustomTextField independent value={obj.address_line_1} onChange={(value) => {
                                    setFormObjectArray('addresses', index, 'address_line_1', value);
                                }} />
                            </View>
                        </Col>

                        <Col width={Platform.OS == 'web' ? '25%' : '100%'}>
                            <View style={{ width: '100%' }}>
                                {Label({ label: 'Address Line 2' })}
                                <CustomTextField independent value={obj.address_line_2} onChange={(value) => {
                                    setFormObjectArray('addresses', index, 'address_line_2', value);
                                }} />
                            </View>
                        </Col>

                        <Col width={Platform.OS == 'web' ? '25%' : '100%'}>
                            <View style={{ width: '100%' }}>
                                {Label({ label: 'Primary Billing Address' })}
                                <CustomSelect independent value={obj.primary_billing} onChange={(value) => {
                                    const newAddresses = [...addresses];
                                    let otherDefault = false;
                                    newAddresses.forEach((data, i) => {
                                        if (i != index && value == "Yes") {
                                            data.primary_billing = "No";
                                        }
                                        if (i != index && value == "No") {
                                            if (!otherDefault) {
                                                data.primary_billing = "Yes";
                                                otherDefault = true;
                                            }
                                        }
                                    });
                                    setFormObjectArray('addresses', index, 'primary_billing', value);
                                }
                                } options={[
                                    { label: 'Yes', value: "Yes" },
                                    { label: 'No', value: "No" },
                                ]} />
                            </View>
                        </Col>

                        <Col width={Platform.OS == 'web' ? '25%' : '100%'}>
                            <View style={{ width: '100%' }}>
                                {Label({ label: 'Primary Shipping Address' })}
                                <CustomSelect independent value={obj.primary_shipping} onChange={(value) => {
                                    const newAddresses = [...addresses];
                                    let otherDefault = false;
                                    newAddresses.forEach((data, i) => {
                                        if (i != index && value == "Yes") {
                                            data.primary_shipping = "No";
                                        }
                                        if (i != index && value == "No") {
                                            if (!otherDefault) {
                                                data.primary_shipping = "Yes";
                                                otherDefault = true;
                                            }
                                        }
                                    });
                                    setFormObjectArray('addresses', index, 'primary_shipping', value);
                                }
                                } options={[
                                    { label: 'Yes', value: "Yes" },
                                    { label: 'No', value: "No" },
                                ]} />
                            </View>
                        </Col>

                        <View style={Platform.OS == 'web' ? { marginLeft: 10 } : {
                            marginTop: 10,
                            width: '100%',
                        }}>
                            <DangerButton
                                style={Platform.OS == 'web' ? {

                                } : {
                                    width: '100%',
                                    height: 42
                                }}
                                label="Delete" onPress={() => {
                                    const newAddresses = [...addresses];
                                    newAddresses.splice(index, 1);
                                    setFormObject({
                                        'addresses': newAddresses
                                    });
                                }} />
                        </View>
                    </Row>
                })}
            </View>
        </>
    }

    const LensDetailsComponent = ({ field, useForm }) => {
        const lens_details = useForm((state) => state.formObject["lens_details"]);
        const setFormObject = useForm((state) => state.setFormObject);
        const setFormObjectArray = useForm((state) => state.setFormObjectArray);
        const getFormObject = useForm((state) => state.getFormObject);
        const [states, setStates] = useState({});

        return <>
            <View flex={Platform.OS == 'web'}>
                {lens_details && [...(lens_details?.reverse() || [])].map((obj, index) => {
                    return <Row key={index} style={{ marginTop: 10, alignItems: 'center' }} >
                        <Col width="100%">
                            <View row centerV spread style={{
                                width: '100%'
                            }}>
                                <Text tabHeading>Eye Details - {obj['date'] ? dayjs(obj['date']).format('DD MMM YYYY hh:mm A') : ''}</Text>
                                <DangerButton
                                    style={{
                                        marginTop: 8
                                    }}
                                    label="Delete" onPress={() => {
                                        const newLensDetails = [...lens_details];
                                        newLensDetails.splice(index, 1);
                                        setFormObject({
                                            'lens_details': newLensDetails
                                        });
                                    }} />
                            </View>
                        </Col>

                        <Col width="100%">
                            <View center style={{
                                width: '100%',
                            }}>
                                <Text text90 style={{ fontWeight: 'bold' }}>Right Eye</Text>
                            </View>
                            <View style={{
                                borderBottomColor: '#d9d9d9',
                                borderBottomWidth: 1,
                                width: '100%',
                                marginBottom: 0,
                                marginTop: 10,
                                borderStyle: 'dashed',
                            }} />
                        </Col>



                        <Col width={Platform.OS == 'web' ? '20%' : '20%'}>
                            <View style={{ width: '100%' }}>
                                {Label({ label: '' })}
                                <View marginT-40>
                                    {Label({ label: 'Distant' })}
                                </View>
                            </View>
                        </Col>

                        <Col width={Platform.OS == 'web' ? '20%' : '20%'}>
                            <View style={{ width: '100%' }}>
                                {Label({ label: 'Sph' })}
                                <CustomTextField placeholder={""} independent value={obj.distant_re_sph} onChange={(value) => {
                                    setFormObjectArray('lens_details', index, 'distant_re_sph', value);
                                }} />
                            </View>
                        </Col>

                        <Col width={Platform.OS == 'web' ? '20%' : '20%'}>
                            <View style={{ width: '100%' }}>
                                {Label({ label: 'Cyl' })}
                                <CustomTextField independent value={obj.distant_re_cyl} onChange={(value) => {
                                    setFormObjectArray('lens_details', index, 'distant_re_cyl', value);
                                }} />
                            </View>
                        </Col>

                        <Col width={Platform.OS == 'web' ? '20%' : '20%'}>
                            <View style={{ width: '100%' }}>
                                {Label({ label: 'Axis' })}
                                <CustomTextField independent value={obj.distant_re_axis} onChange={(value) => {
                                    setFormObjectArray('lens_details', index, 'distant_re_axis', value);
                                }} />
                            </View>
                        </Col>

                        <Col width={Platform.OS == 'web' ? '20%' : '20%'}>
                            <View style={{ width: '100%' }}>
                                {Label({ label: 'VA' })}
                                <CustomTextField independent value={obj.distant_re_va} onChange={(value) => {
                                    setFormObjectArray('lens_details', index, 'distant_re_va', value);
                                }} />
                            </View>
                        </Col>

                        <Col width={Platform.OS == 'web' ? '20%' : '20%'}>
                            <View style={{ width: '100%' }}>
                                {Label({ label: '' })}
                                <View marginT-40>
                                    {Label({ label: 'Add' })}
                                </View>
                            </View>
                        </Col>

                        <Col width={Platform.OS == 'web' ? '20%' : '20%'}>
                            <View style={{ width: '100%' }}>
                                {Label({ label: 'Sph' })}
                                <CustomTextField independent value={obj.add_re_sph} onChange={(value) => {
                                    setFormObjectArray('lens_details', index, 'add_re_sph', value);
                                }} />
                            </View>
                        </Col>

                        <Col width={Platform.OS == 'web' ? '20%' : '20%'}>
                            <View style={{ width: '100%' }}>
                                {Label({ label: 'Cyl' })}
                                <CustomTextField independent value={obj.add_re_cyl} onChange={(value) => {
                                    setFormObjectArray('lens_details', index, 'add_re_cyl', value);
                                }} />
                            </View>
                        </Col>

                        <Col width={Platform.OS == 'web' ? '20%' : '20%'}>
                            <View style={{ width: '100%' }}>
                                {Label({ label: 'Axis' })}
                                <CustomTextField independent value={obj.add_re_axis} onChange={(value) => {
                                    setFormObjectArray('lens_details', index, 'add_re_axis', value);
                                }} />
                            </View>
                        </Col>

                        <Col width={Platform.OS == 'web' ? '20%' : '20%'}>
                            <View style={{ width: '100%' }}>
                                {Label({ label: 'VA' })}
                                <CustomTextField independent value={obj.add_re_va} onChange={(value) => {
                                    setFormObjectArray('lens_details', index, 'add_re_va', value);
                                }} />
                            </View>
                        </Col>


                        <Col width="100%">
                            <View center style={{
                                width: '100%',
                            }}>
                                <Text text90 style={{ fontWeight: 'bold' }}>Left Eye</Text>
                            </View>
                            <View style={{
                                borderBottomColor: '#d9d9d9',
                                borderBottomWidth: 1,
                                width: '100%',
                                marginBottom: 0,
                                marginTop: 10,
                                borderStyle: 'dashed',
                            }} />
                        </Col>


                        <Col width={Platform.OS == 'web' ? '20%' : '20%'}>
                            <View style={{ width: '100%' }}>
                                {Label({ label: '' })}
                                <View marginT-40>
                                    {Label({ label: 'Distant' })}
                                </View>
                            </View>
                        </Col>

                        <Col width={Platform.OS == 'web' ? '20%' : '20%'}>
                            <View style={{ width: '100%' }}>
                                {Label({ label: 'Sph' })}
                                <CustomTextField independent value={obj.distant_le_sph} onChange={(value) => {
                                    setFormObjectArray('lens_details', index, 'distant_le_sph', value);
                                }} />
                            </View>
                        </Col>

                        <Col width={Platform.OS == 'web' ? '20%' : '20%'}>
                            <View style={{ width: '100%' }}>
                                {Label({ label: 'Cyl' })}
                                <CustomTextField independent value={obj.distant_le_cyl} onChange={(value) => {
                                    setFormObjectArray('lens_details', index, 'distant_le_cyl', value);
                                }} />
                            </View>
                        </Col>

                        <Col width={Platform.OS == 'web' ? '20%' : '20%'}>
                            <View style={{ width: '100%' }}>
                                {Label({ label: 'Axis' })}
                                <CustomTextField independent value={obj.distant_le_axis} onChange={(value) => {
                                    setFormObjectArray('lens_details', index, 'distant_le_axis', value);
                                }} />
                            </View>
                        </Col>

                        <Col width={Platform.OS == 'web' ? '20%' : '20%'}>
                            <View style={{ width: '100%' }}>
                                {Label({ label: 'VA' })}
                                <CustomTextField independent value={obj.distant_le_va} onChange={(value) => {
                                    setFormObjectArray('lens_details', index, 'distant_le_va', value);
                                }} />
                            </View>
                        </Col>

                        <Col width={Platform.OS == 'web' ? '20%' : '20%'}>
                            <View style={{ width: '100%' }}>
                                {Label({ label: '' })}
                                <View marginT-40>
                                    {Label({ label: 'Add' })}
                                </View>
                            </View>
                        </Col>

                        <Col width={Platform.OS == 'web' ? '20%' : '20%'}>
                            <View style={{ width: '100%' }}>
                                {Label({ label: 'Sph' })}
                                <CustomTextField independent value={obj.add_le_sph} onChange={(value) => {
                                    setFormObjectArray('lens_details', index, 'add_le_sph', value);
                                }} />
                            </View>
                        </Col>

                        <Col width={Platform.OS == 'web' ? '20%' : '20%'}>
                            <View style={{ width: '100%' }}>
                                {Label({ label: 'Cyl' })}
                                <CustomTextField independent value={obj.add_le_cyl} onChange={(value) => {
                                    setFormObjectArray('lens_details', index, 'add_le_cyl', value);
                                }} />
                            </View>
                        </Col>

                        <Col width={Platform.OS == 'web' ? '20%' : '20%'}>
                            <View style={{ width: '100%' }}>
                                {Label({ label: 'Axis' })}
                                <CustomTextField independent value={obj.add_le_axis} onChange={(value) => {
                                    setFormObjectArray('lens_details', index, 'add_le_axis', value);
                                }} />
                            </View>
                        </Col>

                        <Col width={Platform.OS == 'web' ? '20%' : '20%'}>
                            <View style={{ width: '100%' }}>
                                {Label({ label: 'VA' })}
                                <CustomTextField independent value={obj.add_le_va} onChange={(value) => {
                                    setFormObjectArray('lens_details', index, 'add_le_va', value);
                                }} />
                            </View>
                        </Col>

                    </Row>
                })}
            </View>
        </>
    }

    const LedgerAccountComponent = React.memo(({ field, useForm, tKey, tIndex }) => {
        const account_id = useForm((state) => state.formObject['account_id']);

        return <View flex>
            <ReportScreenSingle
                moduleName={"Ledger"}
                hideWrapper={true}
                noMono={true}
                hideHeader={true}
                params={{
                    quickFilterKey: 'account_id',
                    quickFilterValue: account_id,
                    hideQuickFilter: true,
                }}
                route={{
                    params: {
                        quickFilterKey: 'account_id',
                        quickFilterValue: account_id,
                        hideQuickFilter: true,
                        name: "Ledger",
                    }
                }}
            />
        </View>
    });

    const ExtraGSTNumbers = ({ field, useForm }) => {
        const gst_numbers = useForm((state) => state.formObject['gst_numbers']);

        const setFormObject = useForm((state) => state.setFormObject);
        const setFormObjectArray = useForm((state) => state.setFormObjectArray);
        const getFormObject = useForm((state) => state.getFormObject);

        return <>

            <View flex>
                {gst_numbers && gst_numbers.map((obj, index) => {
                    return <Row key={index} style={{ marginTop: 10, alignItems: 'center' }} >
                        <Col width="100%">
                            <Text tabHeading>GST Number {index + 1}</Text>
                        </Col>

                        <Col width="30%">
                            <CustomTextField label="GST Numbers" value={obj.gst_number} onChange={(value) => {
                                setFormObjectArray('gst_numbers', index, 'gst_number', value);
                            }} />
                        </Col>

                        <Col width="30%">
                            <CustomSelect label="Primary" value={obj.primary} options={[
                                { label: 'Yes', value: "Yes" },
                                { label: 'No', value: "No" },
                            ]} onChange={(value) => {
                                setFormObjectArray('gst_numbers', index, 'primary', value);
                                const newArray = [...getFormObject().gst_numbers];
                                let otherDefault = false;
                                newArray.forEach((data, i) => {
                                    if (i != index && value == "Yes") {
                                        data.primary = "No";
                                    }
                                    if (i != index && value == "No") {
                                        if (!otherDefault) {
                                            data.primary = "Yes";
                                            otherDefault = true;
                                        }
                                    }
                                });
                                setFormObject({ ...getFormObject(), gst_numbers: newArray });
                            }} />
                        </Col>

                        <View style={{ marginTop: -15 }}>
                            <Button outline outlineColor={'red'} label="Delete" onPress={() => {
                                const newObject = { ...getFormObject() };
                                const newGstNumbers = [...newObject.gst_numbers];
                                newGstNumbers.splice(index, 1);
                                newObject.gst_numbers = newGstNumbers;
                                setFormObject(newObject);
                            }} />
                        </View>
                    </Row>
                })}
            </View>
        </>
    }

    const SubscriptionComponent = ({ field, useForm, tKey, tIndex }) => {
        const [subscriptions, setSubscriptions] = React.useState([]);
        const contact_id = useForm((state) => state.formObject['_id']);

        const { message, modal } = App.useApp();

        const getSubscriptions = async () => {
            const responseData = await api.get("Subscriptions", {
                page: 1,
                limit: 1000,
                filters: [],
                tabFilter: { contact_id: contact_id },
                search: "",
                groupBy: null
            });

            console.log("responseData", responseData);

            setSubscriptions(responseData.data.data);
        };

        useEffect(() => {
            getSubscriptions();
        }, []);

        return <>
            <View flex row style={{
                flexWrap: 'wrap'
            }}>
                {subscriptions && subscriptions.length > 0 && subscriptions.map((subscription, i) => (<View key={i} style={{
                    flexBasis: '50%'
                }}>
                    <Card margin-10 padding-10 flex style={{ marginVertical: 10, flexDirection: 'row', flexWrap: 'wrap', borderRadius: 10 }}>
                        <View marginB-10 row spread style={{ width: '100%' }}>

                            <View flex>
                                <View row spread>
                                    <Text style={{ marginBottom: 5, fontSize: 16, fontFamily: 'SourceSansProBold' }}>
                                        {subscription.item.name}
                                    </Text>
                                    {moment(subscription.end_date).isBefore(moment()) && <View style={{
                                        backgroundColor: '#cb1721',
                                        paddingHorizontal: 10,
                                        paddingVertical: 5,
                                        borderRadius: 5,
                                    }}>
                                        <Text style={{ color: 'white' }}>Expired</Text>
                                    </View>}
                                </View>


                                {Platform.OS == 'ios' ? <View style={{ overflow: 'hidden' }}>
                                    <View
                                        style={{
                                            borderStyle: 'dashed',
                                            borderWidth: 1,
                                            borderColor: '#d9d9d9',
                                            margin: -2,
                                            marginTop: 5,
                                        }}>
                                        <View style={{ height: 20, width: '100%' }} />
                                    </View>
                                </View> : <View style={{
                                    borderBottomColor: '#d9d9d9',
                                    borderBottomWidth: 1,
                                    width: '100%',
                                    marginBottom: 0,
                                    marginTop: 5,
                                    borderStyle: 'dashed',
                                }} />}

                                <View flex row marginT-10>
                                    <View row marginB-6 flex centerV>
                                        <View>
                                            <Text lightCardTitle>{"Start Date"}: </Text>
                                        </View>
                                        <View flex>
                                            <Text>{moment(subscription.start_date).format('DD MMM YYYY')}</Text>
                                        </View>
                                    </View>
                                    <View row marginB-6 flex>
                                        <View>
                                            <Text lightCardTitle>{"Expiry Date"}: </Text>
                                        </View>
                                        <View flex>
                                            <Text>{moment(subscription.end_date).format('DD MMM YYYY')}</Text>
                                        </View>
                                    </View>
                                </View>

                                {Platform.OS == 'ios' ? <View style={{ overflow: 'hidden' }}>
                                    <View
                                        style={{
                                            borderStyle: 'dashed',
                                            borderWidth: 1,
                                            borderColor: '#d9d9d9',
                                            margin: -2,
                                            marginTop: 5,
                                        }}>
                                        <View style={{ height: 20, width: '100%' }} />
                                    </View>
                                </View> : <View style={{
                                    borderBottomColor: '#d9d9d9',
                                    borderBottomWidth: 1,
                                    width: '100%',
                                    marginBottom: 0,
                                    marginTop: 5,
                                    borderStyle: 'dashed',
                                }} />}

                                {subscription.subscription_item_usage.map((item, j) => (<View key={j} marginT-10>
                                    <View flex>
                                        <View row marginB-6 flex spread centerV>
                                            <View>
                                                <Text lightCardTitle>
                                                    {item.item?.name}
                                                </Text>
                                            </View>
                                            <View flexG row centerV right>
                                                <View marginR-5 style={{
                                                    width: 80,
                                                }}>
                                                    <CustomNumberInput
                                                        type="number"
                                                        placeholder="PRP"
                                                        visible={true}
                                                        width="100%"
                                                        value={item.used_quantity}
                                                        onChange={(value) => {
                                                            const newSubscriptions = [...subscriptions];
                                                            const newSubscription = { ...newSubscriptions[i] };
                                                            const newSubscriptionItemUsage = [...newSubscription.subscription_item_usage];
                                                            const newSubscriptionItemUsageItem = { ...newSubscriptionItemUsage[j] };
                                                            newSubscriptionItemUsageItem.used_quantity = value;
                                                            newSubscriptionItemUsage[j] = newSubscriptionItemUsageItem;
                                                            newSubscription.subscription_item_usage = newSubscriptionItemUsage;
                                                            newSubscriptions[i] = newSubscription;
                                                            setSubscriptions(newSubscriptions);
                                                        }}
                                                    />
                                                </View>
                                                <Text>used out of </Text> <Text style={{ fontFamily: 'SourceSansProBold' }}>{item.quantity}</Text>
                                            </View>
                                        </View>
                                    </View>
                                </View>))}

                                <View marginT-10>
                                    <PrimaryButton
                                        label="Update"
                                        onPress={async () => {
                                            modal.confirm({
                                                title: 'Are you sure you want to update the usage?',
                                                content: 'This action cannot be undone.',
                                                okText: 'Yes',
                                                cancelText: 'No',
                                                onOk: async () => {
                                                    const response = await api.customRoute('Invoices/action', {
                                                        action: 'updateSubscriptionUsage',
                                                        _id: subscription.invoice_id,
                                                        newQuantityArray: subscriptions[i].subscription_item_usage
                                                    });

                                                    if (response.success) {
                                                        message.success('Updated successfully');
                                                    } else {
                                                        message.error('Error updating');
                                                    }
                                                },
                                                onCancel: () => {
                                                }
                                            });
                                        }}
                                    />
                                </View>
                            </View>
                        </View>

                    </Card>
                </View>))}
            </View>
        </>
    };

    const OrganizationConnectComponent = ({ field, useForm }) => {
        // add primary button and on its click open model to enter organization url and on save we call one api to connect organization
        const [organizationUrl, setOrganizationUrl] = useState('');

        const setFormObject = useForm((state) => state.setFormObject);
        const getFormObject = useForm((state) => state.getFormObject);
        const connected_organisaton_number = useForm((state) => state.formObject['connected_organisaton_number']);
        const [showModal, setShowModal] = useState(false);
        const { message } = App.useApp();

        return <>
            {connected_organisaton_number ? <View row centerV> 
                <Text>Organization Connected!</Text>
                <Svg width={20} height={20} viewBox="0 0 24 24" fill="none" stroke="green" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-check-circle" style={{ marginLeft: 5 }}>
                    <Path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"> </Path>
                    <Path d="M22 4L12 14.01l-3-3"> </Path>
                </Svg>
            </View> : <View left>
                <PrimaryButton
                    label="Connect Organization"
                    onPress={() => {
                        setShowModal(true);
                    }}
                />

                <CustomModal
                    visible={showModal}
                    title="Enter Organization URL"
                    onCancel={() => {
                        setShowModal(false);
                    }}
                >
                    <CustomTextField
                        label="Organization URL"
                        value={organizationUrl}
                        onChange={(value) => {
                            setOrganizationUrl(value);
                        }}
                    />

                    <PrimaryButton
                        style={{ marginTop: 10 }}
                        label="Connect"
                        onPress={async () => {
                            const response = await api.customRoute('Contacts/action', {
                                action: 'connectOrganization',
                                contact_id: getFormObject()._id,
                                organization_url: organizationUrl,
                                current_organization_id: getFormObject().org_id
                            });
                            
                            if (response.success) {
                                message.success('Organization connect request sent successfully');
                                setShowModal(false);
                            } else {
                                if(response.message) {
                                    message.error(response.message);
                                } else {
                                    message.error('Error connecting organization');
                                }
                            }
                        }}
                    />
                </CustomModal>
            </View>}
        </>
    }


    let formFields = [
        {
            type: 'divider',
            heading: 'Contact Details',
            visible: true,
            span: 24,
            tab: 'General',
        },
        {
            label: 'Name',
            key: 'name',
            type: 'text',
            placeholder: 'Enter the Contact Name',
            visible: true,
            width: '25%',
            tab: 'General'
        },
        {
            label: 'Company Name',
            key: 'company_name',
            type: 'text',
            placeholder: 'Enter the Company Name',
            visible: true,
            width: '25%',
            tab: 'General'
        },
        // {
        //     label: 'Website',
        //     key: 'website',
        //     type: 'text',
        //     placeholder: 'Enter the Website',
        //     visible: true,
        //     width: '25%',
        //     tab: 'General'
        // },
        // {
        //     label: 'Status',
        //     key: 'status',
        //     type: 'select',
        //     placeholder: 'Select the Status',
        //     visible: true,
        //     width: '25%',
        //     options: [
        //         { label: 'Active', value: 'active' },
        //         { label: 'Inactive', value: 'inactive' },
        //     ],
        //     tab: 'General'
        // },
        {
            label: 'Email',
            key: 'email',
            type: 'text',
            placeholder: 'Enter the Email',
            visible: true,
            width: '25%',
            tab: 'General',
            onBlur: ({ value, setObj, getArrValue, getObj, setArrValue, tIndex, tKey }) => {
                const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
                if (!regex.test(value)) {
                    setObj({
                        email: ''
                    });
                }
            },
        },
        {
            label: 'Phone',
            key: 'phone',
            type: 'text',
            placeholder: 'Enter the Phone Number',
            visible: true,
            width: '25%',
            tab: 'General',
            showCountryCode: true,
            onBlur: ({ value, setObj, getArrValue, getObj, setArrValue, tIndex, tKey }) => {
                if (!value || value.length > 15 || value.length < 8) {
                    setObj({
                        phone: ''
                    });
                }
            }
        },
        {
            label: 'Alternate Contact No',
            key: 'alternate_contact_no',
            type: 'text',
            placeholder: 'Enter the Alternate Contact No',
            visible: true,
            width: '25%',
            tab: 'General',
            showCountryCode: true,
            onBlur: ({ value, setObj, getArrValue, getObj, setArrValue, tIndex, tKey }) => {
                if (!value || value.length > 15 || value.length < 8) {
                    setObj({
                        alternate_contact_no: ''
                    });
                }
            }
        },
        {
            label: 'Date of Birth',
            key: 'date_of_birth',
            type: 'date',
            placeholder: 'Enter the Date of Birth',
            visible: true,
            width: '25%',
            tab: 'General'
        },
        {
            label: 'Contact Category',
            key: 'contact_category_id',
            type: 'select',
            placeholder: 'Select the Contact Category',
            visible: true,
            width: '25%',
            options: response.ContactCategories || [],
            tab: 'General',
            creatableAction: (props) => {
                const params = { ...(props.id ? { isEdit: true, id: props.id } : {}), ...(props.search ? { is_default_values: true, name: props.search } : {}) };

                OpenModalCustom({ newModule: "Categories", field_key: 'contact_category_id', params })
            },
        },
        {
            label: 'Balance',
            key: 'balance',
            type: 'number',
            placeholder: 'Enter the Balance',
            visible: false,
            width: '25%',
            tab: 'General'
        },
        {
            label: 'Opening Balance',
            key: 'opening_balance',
            type: 'number',
            placeholder: 'Enter the Opening Balance',
            visible: true,
            width: '25%',
            tab: 'General',
            value: 0
        },
        {
            label: 'Do you sell to this contact?',
            key: 'is_customer',
            type: 'checkbox',
            placeholder: 'Enter the Opening Balance',
            visible: true,
            width: '100%',
            tab: 'General',
            value: true,
        },
        {
            label: 'Do you purchase from this contact?',
            key: 'is_supplier',
            type: 'checkbox',
            placeholder: 'Enter the Opening Balance',
            visible: true,
            width: '100%',
            tab: 'General',
            value: false,
        },
        {
            type: 'divider',
            heading: 'GST Details',
            visible: true,
            span: 24,
            tab: 'General'
        },
        {
            label: 'GST Treatment',
            key: 'gst_treatment',
            type: 'select',
            placeholder: 'Enter the GST Treatment',
            visible: true,
            width: '25%',
            options: [
                { label: 'Registered Business - Regular', value: 'Registered Business - Regular' },
                { label: 'Registered Business - Composition', value: 'Registered Business - Composition' },
                { label: 'Unregistered Business', value: 'Unregistered Business' },
                { label: 'Consumer', value: 'Consumer' },
            ],
            tab: 'General',
            onChange: ({ value, tKey, tIndex, getObj, setObj, getArrValue, setArrValue }) => {
                setObj({
                    updateVisiblity: Math.random()
                });
            },
            value: 'Consumer'
        },
        {
            label: 'PAN Number',
            key: 'pan_number',
            type: 'text',
            placeholder: 'Enter the PAN Number',
            visible: true,
            width: '25%',
            tab: 'General'
        },
        {

        },
        {
            type: 'custom',
            key: 'gst_numbers',
            component: ExtraGSTNumbers,
            visible: (connectedObject) => {
                return (connectedObject.gst_treatment == 'Registered Business - Regular' || connectedObject.gst_treatment == 'Registered Business - Composition');
            },
            span: 24,
            value: [{
                gst_number: '',
                primary: 'Yes'
            }],
            tab: 'General'
        },
        addButton('Add GST Number', 'add_gst_number', 'gst_numbers', {
            gst_number: '',
            primary: 'No'
        }, "General", (connectedObject) => {
            return (connectedObject.gst_treatment == 'Registered Business - Regular' || connectedObject.gst_treatment == 'Registered Business - Composition');
        }),
        {
            type: 'divider',
            heading: 'Organization Connect',
            visible: true,
            span: 24,
            tab: 'General'
        },
        {
            type: 'custom',
            key: 'custom_fields',
            component: OrganizationConnectComponent,
            visible: true,
            span: 24,
            width: '100%',
            tab: 'General'
        },
        {
            type: 'divider',
            heading: 'Addresses',
            visible: true,
            span: 24,
            tab: 'Address Details'
        }, {
            type: 'custom',
            key: 'addresses',
            component: AddressesComponent,
            visible: true,
            span: 24,
            value: [{
                primary_billing: 'Yes',
                primary_shipping: 'Yes',
                country: 'India',
                state: 'Madhya Pradesh'
            }],
            tab: 'Address Details'
        },
        addButton('Add Address', 'add_address', 'addresses', {
            primary_billing: 'No',
            primary_shipping: 'No',
            country: 'India',
            state: 'Madhya Pradesh'
        }, "Address Details"),
        {
            type: 'divider',
            heading: 'Eye Details',
            visible: true,
            span: 24,
            tab: 'Eye Details'
        }, {
            type: 'custom',
            key: 'lens_details',
            component: LensDetailsComponent,
            visible: true,
            span: 24,
            value: [],
            tab: 'Eye Details'
        },
        {
            ...addButton('Add Eye Details', 'add_lens_details', 'lens_details', {
                date: "GENERATED_DATE",
            }, "Eye Details")
        },
        {
            label: 'Auto create ledger account with the same name as the contact?',
            key: 'auto_create_ledger_account',
            type: 'checkbox',
            placeholder: 'Enter the Opening Balance',
            visible: isEdit ? false : view ? false : true,
            width: '100%',
            tab: 'Ledger Account',
            value: isEdit ? false : true,
            onChange: ({ value, tKey, tIndex, getObj, setObj, getArrValue, setArrValue }) => {
                setObj({
                    updateVisiblity: Math.random()
                })
            }
        },
        {
            label: 'Ledger Account',
            key: 'account_id',
            type: 'select',
            placeholder: 'Enter the Ledger Account',
            visible: (connectedObject) => {
                if (isEdit) {
                    return true;
                }
                return !connectedObject.auto_create_ledger_account;
            },
            width: '25%',
            tab: 'Ledger Account',
            options: response.LedgerAccounts || []
        },
        {
            label: '',
            type: 'custom',
            placeholder: 'Enter the Ledger Account',
            visible: (connectedObject) => {
                if (view) {
                    return true;
                } else {
                    return false;
                }
            },
            width: '100%',
            tab: 'Ledger Account',
            component: LedgerAccountComponent
        },

        {
            key: 'active_subscriptions',
            type: 'custom',
            visible: response.module_preferences?.show_subscriptions == true,
            width: '100%',
            tab: 'Subscriptions',
            component: SubscriptionComponent,
        },
    ];

    if (globalData.organization_id != 15) {
        formFields = formFields.filter((f) => f.tab != 'Eye Details');
    }

    const CustomFields = response.CustomFields;

    addCustomFields(CustomFields, formFields);

    return formFields;
}

export const contactMobileCard = (item, index, getFieldValue) => {
    return <Card flex margin-20 padding-15 className="hh" style={{ height: 100, marginVertical: 10, flexDirection: 'row', flexWrap: 'wrap' }}>
        <View>
            <Text>TEST</Text>
            <Text style={{ marginBottom: 5 }}>{getFieldValue(item, 'name') ? "" + getFieldValue(item, 'name') : ""}</Text>
        </View>
    </Card>;
}



const preferencesFields = (response, view = false, moduleName = null, OpenModalCustom) => {
    const formFields = [{
        label: 'Show Subscriptions',
        key: 'show_subscriptions',
        type: 'checkbox',
        placeholder: 'Show Subscriptions',
        visible: true,
        width: '25%',
        value: false
    }];

    return formFields;
}

export const contactMain = {
    fields: contactfields,
    mobileCard: null,
    preferencesFields: preferencesFields,
}

export function addCustomFields(CustomFields, formFields) {
    if (CustomFields && CustomFields.length > 0) {
        CustomFields.forEach((field) => {

            const obj = {
                label: field.name,
                key: 'cf.' + field.key,
                type: field.type,
                placeholder: field.placeholder,
                visible: true,
                width: '25%',
                tab: field.tab,
                onChange: null
            };

            const formFieldOne = formFields[0];

            if (!formFieldOne.tab) {
                delete obj.tab;
            }

            if (field.onChangeFunction) {
                obj.onChange = ({ value, tKey, tIndex, getObj, setObj, getArrValue, setArrValue }) => {
                    try {
                        const fn = api.decryptText(field.onChangeFunction)

                        eval(fn);
                    } catch (e) {

                    }
                }
            }

            if (field.after) {
                const index = formFields.findIndex((f) => f.key === field.after);
                formFields.splice(index + 1, 0, obj);
            } else if (field.before) {
                const index = formFields.findIndex((f) => f.key === field.before);
                formFields.splice(index, 0, obj);
            } else {
                formFields.push(obj)
            }

        });
    }
}
